import React, { useState, useContext } from "react";
import { Button, Grid } from "@mui/material";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";

import TrackingStatusBar from "components/TrackingStatusBar";
import TrackingHistoryList from "components/TrackingHistoryList";
import OrderStatusPreview from "components/OrderStatusPreview";
import { useTranslation } from "react-i18next";
import { AuthContext } from "AuthProvider";
import theme from "../../theme";

const styles = {
    pageBlock: {
        marginTop: theme.spacing(1),
    },
};

export default function OrderInfos({ orderData, order_statuses_definitions }) {
    const [visibleCollapse, setVisibleCollapse] = useState(false);
    const { t } = useTranslation();
    const { order_status_changes, type } = orderData || {};
    const order_status_changes_valid = order_status_changes.filter(
        (order_status_change) =>
            !order_status_change.order_statuses_definition.is_problem,
    );
    const { stores } = useContext(AuthContext);

    return (
        <>
            {["VP", "VF", "VDS"].includes(type) && (
                <Grid sx={styles.pageBlock} container>
                    <TrackingStatusBar
                        status_changes={order_status_changes_valid}
                        order_statuses_definitions={order_statuses_definitions}
                        created_at={orderData?.created_at}
                        passed_statuses={order_status_changes_valid}
                        orderData={orderData}
                        stores={stores}
                    />
                </Grid>
            )}
            {order_status_changes.length ? (
                <>
                    {/*["VP", "VF", "VDS"].includes(type) && (
                        <Grid
                            sx={styles.pageBlock}
                            container
                            justify="center"
                        >
                            <AlertInfoDeliveryDates
                                created_at={orderData.created_at}
                                external_id={orderData.id}
                                order_product_lines={order_product_lines}
                                order_status_changes={order_status_changes}
                                tracking_number={orderData.tracking_number}
                            />
                        </Grid>
                    )*/}

                    <Grid
                        sx={styles.pageBlock}
                        container
                        justifyContent="flex-end"
                    >
                        <Button
                            onClick={() =>
                                setVisibleCollapse((prevState) => !prevState)
                            }
                        >
                            {t("common.trackingDetail")}
                            {visibleCollapse ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </Button>
                    </Grid>
                </>
            ) : null}
            <Grid sx={styles.pageBlock} container>
                <TrackingHistoryList
                    visible={visibleCollapse}
                    order_status_changes={order_status_changes}
                    order_statuses_definitions={order_statuses_definitions}
                    created_at={orderData.created_at}
                    stores={stores}
                />
            </Grid>
            <Grid sx={styles.pageBlock} container>
                <OrderStatusPreview
                    orderData={orderData}
                    extend={true}
                    order_statuses_definitions={order_statuses_definitions}
                    created_at={orderData.created_at}
                />
            </Grid>
        </>
    );
}
