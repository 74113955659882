import React, { useContext } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Skeleton, Link, Box } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import styled from "@mui/styles/styled";

import ORDER_STATUS from "constants/order-status";
import { useTranslation } from "react-i18next";
import { encode } from "pages/Orders/utils";
import { useHistory } from "react-router-dom";
import { AuthContext } from "AuthProvider";
import Text from "components/Text";

const StyledTitleText = styled(Text)(({ theme }) => ({
    color: theme.palette.grey.granite,
    fontWeight: "bolder",
    marginLeft: theme.spacing(1),
}));

const StyledTitleContainer = styled("div")(({ theme }) => ({
    display: "flex",
    marginBottom: theme.spacing(1.5),
}));

const StyledTitleIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.grey.granite,
}));

const StyledRecapContainer = styled("div")(() => ({
    display: "inline-flex",
    alignItems: "center",
}));

const StyledRecapBox = styled(Box)(({ theme }) => ({
    display: "inline-flex",
    color: theme.palette.secondary.light,
    borderRadius: theme.border.radius.list,
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.grey.granite,
}));

const StyledRecapBoldText = styled("div")(({ theme }) => ({
    fontWeight: "bolder",
    marginRight: theme.spacing(0.4),
    marginLeft: theme.spacing(0.4),
    display: "flex",
    color: theme.palette.white.lotion,
}));

const StyledRecapRefreshButton = styled(Link)(() => ({
    padding: 0,
    textTransform: "none",
    fontWeight: "bolder",
    cursor: "pointer",
    display: "flex",
    "&:hover": {
        textDecoration: "underline",
    },
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: ["Roboto", "sans-serif"].join(","),
}));

const StyledErrorContainer = styled("div")(({ theme }) => ({
    display: "inline-flex",
    border: `${theme.border.width.thinner}px solid`,
    borderRadius: theme.border.radius.list,
    padding: theme.spacing(1),
    borderColor: theme.palette.grey.granite,
}));

const StyledBodyErrorText = styled("div")(({ theme }) => ({
    color: theme.palette.grey.granite,
    marginRight: theme.spacing(0.7),
}));

const StyledLoadingContainer = styled("div")(({ theme }) => ({
    border: `${theme.border.width.thinner}px solid`,
    borderRadius: theme.border.radius.list,
    padding: theme.spacing(1),
    borderColor: theme.palette.grey.granite,
    width: "438px",
}));

const StyledBodyLinkButton = styled("div")(({ theme }) => ({
    padding: 0,
    textTransform: "none",
    cursor: "pointer",
    backgroundColor: theme.palette.grey.granite,
    color: theme.palette.secondary.light,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    textDecoration: `underline ${theme.palette.white.lotion}`,
    fontFamily: ["Roboto", "sans-serif"].join(","),
    "&:hover": {
        textDecoration: "none",
    },
}));

const TRANS_PATH = "pages.receipt.recap";

const ORDERS_TABLE_PATH = "/orders";

const Recap = ({ orders, loading, refetch, error, disableRefresh }) => {
    const { t } = useTranslation();

    const { storeId } = useContext(AuthContext);
    const history = useHistory();

    const ordersTableFilters = {
        beginCreatedAt: null,
        endCreatedAt: null,
        destination_store: [storeId],
        global_statuses: [ORDER_STATUS.S_ORDER_SHIPPED],
    };

    const redirectToShippedOrdersTable = () =>
        history.push({
            pathname: ORDERS_TABLE_PATH,
            search: encode(ordersTableFilters),
        });

    const orderReceipted =
        orders?.filter(
            (order) =>
                order?.order_status_changes?.[0]?.order_statuses_id ===
                    ORDER_STATUS.ORDER_AT_SHOP &&
                order?.global_status_id !== ORDER_STATUS.S_ORDER_SHIPPED &&
                order?.global_status_id !== ORDER_STATUS.S_ORDER_ENDED,
        ) || [];
    const orderRetrieved =
        orders?.filter(
            (order) => order?.global_status_id === ORDER_STATUS.S_ORDER_ENDED,
        ) || [];
    const orderNotScanned =
        orders?.filter(
            (order) => order?.global_status_id === ORDER_STATUS.S_ORDER_SHIPPED,
        ) || [];

    const _renderLoading = () => (
        <StyledLoadingContainer>
            <Skeleton animation="wave" />
        </StyledLoadingContainer>
    );

    const _renderError = () => (
        <StyledErrorContainer component={"span"}>
            <StyledBodyErrorText>
                {t(`${TRANS_PATH}.bodyError`)}
            </StyledBodyErrorText>
            <StyledRecapRefreshButton
                onClick={() => refetch()}
                endIcon={<ReplayIcon />}
            >
                {t("common.retry")}
            </StyledRecapRefreshButton>
        </StyledErrorContainer>
    );

    const _renderRecapBody = () => (
        <StyledRecapContainer component={"span"}>
            <StyledRecapBox component={"span"}>
                <StyledRecapBoldText>
                    {orderReceipted.length}
                </StyledRecapBoldText>
                <Text>
                    {t(`${TRANS_PATH}.body1`, { count: orderReceipted.length })}
                </Text>
                <StyledRecapBoldText>
                    {orderRetrieved.length}
                </StyledRecapBoldText>
                <Text>
                    {t(`${TRANS_PATH}.body2`, { count: orderRetrieved.length })}
                </Text>
                <StyledRecapBoldText>
                    {orderNotScanned.length}
                </StyledRecapBoldText>
                <StyledBodyLinkButton
                    ubderline="always"
                    onClick={() => redirectToShippedOrdersTable()}
                >
                    <StyledRecapBoldText>
                        {t(`${TRANS_PATH}.body3`, {
                            count: orderNotScanned.length,
                        })}
                    </StyledRecapBoldText>
                </StyledBodyLinkButton>
            </StyledRecapBox>
            <StyledRecapRefreshButton
                disabled={disableRefresh}
                onClick={() => {
                    refetch();
                }}
                underline="hover"
            >
                {t("common.refetchData")} <ReplayIcon />
            </StyledRecapRefreshButton>
        </StyledRecapContainer>
    );

    return (
        <div>
            <StyledTitleContainer>
                <StyledTitleIcon />
                <StyledTitleText>{t(`${TRANS_PATH}.title`)}</StyledTitleText>
            </StyledTitleContainer>
            {loading && _renderLoading()}
            {error && _renderError()}
            {!loading && !error && _renderRecapBody()}
        </div>
    );
};

export default Recap;
