import { gql } from "@apollo/client";

const orderQuery = gql`
    query Orders(
        $where: orders_bool_exp
        $limit: Int
        $offset: Int
        $order_by: [orders_order_by!]
    ) {
        orders(
            limit: $limit
            offset: $offset
            order_by: $order_by
            where: $where
        ) {
            id
            keybuild_id
            created_at
            carrier_type
            tracking_number
            type
            flat_amount_including_tax
            currency
            is_care_service
            is_multi
            customer_account {
                firstname
                lastname
                email
                phone_number
            }
            store {
                id
            }
            destination_store {
                id
            }
            global_status_detail {
                id
            }
            global_status_id
            optimum {
                transmission_status
            }
            order_product_lines_aggregate {
                aggregate {
                    count
                }
            }
            origin
        }
        orders_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

const storesQuery = gql`
    query GetStores($stores: [String!]) {
        destinations_stores: stores(
            where: {
                orders_destination_store: { store: { id: { _in: $stores } } }
            }
        ) {
            id
            name
        }
    }
`;

const globalStatusesQuery = gql`
    query GetTransactionalStatuses {
        order_statuses_definitions(
            where: { type: { _eq: "global" } }
            order_by: { label: asc }
        ) {
            id
            label
        }
    }
`;

export { orderQuery, storesQuery, globalStatusesQuery };
