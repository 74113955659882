import React from "react";
import { Paper, Typography, Fade } from "@mui/material";

import { transformCountryCode } from "services/toolbox";

import PropTypes from "prop-types";
import theme from "theme";

const styles = {
    paper: {
        padding: theme.spacing(2),
        height: "100%",
        textAlign: "center",
        backgroundColor: theme.palette.mainGrey.main,
    },
};

const AdressGrid = ({ label, adress, extend, infoBoxHeight, fullInfos }) => {
    const {
        line1,
        line2,
        postal_code,
        firstname,
        lastname,
        city,
        country_code,
        company,
    } = adress;

    return (
        <Fade timeout={1000} in={true}>
            <Paper
                sx={styles.paper}
                style={{
                    height: !extend && infoBoxHeight && `${infoBoxHeight}px`,
                    minHeight: extend && infoBoxHeight && `${infoBoxHeight}px`,
                }}
            >
                <Typography>{label}</Typography>

                <hr></hr>
                <div>
                    <Typography>
                        {firstname} {lastname}
                    </Typography>
                    {fullInfos && <Typography>{company}</Typography>}
                    <Typography>{line1} </Typography>
                    {fullInfos && <Typography>{line2}</Typography>}
                    <Typography>
                        {postal_code} {city}
                    </Typography>

                    {fullInfos && (
                        <Typography>
                            {transformCountryCode(country_code)}
                        </Typography>
                    )}
                </div>
            </Paper>
        </Fade>
    );
};

AdressGrid.defaultProps = {
    extend: false,
    fullInfos: false,
};

AdressGrid.propTypes = {
    /**Label of the card, used at the top as a title */
    label: PropTypes.string,
    /** Adress object */
    adress: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        postal_code: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        city: PropTypes.string,
        country_code: PropTypes.string,
        company: PropTypes.string,
    }),
    /** Bool to determine either we need to modify height or not, used in the OrdersInfos */
    extend: PropTypes.bool,
    /** Updated height of the associated component */
    infoBoxHeight: PropTypes.number,
    /** If we need to display all adresses infos like company or country code, use in CustomerInfos */
    fullInfos: PropTypes.bool,
};

export default AdressGrid;
