import queryString from "query-string";
import { endOfDay, format, startOfDay } from "date-fns";

import {
    formatFiltersToObj,
    nameFilterFormatter,
} from "components/Table/utils";

/**
 * FormatedFilters
 * @typedef {Object} FormatedFilters
 * @property {?string} search,
 * @property {?string} beginCreatedAt
 * @property {?string} endCreatedAt
 * @property {?string[]} types
 * @property {?string[]} stores
 * @property {?string[]} destination_store
 * @property {?string[]} global_statuses
 * @property {?string[]} teletransmission
 */

/**
 *
 * @param {[]} filters filters issued from react table
 * @param {string} globalFilter
 * @returns {FormatedFilters}
 */
export const formatFiltersForGraphQL = (filters = [], globalFilter) => {
    const filtersObj = formatFiltersToObj(filters);

    const typeFilteredIds =
        filtersObj.type?.length > 0
            ? filtersObj.type
                  .filter((type) => type.id !== "care" && type.id !== "VM")
                  .map((type) => type.id)
            : null;
    const isCareFilter =
        filtersObj.type?.length > 0
            ? filtersObj.type.find((type) => type.id === "care") || null
            : null;
    const isMultiFilter =
        filtersObj.type?.length > 0
            ? filtersObj.type.find((type) => type.id === "VM") || null
            : null;
    const teletransmissionStatusFilteredIds =
        filtersObj.teletransmission?.length > 0
            ? filtersObj.teletransmission.map((tt) => tt.id)
            : null;
    const storesFilteredIds =
        filtersObj["store.id"]?.store?.length > 0
            ? filtersObj["store.id"]?.store?.map((store) => store?.id)
            : null;
    const destinationStoresFilteredIds =
        filtersObj["store.id"]?.destination_store?.length > 0
            ? filtersObj["store.id"]?.destination_store?.map(
                  (store) => store?.id,
              )
            : null;
    const globalStatusesFilteredIds =
        filtersObj.global_status_id?.length > 0
            ? filtersObj.global_status_id.map((status) => status.id)
            : null;

    return {
        search: globalFilter,
        beginCreatedAt: filtersObj.created_at?.[0] || null,
        endCreatedAt: filtersObj.created_at?.[1] || null,
        types: typeFilteredIds?.length ? typeFilteredIds : null,
        stores: storesFilteredIds,
        destination_store: destinationStoresFilteredIds,
        global_statuses: globalStatusesFilteredIds,
        teletransmission: teletransmissionStatusFilteredIds,
        isCare: !!isCareFilter || null,
        isMulti: !!isMultiFilter || null,
    };
};

/**
 *
 * @param {FormatedFilters} filters
 * @returns a conditionnal where clause where null conditions
 */
export const buildGraphQLWhereClauseWithFilters = (filters, searchOptions) => {
    const whereClause = { _and: [{ origin: { _is_null: false } }] };
    const { searchByNameAndLastName, searchByOrderId, searchByMail } =
        searchOptions;
    if (filters.search) {
        if (searchByNameAndLastName) {
            whereClause._and.push(
                {
                    customer_account: {
                        firstname: {
                            _similar: nameFilterFormatter(filters.search),
                        },
                    },
                },
                {
                    customer_account: {
                        lastname: {
                            _similar: nameFilterFormatter(filters.search),
                        },
                    },
                },
            );
        } else if (searchByMail) {
            whereClause._and.push({
                customer_account: { email: { _ilike: filters.search } },
            });
        } else if (searchByOrderId) {
            whereClause._and.push({
                _or: [
                    {
                        id: { _similar: `%${filters.search}%` },
                    },
                    {
                        keybuild_id: { _similar: `%${filters.search}%` },
                    },
                ],
            });
        } else {
            whereClause._and.push({
                _or: [
                    {
                        customer_account: {
                            firstname: {
                                _similar: nameFilterFormatter(filters.search),
                            },
                        },
                    },
                    {
                        customer_account: {
                            lastname: {
                                _similar: nameFilterFormatter(filters.search),
                            },
                        },
                    },
                ],
            });
        }
    }
    if (filters.beginCreatedAt || filters.endCreatedAt) {
        const lastIndex = whereClause._and.push({ created_at: {} }) - 1;
        if (filters.beginCreatedAt) {
            whereClause._and[lastIndex].created_at._gte =
                filters.beginCreatedAt;
        }
        if (filters.endCreatedAt) {
            whereClause._and[lastIndex].created_at._lte = filters.endCreatedAt;
        }
    }
    if (filters.stores) {
        whereClause._and.push({ store: { id: { _in: filters.stores } } });
    }
    if (filters.types) {
        whereClause._and.push({ type: { _in: filters.types } });
    }

    if (filters.global_statuses) {
        whereClause._and.push({
            global_status_id: { _in: filters.global_statuses },
        });
    }
    if (filters.destination_store) {
        whereClause._and.push({
            store_destination_id: { _in: filters.destination_store },
        });
    }
    if (filters.isCare) {
        whereClause._and.push({
            is_care_service: { _eq: true },
        });
    }
    if (filters.isMulti) {
        whereClause._and.push({
            is_multi: { _eq: true },
            origin: { _like: "WEB%" },
        });
    }
    if (filters.teletransmission) {
        const teletransmissionWhere = [];

        for (let i = 0; i < filters.teletransmission.length; i += 1) {
            switch (filters.teletransmission[i]) {
                case "failed":
                    teletransmissionWhere.push({
                        _and: [
                            { client_id: { _eq: 0 } },
                            { visite_id: { _eq: 0 } },
                            { offre_id: { _eq: 0 } },
                        ],
                    });
                    break;
                case "valid":
                    teletransmissionWhere.push({
                        _and: {
                            client_id: { _neq: 0 },
                            visite_id: { _neq: 0 },
                            offre_id: { _neq: 0 },
                        },
                    });
                    break;
                case "invalid":
                    whereClause._and.push({
                        _not: {
                            optimum: {},
                        },
                    });

                    break;
                default:
                    break;
            }
        }
        if (teletransmissionWhere.length > 0) {
            whereClause._and.push({
                optimum: {
                    _or: teletransmissionWhere,
                },
            });
        }
    }
    return whereClause;
};

export const encode = (filters) => {
    const arrangedFilters = { ...filters };

    if (filters.beginCreatedAt) {
        arrangedFilters.beginCreatedAt = format(
            filters.beginCreatedAt,
            "yyyy-MM-dd",
        );
    }

    if (filters.endCreatedAt) {
        arrangedFilters.endCreatedAt = format(
            filters.endCreatedAt,
            "yyyy-MM-dd",
        );
    }

    return queryString.stringify(arrangedFilters, {
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
    });
};

/**
 * decode a url string and transform to react-table array of filter
 * @param {string} urlFilterString
 * @returns {[{id:string,value:string}]}
 */
export const decode = (urlFilterString, globalStatuses, stores) => {
    const arrayToReturn = [];
    if (urlFilterString) {
        const filterObj = queryString.parse(urlFilterString, {
            arrayFormat: "comma",
        });

        if (filterObj.beginCreatedAt || filterObj.endCreatedAt) {
            arrayToReturn.push({
                id: "created_at",
                value: [
                    filterObj.beginCreatedAt
                        ? startOfDay(new Date(filterObj.beginCreatedAt))
                        : null,
                    filterObj.endCreatedAt
                        ? endOfDay(new Date(filterObj.endCreatedAt))
                        : null,
                ],
            });
        }
        if (filterObj.stores || filterObj.destination_store) {
            //when only one value it's considered as string
            const tempStore =
                filterObj.stores && Array.isArray(filterObj.stores)
                    ? [...filterObj.stores]
                    : filterObj.stores;
            const tempDestinationStore =
                filterObj.destination_store &&
                Array.isArray(filterObj.destination_store)
                    ? [...filterObj.destination_store]
                    : filterObj.destination_store;

            arrayToReturn.push({
                id: "store.id",
                value: {
                    store: tempStore
                        ? stores.filter((store) => tempStore.includes(store.id))
                        : null,
                    destination_store: tempDestinationStore
                        ? stores.filter((store) =>
                              tempDestinationStore.includes(store.id),
                          )
                        : null,
                },
            });
        }

        if (filterObj.global_statuses) {
            arrayToReturn.push({
                id: "global_status_id",
                value: globalStatuses.filter((globalStatus) =>
                    filterObj.global_statuses.includes(globalStatus.id),
                ),
            });
        }
        if (filterObj.types) {
            const tempType = Array.isArray(filterObj.types)
                ? [...filterObj.types]
                : [filterObj.types];

            arrayToReturn.push({
                id: "type",
                value: tempType.map((t) => ({ name: t, id: t })),
            });
        }
    }
    return arrayToReturn;
};
