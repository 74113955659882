import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

import PropTypes from "prop-types";
import theme from "theme";

const styles = {
    chip: {
        color: theme.palette.primary.main,
        textAlign: "center",
    },
};

const Label = ({ label, value, style, onExtend, extend, variant, spacing }) => {
    return (
        <Grid container spacing={spacing}>
            <Grid xs={extend ? 6 : onExtend[0]} item>
                <Typography variant={variant}>{label}</Typography>
            </Grid>
            <Grid xs={extend ? 6 : onExtend[1]} item>
                <Paper sx={{ ...styles.chip, ...style }}>
                    {typeof value === "string" && (
                        <Typography variant={variant}>{value}</Typography>
                    )}
                    {typeof value === "object" &&
                        value &&
                        value.type === "url" && (
                            <a
                                href={value.href}
                                target="_blank"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                rel="noopener noreferrer"
                            >
                                <Typography variant={variant}>
                                    {value.text}
                                </Typography>
                            </a>
                        )}
                </Paper>
            </Grid>
        </Grid>
    );
};

Label.defaultProps = {
    extend: false,
    onExtend: [4, 8],
    spacing: 1,
};

Label.propTypes = {
    /** The label / title of the card */
    label: PropTypes.string.isRequired,
    /** The value associated to label props */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            type: PropTypes.oneOf(["url"]),
            href: PropTypes.string,
            text: PropTypes.string,
        }),
    ]).isRequired,
    /** optionnal className associated if styling wanted for the value paper */
    class_name: PropTypes.string,
    /** optionnal array to determine bootstrap grid xs value for each [label, value] */
    onExtend: PropTypes.arrayOf(PropTypes.number),
    /** defaul set to false, needed to personnalize style based on a boolean value */
    extend: PropTypes.bool,
    /** optionnal variant props to set typography variant */
    variant: PropTypes.string,
    /** optionnal props to set spacing, default to 1 */
    spacing: PropTypes.number,
};

export default Label;
