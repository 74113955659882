import axios from "axios";

const getPdfDocuments = async (entity_type, orderId, opticalDataInfo) => {
    let urlApi;

    if (opticalDataInfo) {
        entity_type =
            entity_type === "health_quotation" ? "quote" : entity_type;
        urlApi = `${
            process.env.REACT_APP_OPTICAL_DATA_API_BASE_URL
        }/api/download/${entity_type}/${opticalDataInfo.parentId}/${
            opticalDataInfo.childId
        }?health=${
            opticalDataInfo.country === "FRA" && opticalDataInfo.prescription
                ? "yes"
                : "no"
        }`;
    } else {
        urlApi = `${process.env.REACT_APP_API_BASE_URL}/magentoapiproxy-download?token=${process.env.REACT_APP_API_DOCUMENTS_TOKEN}&type=${entity_type}&id=${orderId}`;
    }

    try {
        const response = await axios.get(urlApi);

        if (!opticalDataInfo && response.data.success === true) {
            window.open(response.data.url);
        } else if (opticalDataInfo && response.status === 200) {
            window.open(urlApi);
        } else {
            alert("Aucun fichier trouvé");
        }
    } catch (error) {
        alert("Aucun fichier trouvé");
        console.error(error);
    }
};

const openPdfDataToNewWindow = (blob, filename = "file.pdf") => {
    const newBlob = new Blob([blob], { type: "application/pdf" });

    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
};

/**
 *
 * @param {{
 *    refs: object,
 *   already_used_labels: number,
 *   display_borders: boolean,
 *   page_margin_top: number,
 *   page_margin_left: number,
 *   page_margin_bottom: number,
 *   page_margin_right: number}} settings
 */
const getPdfLabels = async (settings) => {
    const urlApi = `${process.env.REACT_APP_API_BASE_URL}/documents-generate/adhesive-labels`;
    try {
        const response = await axios.post(
            urlApi,
            { ...settings },
            {
                responseType: "arraybuffer",
                headers: { "Content-Type": "application/json" },
            },
        );

        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const getPdfCustomsDocument = async (settings) => {
    const urlApi = `${process.env.REACT_APP_API_BASE_URL}/documents-generate/doc-export`;
    try {
        const response = await axios.post(
            urlApi,
            { ...settings },
            {
                responseType: "arraybuffer",
                headers: { "Content-Type": "application/json" },
            },
        );
        const filename = response.headers["content-disposition"].split("=")[1];
        if (response.status === 200) {
            return [response.data, filename];
        }
        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export {
    getPdfDocuments,
    openPdfDataToNewWindow,
    getPdfLabels,
    getPdfCustomsDocument,
};
