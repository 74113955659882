import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        blue: {
            maastricht: "#011627",
            electricUltramarine: "#2602FF",
        },
        grey: {
            dark: "#AAA7A7",
            granite: "#646464",
            silver: "#B3B0B0",
            spanish: "#9C9C9C",
            philippine: "#928E8E",
            gainsboro: "#DEDEDE",
            platinum: "#E5E5E5",
        },
        green: {
            texas: "#01A91C",
            may: "#48A14D",
        },
        orange: { crayola: "#F47A36" },
        primary: {
            light: "#939292",
            main: "#000",
            dark: "#191919",
            contrastText: "#FFF",
        },
        red: {
            apple: "#A10101",
            coral: "#F44336",
            corsa: "#D60505",
            crimson: "#D61A3C",
        },
        secondary: {
            light: "#FAFAFA",
            main: "#FFF",
            dark: "#E5E5E5",
            contrastText: "#000",
        },
        mainGrey: {
            main: "#f0f3f4",
        },
        info: {
            main: "#f0f3f4",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        white: {
            absolute: "#FFFFFF",
            antiFlash: "#F0F0F0",
            cultured: "#F8F9F9",
            lotion: "#FAFAFA",
        },
        yellow: {
            main: "#F1E7BD",
        },
    },
    border: {
        radius: {
            circle: 80,
            button: 30,
            card: 20,
            list: 15,
            square: 10,
        },
        width: {
            thinner: 2,
            thin: 3,
            normal: 4,
            thick: 8,
        },
    },
    fontSize: {
        M: 16,
    },
    typography: {
        body: {
            fontSize: "1rem",
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: "0.875rem",
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontWeight: 400,
            lineHeight: 1.43,
        },
        h3: {
            fontSize: "3rem",
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontWeight: 400,
            lineHeight: 1.167,
        },
        h5: {
            fontSize: "1.5rem",
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontWeight: 400,
            lineHeight: 1.334,
        },
        h6: {
            fontSize: "1.25rem",
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontWeight: 500,
            lineHeight: 1.6,
        },
        subtitle1: {
            fontSize: "1rem",
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontWeight: 400,
            lineHeight: 1.75,
        },
    },
    overrides: {
        MuiTab: {
            root: {
                textTransform: "none",
            },
        },
    },
});

export default theme;
