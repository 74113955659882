import React, { useEffect, useState } from "react";
import {
    Box,
    Table,
    TableCell,
    TableBody,
    TableRow,
    Grid,
    Paper,
    TableContainer,
    TableHead,
    Fade,
    Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import { convertDate } from "services/toolbox";

import CareImg from "assets/Care.svg";
import { useTranslation } from "react-i18next";
import theme from "../../theme";

export default function Care({ orderData }) {
    const { t } = useTranslation();
    const [careObj, setCareObj] = useState(null);

    const { is_care_service, caresByOrderIdOrigin, cares } = orderData;

    useEffect(() => {
        /**
         * At the first place, verify if this order is a care
         */
        if (is_care_service) {
            setCareObj(cares);
        } else if (caresByOrderIdOrigin.length) {
            /**
             * Then verify if order has related care(s)
             */
            setCareObj(caresByOrderIdOrigin);
        }
    }, [is_care_service, caresByOrderIdOrigin, cares]);

    const getLinkToRelatedCare = (care) => {
        if (is_care_service) {
            return care.orderOrigin.id;
        } else if (caresByOrderIdOrigin.length) {
            return care.orderCreated.id;
        }
    };

    if (careObj) {
        if (!is_care_service) {
            return (
                <>
                    <Typography>
                        Care(s) service issus de cette commande:{" "}
                    </Typography>
                    <Grid item xs={12}>
                        <Fade in={true} timeout={1100}>
                            <TableContainer component={Paper}>
                                <Table aria-label="table">
                                    <TableHead
                                        sx={{
                                            backgroundColor:
                                                theme.palette.mainGrey.main,
                                        }}
                                    >
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Raison</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Store</TableCell>
                                            <TableCell>
                                                Commande(s) liée(s)
                                            </TableCell>
                                            <TableCell
                                                rowSpan={2}
                                                align="right"
                                            ></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {careObj.map((order) => (
                                            <TableRow>
                                                <TableCell>
                                                    {t(
                                                        `common.care_types.${order.type}`,
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {t(
                                                        `common.care_reasons.${order.reason}`,
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {convertDate(
                                                        order.created_at,
                                                        true,
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {order.store.name}
                                                </TableCell>
                                                <TableCell>
                                                    <Link
                                                        to={`/orders/${getLinkToRelatedCare(
                                                            order,
                                                        )}`}
                                                    >
                                                        {getLinkToRelatedCare(
                                                            order,
                                                        )}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Fade>
                    </Grid>
                </>
            );
        }
        return (
            <Grid container>
                {careObj.map((order) => (
                    <Fade in={true} timeout={1000}>
                        <Grid container justifyContent="center">
                            <Grid item xs={3}>
                                <img width="40%" alt="care" src={CareImg} />
                            </Grid>
                            <Grid item xs={6} justifyContent="space-evenly">
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {t("common.type")}:{" "}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {t(
                                                `common.care_types.${order.type}`,
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {t("common.reason")}:{" "}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {t(
                                                `common.care_reasons.${order.reason}`,
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {t("common.date")}:{" "}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {convertDate(
                                                order.created_at,
                                                true,
                                            )}{" "}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {t("common.store")}:{" "}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {order.store.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {t("common.initialOrder")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            to={`/orders/${getLinkToRelatedCare(
                                                order,
                                            )}`}
                                        >
                                            {getLinkToRelatedCare(order)}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fade>
                ))}
            </Grid>
        );
    }
    return (
        <Box>
            <Typography>Pas de care réalisé sur cette commande</Typography>
        </Box>
    );
}

/**
 *
 * Ex de commande avec care : http://localhost:3000/orders/MAG600030068
 *
 */
