import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppBar, Container, Tab, Tabs } from "@mui/material";

import { useQuery } from "@apollo/client";

import PrescriptionIcon from "assets/prescription.svg";
import ContentWrapper from "components/Layout/ContentWithHeader";
import VitaleCardLogo from "components/common/VitaleCardLogo";
import TabPanel from "./TabPanel";
import OrderInfos from "./OrderInfos";
import ProductInfos from "./ProductInfos";
import CustomerInfos from "./CustomerInfos";
import { getPdfDocuments } from "services/utils/pdf";
import { orderQueryTypes, orderByIdQuery } from "./utils/queries.graphql";
import InsideLoader from "components/common/InsideLoader";
import Care from "./Care";
import { useTranslation } from "react-i18next";
import Teletransmission from "./Teletransmission";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Chip from "@mui/material/Chip";
import DropdownListMenu from "components/DropdownMenuList";
import theme from "theme";
import { extractIds } from "../Orders/utils";

const styles = {
    root: {
        flexGrow: 1,
        backgroundColor: "white",
        borderRadius: "5px",
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    buttonJF: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "48px",
        color: theme.palette.secondary.main,
        marginLeft: 15,
    },
    addressTitle: {
        fontWeight: 600,
        paddingBottom: 15,
    },
    addressBlock: {
        padding: 15,
        margin: 5,
        backgroundColor: theme.palette.grey[200],
        borderRadius: 8,
        minHeight: 200,
        textAlign: "center",
    },
    careChip: {
        marginLeft: theme.spacing(2),
        border: `2px solid ${theme.palette.blue.electricUltramarine}`,
        backgroundColor: `${theme.palette.blue.electricUltramarine}20`,
        color: theme.palette.blue.electricUltramarine,
        fontWeight: "bold",
    },
    careChipIcon: { color: "#2602FF" },
    vitaleCardLogoTitle: {
        width: "40px",
        height: "auto",
        marginLeft: theme.spacing(2),
    },
};

export default function OrderDetailsPage() {
    const { id } = useParams();
    const [value, setValue] = useState(0);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { data, loading, error } = useQuery(
        orderByIdQuery(orderQueryTypes.queryByPK),
        {
            variables: { id },
        },
    );

    // force the reset of tab when clicking on care links
    useEffect(() => {
        setValue(0);
    }, [location.pathname]);

    if (error) {
        console.error(error);
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const clickableIcons = (orderId, opticalDataInfo) => {
        const icons = [
            {
                label: t("common.invoice"),
                name: "description_outlined",
                onPress: () => {
                    getPdfDocuments(
                        "invoice",
                        orderId.substring(3),
                        opticalDataInfo,
                    );
                },
            },
        ];

        if (
            data?.orders_by_pk?.prescriptions?.[0]?.url ||
            opticalDataInfo.prescription
        ) {
            icons.push({
                label: t("common.medicalPrescription"),
                svg: PrescriptionIcon,
                onPress: async () => {
                    const urlApi = !opticalDataInfo
                        ? `${process.env.REACT_APP_BASE_URL_PRESCRIPTION}/${data.orders_by_pk.prescriptions[0].url}`
                        : `${process.env.REACT_APP_OPTICAL_DATA_API_BASE_URL}/orders/${opticalDataInfo.parentId}/line-items/${opticalDataInfo.childId}`;
                    window.open(urlApi);
                },
            });
        }

        if (data.orders_by_pk.type !== "VD" && !data?.orders_by_pk?.optimum) {
            icons.push({
                label: t("common.careSheet"),
                name: "local_hospital_outlined",
                onPress: () => {
                    getPdfDocuments(
                        "caresheet",
                        orderId.substring(3),
                        opticalDataInfo,
                    );
                },
            });
        }

        if (data.orders_by_pk.type !== "VD") {
            icons.push({
                label: t("pages.orderDetails.quote"),
                name: "receipt_outlined",
                onPress: () => {
                    getPdfDocuments(
                        "health_quotation",
                        orderId.substring(3),
                        opticalDataInfo,
                    );
                },
            });
        }
        /**
         *   else => health_quotation
         *   future implementation
         */
        return icons;
    };

    const handleGoBack = () => {
        if (history.length > 1) {
            return history.goBack();
        }
        return history.push("/orders");
    };

    const RenderDownloadPdfList = ({ opticalDataInfo }) => {
        return (
            <DropdownListMenu
                title={t("common.download")}
                items={clickableIcons(id, opticalDataInfo)}
            />
        );
    };

    if (!loading) {
        const {
            orders_by_pk: orderData,
            order_statuses_definitions: orderStatusesDefinition,
        } = data || {};

        if (!data) return null;

        const { mainId, secondaryId } = extractIds(orderData);

        return (
            <ContentWrapper
                TitleComponent={() => (
                    <>
                        {mainId} - {orderData.customer_account.firstname}{" "}
                        {orderData.customer_account.lastname}
                        {
                            <VitaleCardLogo
                                transmissionStatus={
                                    orderData.optimum
                                        ? orderData.optimum.transmission_status
                                        : "no"
                                }
                                style={styles.vitaleCardLogoTitle}
                            />
                        }
                        {orderData.is_care_service && (
                            <Chip
                                label={t("common.care")}
                                sx={styles.careChip}
                                icon={
                                    <HealthAndSafetyIcon
                                        sx={styles.careChipIcon}
                                    />
                                }
                            />
                        )}
                    </>
                )}
                subtitle={secondaryId}
                goBackTo={handleGoBack}
            >
                <Container sx={styles.root}>
                    <AppBar
                        position="static"
                        style={{
                            background: "transparent",
                            boxShadow: "none",
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab
                                label={t("common.clientOrder_plural")}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={t("common.product_plural")}
                                {...a11yProps(1)}
                            />
                            <Tab
                                label={t("common.customer_plural")}
                                {...a11yProps(2)}
                            />
                            {(orderData.is_care_service ||
                                orderData.caresByOrderIdOrigin.length > 0) && (
                                <Tab
                                    label={t("common.customerService")}
                                    {...a11yProps(3)}
                                />
                            )}
                            {orderData.optimum && i18n.language === "fr-FR" && (
                                <Tab
                                    label="Télétransmission"
                                    {...a11yProps(4)}
                                />
                            )}
                        </Tabs>
                    </AppBar>

                    <TabPanel value={value} index={0}>
                        <OrderInfos
                            styles={styles}
                            orderData={orderData}
                            order_statuses_definitions={orderStatusesDefinition}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ProductInfos
                            styles={styles}
                            orderData={orderData}
                            RenderDownloadPdfList={RenderDownloadPdfList}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <CustomerInfos orderData={orderData} styles={styles} />
                    </TabPanel>
                    {(orderData.is_care_service ||
                        orderData.caresByOrderIdOrigin.length > 0) && (
                        <TabPanel value={value} index={3}>
                            <Care orderData={orderData} styles={styles} />
                        </TabPanel>
                    )}
                    {orderData.optimum && i18n.language === "fr-FR" && (
                        <TabPanel
                            value={value}
                            index={
                                orderData.is_care_service ||
                                orderData.caresByOrderIdOrigin.length > 0
                                    ? 4
                                    : 3
                            }
                        >
                            <Teletransmission
                                orderData={orderData}
                                styles={styles}
                            />
                        </TabPanel>
                    )}
                </Container>
            </ContentWrapper>
        );
    }

    return <InsideLoader />;
}
