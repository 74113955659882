import React from "react";
import styled from "@mui/styles/styled";
import Skeleton from "@mui/material/Skeleton";
import theme from "theme";

const styles = {
    loadingSkeleton: {
        padding: 1,
        borderRadius: theme.border.radius.button,
    },
};

const StyledContainer = styled("div")(() => ({
    border: "solid",
    padding: 18,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: theme.border.radius.list,
    borderColor: theme.palette.grey.gainsboro,
}));

const LoaderRowsRoundedSkeleton = ({ numberOfRow, loading }) => {
    if (!loading) {
        return null;
    }
    return [...Array(numberOfRow)].map((value, index) => (
        <StyledContainer key={`loadingSkel${index}`}>
            <Skeleton animation="wave" sx={styles.loadingSkeleton} />
        </StyledContainer>
    ));
};

LoaderRowsRoundedSkeleton.defaultProps = {
    numberOfRow: 10,
};

export default LoaderRowsRoundedSkeleton;
