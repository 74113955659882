import React, { useState, useContext, useEffect } from "react";
import styled from "@mui/styles/styled";

import { AuthContext } from "AuthProvider";
import Header from "./Header";
import VerticalMenu from "./VerticalMenu";
import { getDeviceType } from "services/toolbox";

const StyledContainer = styled("div")(() => ({
    display: "flex",
    height: "100%",
}));

const StyledMain = styled("main")(() => ({
    flexGrow: 1,
    width: "100%",
    overflowX: "hidden",
    overflowY: "auto",
}));

const StyledToolBar = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function Layout({ children }) {
    const [open, setOpen] = useState(true);

    const { welcomeAnimation } = useContext(AuthContext);

    useEffect(() => {
        if (getDeviceType() === "desktop") setOpen(!welcomeAnimation);
    }, [welcomeAnimation]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <StyledContainer>
            <Header handleDrawerOpen={handleDrawerOpen} open={open} />
            <VerticalMenu handleDrawerClose={handleDrawerClose} open={open} />
            <StyledMain>
                <StyledToolBar />
                {children}
            </StyledMain>
        </StyledContainer>
    );
}

export default Layout;
