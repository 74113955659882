import { Fragment } from "react";
import { Grid } from "@mui/material";
import ProductImageCard from "components/common/ProductImageCard";
import TablePackProduct from "components/TablePackProduct";
import TablePrescriptionProduct from "components/common/TablePrescription";
import { getProductType } from "services/utils/products";

const ProductInfoDetails = ({ getProductPrice, orderLine, prescriptions }) => (
    <Grid container spacing={4}>
        {/** CARD CONTENT => DISPLAY PRODUCT IMAGE + SOME UTILS INFOS */}
        {getProductType(orderLine) === "ACCESSORY" ? (
            <ProductImageCard
                price={getProductPrice()}
                label={orderLine.accessory.label}
                url={orderLine.accessory.image && orderLine.accessory.image.url}
                id={orderLine.accessory.id}
            />
        ) : getProductType(orderLine) === "FRAME" ? (
            <ProductImageCard
                price={getProductPrice()}
                label={orderLine.frame.label}
                url={orderLine.frame.image && orderLine.frame.image.url}
                id={orderLine.frame.id}
            />
        ) : getProductType(orderLine) === "LENS" ? (
            <ProductImageCard
                price={getProductPrice()}
                label={orderLine.lens_pack.label}
                id={"verres seuls"}
            />
        ) : (
            getProductType(orderLine) === "PACK" && (
                <ProductImageCard
                    price={getProductPrice()}
                    label={orderLine.frame.label}
                    url={orderLine.frame.image && orderLine.frame.image.url}
                    id={orderLine.frame.id}
                />
            )
        )}
        <Grid item xs={9}>
            <Grid container spacing={3}>
                {/**TABLE TO SHOW INFOS ABOUT GLASSES TYPES, PACK, AFFI, OPTS AND PRICES */}
                {getProductType(orderLine) === "PACK" && (
                    <TablePackProduct
                        lens_pack={orderLine.lens_pack}
                        option={orderLine.option}
                        refinement={orderLine.refinement}
                    />
                )}
                {/** TABLE TO DISPLAY LEFT AND RIGHT LENSES INFOS */}
                {prescriptions && prescriptions.length
                    ? prescriptions.map((prescription, index) => (
                          <Fragment key={index}>
                              <TablePrescriptionProduct
                                  prescription={prescription}
                              />
                          </Fragment>
                      ))
                    : ""}
            </Grid>
        </Grid>
    </Grid>
);

export default ProductInfoDetails;
