import React from "react";
import { Box } from "@mui/material";
import styled from "@mui/styles/styled";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import Text from "components/Text";
import { StyledVitalCardLogo } from "pages/Receipt/OrderReceipt/styledComponents";

const TRANS_PATH = "pages.receipt.scan";
const DETRACTOR_SCORE = 6;

const layoutContent = (t, isAdmission) => {
    if (isAdmission) {
        return {
            dialogTitle: t(`${TRANS_PATH}.admissionDialogTitle`),
            dialogContent: t(`${TRANS_PATH}.admissionDialogContent`),
            boldContent: t(`${TRANS_PATH}.availableAtStore`),
            disclaimer: t(`${TRANS_PATH}.disclaimerContent`),
            buttons: {
                confirm: t("common.confirm"),
                cancel: t("common.cancel"),
            },
            status: "ORDER_AT_SHOP",
        };
    }
    return {
        dialogTitle: t(`${TRANS_PATH}.handOverDialogTitle`),
        dialogContent: t(`${TRANS_PATH}.handOverDialogContent`),
        boldContent: t(`${TRANS_PATH}.ended`),
        disclaimer: t(`${TRANS_PATH}.disclaimerContent`),
        buttons: {
            confirm: t("common.confirmDeliveryToCustomer"),
            cancel: t("common.cancel"),
        },
        status: "ORDER_DELIVERED",
    };
};

const StyledTitleContainer = styled("div")(() => ({
    display: "flex",
    textAlign: "center",
}));

const StyledBoldTitle = styled("div")(({ theme }) => ({
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.blue.maastricht,
}));

const StyledTag = styled("div")(({ theme, isDetractor }) => {
    if (isDetractor) {
        return {
            ...theme.typography.h6,
            fontWeight: "bold",
            color: theme.palette.blue.maastricht,
        };
    }
    return {
        ...theme.typography.h6,
        fontWeight: "bold",
        color: theme.palette.blue.maastricht,
    };
});

const StyledTagsContainer = styled(Box)(({ theme }) => ({
    maxHeight: "38vh",
    overflowY: "auto",
    textAlign: "center",
    marginTop: theme.spacing(1),
}));

const StyledDetractorIcon = styled("span")(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

const StyledInfoContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginRight: theme.spacing(1.5),
    color: theme.palette.red.corsa,
}));

const StyledInfoText = styled("div")(({ theme }) => ({
    fontSize: theme.fontSize.M,
    textAlign: "center",
    whiteSpace: "pre-line",
    color: theme.palette.red.corsa,
}));

const ConfirmationPopUp = ({
    open,
    isLoading,
    tags,
    onClickCancel,
    onClickConfirm,
    layout,
}) => {
    const { t } = useTranslation();
    const isAdmission = layout === "admission";
    const haveDetractor = tags.some(
        (tag) => tag.satisfactionAvg <= DETRACTOR_SCORE,
    );

    const { dialogTitle, dialogContent, boldContent, disclaimer, buttons } =
        layoutContent(t, isAdmission);
    return (
        <ConfirmationDialog
            open={open}
            title={dialogTitle}
            buttonLabels={buttons}
            handleOK={onClickConfirm}
            handleNOK={onClickCancel}
            isLoading={isLoading}
        >
            <StyledTitleContainer>
                <Text variant="body1">{dialogContent}</Text>
                <StyledBoldTitle>{boldContent}</StyledBoldTitle>
            </StyledTitleContainer>
            <StyledTagsContainer>
                {tags
                    .filter((tag) => tag.isValid)
                    .map((tag) => (
                        <StyledTag
                            isDetractor={
                                tag.satisfactionAvg <= DETRACTOR_SCORE &&
                                isAdmission
                            }
                        >
                            {`${
                                tag.customerName ? tag.customerName + " - " : ""
                            } ${tag.value}`}
                            <StyledVitalCardLogo
                                transmissionStatus={
                                    tag.optimum?.transmission_status &&
                                    tag.optimum?.transmission_status === "valid"
                                        ? tag.optimum?.transmission_status
                                        : null
                                }
                            />
                            {tag.satisfactionAvg <= DETRACTOR_SCORE &&
                                isAdmission && (
                                    <StyledDetractorIcon
                                        role="img"
                                        aria-label="pouting face"
                                    >
                                        😡
                                    </StyledDetractorIcon>
                                )}
                        </StyledTag>
                    ))}
            </StyledTagsContainer>
            {haveDetractor && isAdmission && (
                <div style={{ justifyContent: "center" }}>
                    <StyledInfoContainer>
                        <StyledInfoIcon />
                        <StyledInfoText>
                            😡 = {t("common.detractor")}
                        </StyledInfoText>
                    </StyledInfoContainer>
                    <StyledInfoText>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: disclaimer,
                            }}
                        />
                    </StyledInfoText>
                </div>
            )}
        </ConfirmationDialog>
    );
};

export default ConfirmationPopUp;
