import React from "react";
import styled from "@mui/styles/styled";
import { useTranslation } from "react-i18next";
import { StyledVitalCardLogo } from "pages/Receipt/OrderReceipt/styledComponents";
import Text from "components/Text";
import theme from "theme";

const styles = {
    orderInfo: {
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
    },
    orderText: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontSize: theme.fontSize.M,
    },
};

const StyledContainer = styled("div")(() => ({
    maxHeight: "38vh",
    overflowY: "auto",
    padding: theme.spacing(1),
}));

const StyledOrderRowContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}));

const TRANS_PATH = "pages.receipt.stockReceiptPopUp.badDestinationPopUp";

const OrderList = ({ orders }) => {
    const { t } = useTranslation();
    return (
        <StyledContainer>
            {orders.map((order) => (
                <StyledOrderRowContainer>
                    <Text style={styles.orderInfo}>
                        {order.value}{" "}
                        <StyledVitalCardLogo
                            transmissionStatus={
                                order.optimum?.transmission_status &&
                                order.optimum?.transmission_status === "valid"
                                    ? order.optimum?.transmission_status
                                    : null
                            }
                        />
                    </Text>
                    <Text style={styles.orderText}>
                        {t(`${TRANS_PATH}.orderListBody`)}
                    </Text>
                    <Text style={styles.orderInfo}>
                        {order.destinationStore.name}
                    </Text>
                </StyledOrderRowContainer>
            ))}
        </StyledContainer>
    );
};

export default OrderList;
