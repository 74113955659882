import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, AppBar, Tab, Tabs } from "@mui/material";

import { useQuery, gql } from "@apollo/client";

import ContentWrapper from "components/Layout/ContentWithHeader";
import TabPanel from "./TabPanel";
import CustomerInfo from "./CustomerInfo";
import Prescriptions from "./Prescriptions";
import InsideLoader from "components/common/InsideLoader";
import { useTranslation } from "react-i18next";
import theme from "theme";

const customerByIdQuery = gql`
    query CustomerById($id: uuid!) {
        customer_accounts_by_pk(id: $id) {
            id
            external_id
            created_at
            firstname
            lastname
            email
            phone_number
            birthday
            subscriber
            orders(order_by: { created_at: desc }, limit: 50) {
                prescriptions(order_by: { created_at: desc }) {
                    created_at
                    doctor
                    doctor_number
                    left_axis
                    left_cylinder
                    left_pd
                    left_sphere
                    order_id
                    right_axis
                    right_cylinder
                    right_pd
                    right_sphere
                    type
                }
                cares {
                    reason
                    orderOrigin {
                        id
                    }
                }
                order_product_lines {
                    left_final_reference
                    lens_pack {
                        label
                    }
                    frame {
                        label
                    }
                }
                created_at
                id
                store_id
                store_destination_id
                global_status_id
                type
                currency
                flat_amount_including_tax
                is_care_service
                carrier_type
                global_status_id
                shipping_address {
                    firstname
                    lastname
                    company
                    line1
                    line2
                    postal_code
                    city
                    country_code
                    external_id
                }
                store {
                    id
                }
                destination_store {
                    id
                }
                cares {
                    reason
                }
                optimum {
                    transmission_status
                }
                lastStatus: order_status_changes(
                    order_by: { created_at: desc }
                    limit: 1
                ) {
                    created_at
                }
            }
        }
    }
`;

const styles = {
    root: {
        flexGrow: 1,
        backgroundColor: "white",
        borderRadius: "5px",
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    pageBlock: {
        marginTop: theme.spacing(1),
    },
    addressTitle: {
        fontWeight: 600,
        paddingBottom: 15,
    },
    addressBlock: {
        padding: 15,
        margin: 5,
        backgroundColor: theme.palette.grey[200],
        borderRadius: 8,
        minHeight: 200,
        textAlign: "center",
    },
    card: {
        maxWidth: 345,
    },
    cardMedia: {
        height: 140,
    },
    cardHeader: {
        textAlign: "center",
    },
    packTableMain: {
        backgroundColor: theme.palette.mainGrey.main,
    },
    subtitle: {
        borderBottom: "1px solid #000",
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        fontWeight: "bold",
    },
    customPanSum: {
        padding: "0px 16px",
    },
    customPanDet: {
        padding: "0px 16px",
        flexDirection: "column",
    },
    warningChip: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.warning.main,
        fontWeight: 600,
        border: "none",
    },
    successChip: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.success.main,
        fontWeight: 600,
        border: "none",
    },
};

export default function CustomerDetailsPage() {
    const { id } = useParams();
    const history = useHistory();
    const { t } = useTranslation();

    const { data, loading } = useQuery(customerByIdQuery, {
        variables: { id: id },
    });

    const renderData = (src) => {
        return Object.assign({}, src);
    };
    let customerData = {};
    if (!loading) {
        customerData = renderData(data.customer_accounts_by_pk);
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const handleGoBack = () => {
        if (history.length > 1) {
            return history.goBack();
        }
        return history.push("/customers");
    };

    return (
        <>
            {!loading ? (
                <ContentWrapper
                    title={`${customerData.external_id} - ${customerData.firstname} ${customerData.lastname}`}
                    goBackTo={handleGoBack}
                >
                    <Container sx={styles.root}>
                        <AppBar
                            position="static"
                            style={{
                                background: "transparent",
                                boxShadow: "none",
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab
                                    label={t("common.customer")}
                                    {...a11yProps(0)}
                                />
                                {/*<Tab*/}
                                {/*    label={t("common.clientOrder_plural")}*/}
                                {/*    {...a11yProps(1)}*/}
                                {/*/>*/}
                                <Tab
                                    label={t("common.prescription_plural")}
                                    {...a11yProps(2)}
                                />
                            </Tabs>
                        </AppBar>

                        <TabPanel value={value} index={0}>
                            <CustomerInfo customerData={customerData} />
                        </TabPanel>
                        {/*<TabPanel value={value} index={1}>*/}
                        {/*    <OrderHistory*/}
                        {/*        styles={styles}*/}
                        {/*        customerData={customerData}*/}
                        {/*    />*/}
                        {/*</TabPanel>*/}
                        <TabPanel value={value} index={2}>
                            <Prescriptions customerData={customerData} />
                        </TabPanel>
                    </Container>
                </ContentWrapper>
            ) : (
                <InsideLoader />
            )}
        </>
    );
}
