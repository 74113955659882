import React from "react";
import { styled } from "@mui/styles";

const Text = ({ children, variant, fontWeight, style }) => {
    const StyledComponent = styled("div")(({ theme }) => ({
        ...theme.typography[variant || "body"],
        fontWeight: fontWeight,
        ...style,
    }));
    return <StyledComponent>{children}</StyledComponent>;
};

export default Text;
