import React from "react";
import styled from "@mui/styles/styled";
import Text from "components/Text";
import theme from "theme";

const bulletPointSizeStyles = {
    M: {
        height: 24,
        width: 24,
        fontSize: 20,
    },
    L: {
        height: 34,
        width: 34,
        fontSize: 24,
    },
};

const styles = {
    textLine: {
        alignSelf: "center",
        fontSize: theme.fontSize.M,
        fontWeight: "bold",
        color: theme.palette.grey.granite,
    },
};

const StyleContainer = styled("div")(({ style }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    ...style,
}));

const StyledListLine = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
}));

const StyledBulletPoint = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
    color: theme.palette.white.absolute,
    fontWeight: "bold",
    borderRadius: "50%",
    backgroundColor: theme.palette.blue.maastricht,
}));

const BulletPointList = ({ list, size, style }) => {
    const _RenderLine = (bulletPointNumber, line) => (
        <StyledListLine>
            <StyledBulletPoint style={bulletPointSizeStyles[size]}>
                {bulletPointNumber}
            </StyledBulletPoint>
            <Text style={styles.textLine}> {line} </Text>
        </StyledListLine>
    );
    return (
        <StyleContainer style={style}>
            {list.map((line, index) => _RenderLine(index + 1, line))}
        </StyleContainer>
    );
};

BulletPointList.defaultProps = {
    size: "M",
};

export default BulletPointList;
