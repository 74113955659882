import React from "react";
import styled from "@mui/styles/styled";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "theme";

const TRANS_PATH = "pages.satisfaction.reportPopUp";

const styles = {
    description: {
        fontSize: 18,
        marginTop: theme.spacing(4),
        color: theme.palette.grey.granite,
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        marginTop: theme.spacing(2),
        color: theme.palette.blue.maastricht,
    },
    useFullInfo: {
        fontSize: 14,
        color: theme.palette.grey.granite,
    },
    useFullInfoTitle: {
        fontSize: 14,
        fontWeight: "bold",
        alignSelf: "flex-start",
        marginTop: theme.spacing(4),
        color: theme.palette.grey.granite,
    },
};

const StyledUseFullContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignSelf: "flex-start",
    marginTop: theme.spacing(2),
}));

const StyledUseFullInfoContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const StyledUseFullInfoSecondContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(5),
}));

const SatisfactionInformation = ({ data }) => {
    const { t } = useTranslation();
    const customerFirstname = data?.order?.customer_account?.firstname || "";
    const customerLastname = data?.order?.customer_account?.firstname || "";
    const customerNickName = `${customerFirstname} ${customerLastname}`;
    const orderId = data?.order?.id;
    const category = data?.category
        ? t(`${TRANS_PATH}.categories.${data?.category}`)
        : "";
    const type = data?.type ? t(`${TRANS_PATH}.types.${data?.type}`) : "";
    return (
        <>
            <Typography sx={styles.title}>
                {t(`${TRANS_PATH}.title`)}
            </Typography>
            <Typography sx={styles.description}>
                {t(`${TRANS_PATH}.description`)}
            </Typography>
            <Typography sx={styles.useFullInfoTitle}>
                {t(`${TRANS_PATH}.useFullInfo`)}
            </Typography>
            <StyledUseFullContainer>
                <StyledUseFullInfoContainer>
                    <Typography sx={styles.useFullInfo}>
                        {`${t("common.clientName")}: ${customerNickName}`}
                    </Typography>
                    <Typography sx={styles.useFullInfo}>
                        {`${t("common.command")}: ${orderId}`}
                    </Typography>
                </StyledUseFullInfoContainer>
                <StyledUseFullInfoSecondContainer>
                    <Typography sx={styles.useFullInfo}>
                        {`${t("common.category")}: ${category}`}
                    </Typography>
                    <Typography sx={styles.useFullInfo}>
                        {`${t(`${TRANS_PATH}.noteType`)}: ${type}`}
                    </Typography>
                </StyledUseFullInfoSecondContainer>
            </StyledUseFullContainer>
        </>
    );
};

export default SatisfactionInformation;
