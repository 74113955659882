import React, { useEffect, useRef, useState } from "react";
import { Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const StyledSearchTextField = styled(TextField)(({ theme, style = {} }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: 8,
    height: 40,
    width: 500,
    paddingLeft: 5,
    marginLeft: "-16px",
    ...style,
}));

const StyledBox = styled(Box)(({ style }) => ({
    margin: "20px 0 20px",
    ...style,
}));

const TableToolbar = ({
    onChange,
    placeHolder,
    style,
    inputStyle,
    fontSize,
    disableUnderline,
}) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState("");
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            onChange(searchText);
        }, 1000);
    }, [searchText, onChange]);

    return (
        <StyledBox style={style} display="flex" justifyContent="center">
            <StyledSearchTextField
                autoFocus
                variant="standard"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                placeholder={placeHolder}
                style={inputStyle}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Tooltip title={t("common.search")}>
                                <SearchIcon fontSize={fontSize} />
                            </Tooltip>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disabled={!searchText}
                                onClick={() => setSearchText("")}
                                aria-label={"Clear search"}
                            >
                                <CloseIcon fontSize={fontSize} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    inputProps: {
                        "aria-label": t("common.search"),
                        "data-hj-allow": true,
                    },
                    disableUnderline: disableUnderline,
                }}
            />
        </StyledBox>
    );
};

TableToolbar.defaulProps = {
    fontSize: "small",
};

export default TableToolbar;
