import { isAfter, parseISO } from "date-fns";

const setWithExpiry = (key, value, expiryDate) => {
    const item = {
        value: value,
        expiry: expiryDate,
    };
    localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    const expiry = new Date(item.expiry);

    if (isAfter(now, expiry)) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value ? parseISO(item.value) : null;
};

export { setWithExpiry, getWithExpiry };
