import React, { useContext, useCallback, useEffect } from "react";
import styled from "@mui/styles/styled";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Box } from "@mui/material";

import "services/firebase";
import {
    getAuth,
    GoogleAuthProvider,
    browserLocalPersistence,
    setPersistence,
    signInWithPopup,
} from "firebase/auth";

import config from "services/firebase";
import { AuthContext } from "AuthProvider";
import Logo from "assets/logoMini.png";
import theme from "theme";

/**
 * Login page, skipped if the user is already loggedin with persistence else the user must login with his recognized by jimmy fairly
 * email
 */

const StyledContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
}));

const Login = () => {
    const { authenticated, setWelcomeAnimation } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();

    let { from } = location.state || { from: { pathname: "/" } };

    useEffect(() => {
        if (authenticated) {
            setWelcomeAnimation(true);
            history.replace(from);
        }
    }, [authenticated, from, history, setWelcomeAnimation]);

    const handleLogin = useCallback(async () => {
        try {
            let provider = new GoogleAuthProvider();
            const auth = getAuth(config);
            await setPersistence(auth, browserLocalPersistence);
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <StyledContainer>
            <Box
                textAlign="center"
                bgcolor={theme.palette.secondary.main}
                p={15}
                borderRadius="10px"
            >
                <Box marginBottom="2rem">
                    <img
                        style={{ borderRadius: "10px", width: "70%" }}
                        src={Logo}
                        alt="Jimmy Fairly"
                    />
                </Box>
                <Box>
                    <Button onClick={handleLogin} variant="contained">
                        Login with google
                    </Button>
                </Box>
            </Box>
        </StyledContainer>
    );
};
export default Login;
