import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from "react";
import { useQuery } from "@apollo/client";
import { endOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
    orderQuery,
    globalStatusesQuery,
    storesQuery,
} from "./queries.graphql";
import InsideTable from "components/Table";
import HeaderSimple from "components/Table/HeaderComponents/HeaderSimple";
import ContentWrapper from "components/Layout/ContentWithHeader";
import { AuthContext } from "AuthProvider";
import InsideLoader from "components/common/InsideLoader";
import {
    makeColumns,
    encode,
    decode,
    formatFiltersForGraphQL,
    buildGraphQLWhereClauseWithFilters,
    extractIds,
} from "./utils";
import { setWithExpiry, getWithExpiry } from "services/utils/localStorage";
import FooterPaginationSimple from "components/Table/FooterComponents/FooterPaginationSimple";
import BodyTableSimple from "components/Table/BodyComponents/BodyTableSimple";
import { atLeastOneNumber } from "services/utils/regex";

// import { tableData } from "./__fixtures__";

const INITIAL_SORT_BY = [{ id: "created_at", desc: true }];

const BEGIN_DATE_LOCAL_STORAGE_KEY = "BEGIN_DATE_FILTER_ORDERS_TABLE";
const END_DATE_LOCAL_STORAGE_KEY = "END_DATE_FILTER_ORDERS_TABLE";

const OrdersPage = () => {
    const lastRemoteDataHandlerParams = useRef(null);
    const lastStoresFetchParam = useRef([]);
    const [columns, setColumns] = useState(null);
    const [initialFilters, setInitialFilters] = useState(null);
    const [fetchOrderStatus, setFetchOrderStatus] = useState({
        isLoading: true,
        data: [],
        page: 0,
        totalCount: 0,
    });

    const { refetch, error } = useQuery(orderQuery, { skip: true });
    const {
        data: storesData,
        loading: storesLoading,
        refetch: refetchStores,
    } = useQuery(storesQuery, { variables: { stores: [] } });
    const { data: globalStatusesData, loading: globalStatusLoading } =
        useQuery(globalStatusesQuery) || {};

    const { storeId, stores } = useContext(AuthContext);
    const { t, i18n } = useTranslation();

    if (error) {
        console.error(error);
    }

    const history = useHistory();
    const location = useLocation();

    const getStatusTranslated = useCallback(() => {
        if (!globalStatusLoading) {
            const { order_statuses_definitions } = globalStatusesData;

            return order_statuses_definitions.reduce(
                (acc, status) => ({
                    ...acc,
                    [status.id]: t(`orderStatus:${status.id}.label`),
                }),
                {},
            );
        }
        return [];
    }, [globalStatusLoading, globalStatusesData, t]);

    useEffect(() => {
        if (
            !storesLoading &&
            stores.length !== 0 &&
            storesData &&
            globalStatusesData &&
            !globalStatusLoading
        ) {
            const { destinations_stores } = storesData;
            const { order_statuses_definitions } = globalStatusesData;
            const statusTranslated = getStatusTranslated();
            const locale = i18n.language;
            const columnsTemp = makeColumns({
                stores,
                t,
                statusTranslated,
                locale,
                destinations_stores,
                storesLoading,
            });

            const filtersFromUrl = decode(
                location.search,
                order_statuses_definitions,
                stores,
            );
            const beginDateFilterFromCache = getWithExpiry(
                BEGIN_DATE_LOCAL_STORAGE_KEY,
            );
            const endDateFilterFromCache = getWithExpiry(
                END_DATE_LOCAL_STORAGE_KEY,
            );

            const dateUrlFilter = [
                {
                    id: "created_at",
                    value: [beginDateFilterFromCache, endDateFilterFromCache],
                },
            ];

            const storeInitialFilter = storeId
                ? [
                      {
                          id: "store.id",
                          value: { store: [{ id: storeId }] },
                      },
                  ]
                : [];

            const initialFiltersTemp = [
                ...(filtersFromUrl.length
                    ? filtersFromUrl
                    : [...dateUrlFilter, ...storeInitialFilter]),
            ];
            setColumns(columnsTemp);
            setInitialFilters(initialFiltersTemp);
        }
        // eslint-disable-next-line
    }, [
        stores,
        getStatusTranslated,
        storeId,
        storesData,
        storesLoading,
        globalStatusesData,
        globalStatusLoading,
        i18n,
        t,
    ]);

    const sortByFormatter = (sortBy) => {
        if (!sortBy.length) {
            return {};
        }
        const { id: sortId, desc } = sortBy[0];
        return { [sortId]: desc ? "desc" : "asc" };
    };

    const remoteDataHandler = useCallback(
        async (remoteDataHandlerParams) => {
            const { pageSize, pageIndex, filters, globalFilter, sortBy } =
                remoteDataHandlerParams || {};

            const filtersFormatted = formatFiltersForGraphQL(
                filters,
                globalFilter,
            );
            const searchByNameAndLastName =
                globalFilter?.split(" ")?.length >= 2;
            const searchByOrderId =
                atLeastOneNumber.test(globalFilter) &&
                !globalFilter.includes("@");
            const searchByMail = globalFilter && globalFilter.includes("@");
            const searchOption = {
                searchByNameAndLastName,
                searchByOrderId,
                searchByMail,
            };
            const whereClause = buildGraphQLWhereClauseWithFilters(
                filtersFormatted,
                searchOption,
            );
            const sortByFormatted = sortByFormatter(sortBy);
            const fetchVariables = {
                limit: pageSize,
                offset: pageIndex * pageSize,
                order_by: sortByFormatted,
                where: whereClause,
            };
            setFetchOrderStatus((currentFetchOrderStatus) => ({
                ...currentFetchOrderStatus,
                isLoading: true,
            }));
            /*
             * save params of data handler
             * */
            lastRemoteDataHandlerParams.current = remoteDataHandlerParams;

            try {
                const { beginCreatedAt, endCreatedAt } = fetchVariables;
                const storesFetchVars = fetchVariables.where._and.find(
                    (param) => param.store,
                );
                if (
                    JSON.stringify(storesFetchVars) !==
                    JSON.stringify(lastStoresFetchParam.current)
                ) {
                    lastStoresFetchParam.current =
                        storesFetchVars?.store?.id?._in;
                    await refetchStores({
                        stores: storesFetchVars?.store?.id?._in || [],
                    });
                }

                history.push({
                    pathname: location.pathname,
                    search: encode(filtersFormatted),
                });

                const {
                    data: ordersData,
                    errors: refetchErrors,
                    error: refetchError,
                } = await refetch(fetchVariables);

                const orders = ordersData?.orders.map((orderData) => ({
                    ...orderData,
                    ids: extractIds(orderData),
                }));

                if (refetchErrors || refetchError) {
                    console.error("Problem fetching data");
                    console.debug(refetchErrors);
                    console.debug(refetchError);
                }

                setWithExpiry(
                    BEGIN_DATE_LOCAL_STORAGE_KEY,
                    beginCreatedAt,
                    endOfDay(new Date()),
                );
                setWithExpiry(
                    END_DATE_LOCAL_STORAGE_KEY,
                    endCreatedAt,
                    endOfDay(new Date()),
                );
                /*
                 * Set table data info only if last saved params are same of current params
                 * in case of <InsideTable/> call remote data handler
                 * before last remote data handler call still waiting
                 * */
                if (
                    JSON.stringify(lastRemoteDataHandlerParams.current) ===
                    JSON.stringify(remoteDataHandlerParams)
                ) {
                    setFetchOrderStatus({
                        isLoading: false,
                        data: orders || [],
                        page: pageIndex,
                        totalCount:
                            ordersData?.orders_aggregate?.aggregate?.count,
                    });
                }
            } catch (e) {
                console.error("catch error ==> ", e);
            }
        },
        [refetch, history, location.pathname, refetchStores],
    );

    return (
        <ContentWrapper title={t("common.clientOrder_plural")}>
            {columns && initialFilters ? (
                <InsideTable
                    renderTableHeader={HeaderSimple}
                    renderTableBody={BodyTableSimple}
                    renderTableFooter={FooterPaginationSimple}
                    columns={columns}
                    data={fetchOrderStatus.data}
                    loading={fetchOrderStatus.isLoading}
                    remoteDataHandler={remoteDataHandler}
                    pageCount={fetchOrderStatus.totalCount}
                    initialFilters={initialFilters}
                    initialSortBy={INITIAL_SORT_BY}
                    detailsUrl="/orders"
                    searchPlaceholder={t("pages.orders.searchPlaceholder")}
                />
            ) : (
                <InsideLoader />
            )}
        </ContentWrapper>
    );
};

export default OrdersPage;
