import React from "react";
import styled from "@mui/styles/styled";
import { Container, Typography, Icon, Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
    backIcon: {
        cursor: "pointer",
    },
    childrenWrapper: {
        maxWidth: 1280,
    },
    titleIndex: {
        zIndex: 1,
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
    },
    wrapper: {
        height: "100%",
        margin: 0,
        width: "100%",
    },
};

const StyledGlobalWrapper = styled("div")(({ theme }) => ({
    width: "100%",
    minHeight: "calc(100vh - 64px)",
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(3),
}));

const StyledPageTitleContainer = styled("div")(({ theme }) => ({
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
}));

const StyledPageSubtitleContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "start",
    alignItems: "top",
    width: "100%",
}));

const StyledPageTitleLine = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
}));

const Spacer = ({ horizontal, vertical, children }) => {
    const Component = styled("div")(({ theme }) => ({
        visibility: "hidden",
        ...(horizontal && !vertical && { height: 0 }),
        ...(vertical && !horizontal && { width: 0 }),
    }));
    return <Component>{children}</Component>;
};

const StyledClickableIconsContainer = styled("div")(({ theme }) => ({
    right: theme.spacing(1),
    color: theme.palette.primary.main,
    position: "absolute",
    marginRight: theme.spacing(6),
}));

const ContentWithHeader = ({
    title,
    children,
    icon,
    RenderRightElement,
    goBackTo,
    TitleComponent,
    subtitle,
}) => {
    const BackArrow = (active) => (
        <Box display="flex" alignItems="center" sx={styles.titleIndex}>
            {typeof goBackTo === "function" && (
                <Icon
                    onClick={active && goBackTo}
                    sx={styles.backIcon}
                    fontSize="large"
                >
                    arrow_back
                </Icon>
            )}
            {icon && <Icon fontSize="large">{icon}</Icon>}
        </Box>
    );
    return (
        <StyledGlobalWrapper>
            <Container maxWidth={false} sx={styles.wrapper}>
                <StyledPageTitleContainer>
                    <StyledPageTitleLine>
                        <BackArrow active={true} />
                        <Typography variant="h4" sx={styles.titleIndex}>
                            {title}
                            {TitleComponent && <TitleComponent />}
                        </Typography>
                        {RenderRightElement && (
                            <StyledClickableIconsContainer>
                                <RenderRightElement />
                            </StyledClickableIconsContainer>
                        )}
                    </StyledPageTitleLine>
                    {subtitle && (
                        <StyledPageSubtitleContainer>
                            <Spacer horizontal>
                                <BackArrow active={false} />
                            </Spacer>
                            {subtitle}
                        </StyledPageSubtitleContainer>
                    )}
                </StyledPageTitleContainer>

                <Container maxWidth={false} sx={styles.childrenWrapper}>
                    {children}
                </Container>
            </Container>
        </StyledGlobalWrapper>
    );
};

ContentWithHeader.propTypes = {
    title: PropTypes.string,
    TitleComponent: PropTypes.elementType,
    icon: PropTypes.string,
    RenderRightElement: PropTypes.elementType,
    goBackTo: PropTypes.func,
};

ContentWithHeader.defaultProps = {
    goBackTo: null,
};

export default ContentWithHeader;
