import { gql } from "@apollo/client";

const satisfactionQuery = gql`
    query Satisfaction(
        $where: satisfactions_bool_exp
        $limit: Int
        $offset: Int
        $order_by: [satisfactions_order_by!] = {}
    ) {
        satisfactions(
            limit: $limit
            offset: $offset
            order_by: $order_by
            where: $where
        ) {
            order {
                created_at
                id
                customer_account {
                    id
                    email
                    firstname
                    lastname
                    phone_number
                }
                vendor_info {
                    lastname
                    firstname
                }
                store {
                    name
                }
                destination_store {
                    name
                }
            }
            id
            category
            comment
            contacted
            contacted_by
            updated_at
            created_at
            type
            n1
            n2
            #            satisfaction_reports(limit: 1, order_by: { created_at: desc }) {
            #                id
            #                contacted_by
            #                contact_method
            #                comment
            #                client_mood
            #                solution
            #                status
            #                created_at
            #            }
        }
        satisfactions_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export { satisfactionQuery };
