import React, { useState, useEffect } from "react";
import styled from "@mui/styles/styled";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { isValid, parseISO, startOfDay, endOfDay } from "date-fns";
import CustomDatePicker from "components/common/CustomDatePicker";

const StyledContainer = styled("div")(({ theme, style }) => ({
    height: 45,
    minWidth: 89,
    display: "flex",
    border: "solid",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    borderColor: theme.palette.grey.granite,
    borderWidth: theme.border.width.thinner,
    borderRadius: theme.border.radius.button,
    ...style,
}));

const DatePickers = ({ filterValue = [], onChange, style }) => {
    const { t } = useTranslation();

    /*
     *  TODO: check in next version better way for doing this because
     *   isValid() polluting console with warnings
     * */
    const filterValueFormatted = filterValue.map((date) =>
        !date || isValid(date) ? date : parseISO(date),
    );

    const [currentFilterValue, setCurrentFilterValue] =
        useState(filterValueFormatted);

    useEffect(() => {
        if (
            JSON.stringify(currentFilterValue) !== JSON.stringify(filterValue)
        ) {
            setCurrentFilterValue(filterValue);
        }
    }, [currentFilterValue, filterValue]);

    const onStartDateChange = (date) => {
        const newValue = [
            date ? startOfDay(date) : undefined,
            currentFilterValue?.[1] || null,
        ];
        if (onChange) {
            onChange(newValue);
        }
        setCurrentFilterValue(newValue);
    };

    const onEndDateChange = (date) => {
        const newValue = [
            currentFilterValue?.[0] || null,
            date ? endOfDay(date) : undefined,
        ];
        if (onChange) {
            onChange(newValue);
        }
        setCurrentFilterValue(newValue);
    };

    return (
        <StyledContainer style={style}>
            <DateRangeIcon />
            <CustomDatePicker
                placeHolderCentered
                selected={currentFilterValue[0]}
                onChange={onStartDateChange}
                selectsStart
                placeholderText={t("common.start")}
                startDate={filterValueFormatted[0]}
                endDate={filterValueFormatted[1]}
            />
            <TrendingFlatIcon />
            <CustomDatePicker
                placeHolderCentered
                selected={currentFilterValue[1]}
                onChange={onEndDateChange}
                selectsEnd
                placeholderText={t("common.end")}
                startDate={filterValueFormatted[0]}
                endDate={filterValueFormatted[1]}
                minDate={filterValueFormatted[0]}
            />
        </StyledContainer>
    );
};

DatePickers.defaulProps = {
    filterValue: [],
};

export default DatePickers;
