import { replaceAllWhiteSpaceByOneSpace } from "services/utils/string";

/**
 * transform react-table filter's array by an object of filters
 * @param filters {Array<Object<id: String, value: any>>} - array of filters
 * returned by react-table
 * @returns {Object<[id]: value>}
 */
const formatFiltersToObj = (filters) =>
    filters.reduce(
        (obj, filter) => ({
            ...obj,
            [filter.id]: filter.value,
        }),
        {},
    );

/**
 * Return adapted string for query
 * if we want to search with one word or multiple word
 * @param globalFilter {string}
 * @returns {string}
 */
const globalFilterFormatter = (globalFilter) => {
    const globalFilterTrim = globalFilter.trim();

    if (globalFilterTrim.includes(" ")) {
        return nameFilterFormatter(globalFilter);
    }
    return `%${globalFilterTrim}%`;
};

/**
 * Parse string to specific format for search with multiples words
 * using _similar search method of graphql
 * _similar have specific syntax and it is case sensitive
 * example: "kylian mbappé" -> %(kylian|mbappé|Kylian|Mbappé)%
 * TODO: change to _regex graphql method when the provider can support it
 */
const nameFilterFormatter = (globalFilter) => {
    const globalFilterTrim = globalFilter.trim();
    const filter = replaceAllWhiteSpaceByOneSpace(globalFilterTrim).replace(
        " ",
        "|",
    );
    const filterWithFirstLettersUppercase = globalFilterTrim
        ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
        ?.split(" ")
        ?.join("|");
    const filterFirstWordToUppercase = globalFilter
        ?.split(" ")?.[0]
        ?.toUpperCase();
    const filterSecondWordToUppercase = globalFilter
        ?.split(" ")?.[1]
        ?.toUpperCase();
    const firstWordUppercaseFormatted = filterFirstWordToUppercase
        ? `|${filterFirstWordToUppercase}`
        : "";
    const secondWordUppercaseFormatted = filterSecondWordToUppercase
        ? `|${filterSecondWordToUppercase}`
        : "";
    return `%(${filter}|${filterWithFirstLettersUppercase}${firstWordUppercaseFormatted}${secondWordUppercaseFormatted})%`;
};

export { globalFilterFormatter, formatFiltersToObj, nameFilterFormatter };
