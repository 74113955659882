import React from "react";
import { Grid } from "@mui/material";
import styled from "@mui/styles/styled";
import Text from "components/Text";

const StyledContainer = styled(Grid)(({ theme }) => ({
    minHeight: "90vh",
    padding: theme.spacing(8),
    textAlign: "center",
}));

const Error401 = () => (
    <StyledContainer
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={10}
    >
        <Grid xs={12} item>
            <Text variant="h3">Error 401</Text>
        </Grid>
        <Grid xs={12} item>
            <Text variant="h5">
                Il semblerait que votre rôle actuel ne vous permette pas
                d'accéder à cette ressource, si vous souhaitez en savoir plus,
                contactez{" "}
                <a href="mailto:dev@jimmyfairly.com">dev@jimmyfairly.com</a>
            </Text>
        </Grid>
    </StyledContainer>
);

export default Error401;
