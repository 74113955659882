import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    ApolloProvider,
} from "@apollo/client";

import { getAuth, onIdTokenChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import firebaseApp from "services/firebase";

export const AuthContext = React.createContext({});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    headers: {
        Authorization: `Bearer not_auth`,
    },
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [token, setToken] = useState(null);
    const prevToken = useRef();
    const [storeId, setStoreId] = useState(-1);
    const [storeName, setStoreName] = useState(null);
    const [apolloInstance, setApolloInstance] = useState(client);
    const [isLoadingAuthState, setIsLoadingAuthState] = useState(true);
    const [welcomeAnimation, setWelcomeAnimation] = useState(false);
    const [stores, setStores] = useState([]);

    const logout = useCallback(async () => {
        const auth = getAuth();
        await signOut(auth);
        setUser(null);
        setUserRoles([]);
        setStoreId(-1);
        setStoreName(null);
        setToken(null);
        prevToken.current = null;
        apolloInstance.stop();
        apolloInstance.clearStore();
        setIsLoadingAuthState(true);
    }, [apolloInstance]);

    const [contextValue, setContextValue] = useState({
        userRoles,
        user,
        authenticated: userRoles.length > 0 && token,
        setUser,
        token,
        logout,
        isLoadingAuthState,
        welcomeAnimation,
        setWelcomeAnimation,
    });

    /**
     * each times an event occurs, this method is verified:
     * if the authState of the current user (employee) receive a change (e.g : login / logout)
     * the code inside firebase.auth().onAuthStateChanged is executed.
     * What the code does :
     * - Check if a google/firebase user is registered
     * - Verify that the user is present in the firestore collection "employee_accounts"
     * - Set the AuthContext userRoles and user in case he is recognized
     * - Alert the user in case the user isn't recognized
     */
    useEffect(() => {
        const auth = getAuth(firebaseApp);
        onIdTokenChanged(auth, async (firestoreUser) => {
            if (firestoreUser) {
                const db = getFirestore();
                const docRef = doc(
                    db,
                    "employee_accounts",
                    firestoreUser.email,
                );
                const docSnap = await getDoc(docRef);

                if (!docSnap.exists()) {
                    alert(
                        `Votre adresse email (${firestoreUser.email}) n'est pas reconnue par jimmy fairly contactez dev@jimmyfairly.com`,
                    );
                    setUser(null);
                } else {
                    const userRolesTemp = docSnap.data().role;
                    if (
                        firestoreUser?.email?.toLowerCase().includes("-retail")
                    ) {
                        userRolesTemp.push("Stores Paris");
                    }
                    setUserRoles(userRolesTemp);
                    setUser(firestoreUser);
                    const idTokenResult =
                        await firestoreUser.getIdTokenResult();
                    const hasuraClaim =
                        idTokenResult.claims["https://hasura.io/jwt/claims"];
                    if (hasuraClaim) {
                        const idToken = await firestoreUser.getIdToken();
                        setToken(idToken);
                        setUser(firestoreUser);
                    } else {
                        // Force refresh to pick up the latest custom claims changes.
                        setTimeout(async () => {
                            const idToken = await firestoreUser.getIdToken(
                                true,
                            );
                            setToken(idToken);
                            setUser(firestoreUser);
                        }, 5000);
                    }
                }
            } else {
                setIsLoadingAuthState(false);
            }
        });
    }, []);

    // Effect to manage auth token change and diffuse it to appolo client and provider
    useEffect(() => {
        if (token && prevToken.current !== token) {
            prevToken.current = token;

            const tempHttpLink = new HttpLink({
                uri: process.env.REACT_APP_API_URL,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const tempClient = new ApolloClient({
                cache: new InMemoryCache(),
                link: tempHttpLink,
            });
            setApolloInstance(tempClient);
        }
    }, [token]);

    useEffect(() => {
        setContextValue({
            userRoles: [...userRoles],
            user,
            storeId,
            storeName,
            authenticated: userRoles.length > 0 && token,
            setUser,
            token,
            logout,
            isLoadingAuthState,
            welcomeAnimation,
            setWelcomeAnimation,
            setStoreId,
            setStoreName,
            setIsLoadingAuthState,
            stores,
            setStores,
        });
    }, [
        isLoadingAuthState,
        logout,
        storeId,
        storeName,
        stores,
        token,
        user,
        userRoles,
        welcomeAnimation,
    ]);

    return (
        <AuthContext.Provider value={contextValue}>
            <ApolloProvider client={apolloInstance}>{children}</ApolloProvider>
        </AuthContext.Provider>
    );
};
