import React from "react";
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Grow,
    CardHeader,
} from "@mui/material";
import Glasses from "assets/Glasses.svg";
import { useTranslation } from "react-i18next";

const ProductImageCard = ({ label, url, id }) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={3}>
            <Grow in={true} timeout={1000}>
                <Card className={{ maxWidth: 345 }}>
                    <CardHeader title={label} sx={{ textAlign: "center" }} />
                    <CardMedia
                        sx={{
                            height: 140,
                            backgroundSize: "contain",
                        }}
                        image={url ? url : Glasses}
                        title="frame image"
                    />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography align="right" variant="overline">
                                    {`${t(
                                        "common.productName",
                                    ).toUpperCase()} :`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="overline" align="center">
                                    {label}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography align="right" variant="overline">
                                    {`${t("common.ref").toUpperCase()} :`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="overline" align="center">
                                    {id}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grow>
        </Grid>
    );
};

export default ProductImageCard;
