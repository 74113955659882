import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Fade } from "@mui/material";
import Chip from "@mui/material/Chip";
import {
    Link as LinkIcon,
    HealthAndSafety as HealthAndSafetyIcon,
    ShoppingCart as ShoppingCartIcon,
    LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import InsideTable from "components/Table";
import BodyTableSimple from "components/Table/BodyComponents/BodyTableSimple";
import { DateCell, StatusCellComponent } from "components/Table/CellComponents";
import { getStoreNameWithId } from "services/toolbox";
import theme from "theme";
import { AuthContext } from "AuthProvider";

const styles = {
    subtitle: {
        borderBottom: "1px solid #000",
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        fontWeight: "bold",
    },
};

const OrderHistory = ({ orderData }) => {
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    const { stores } = useContext(AuthContext);

    const columns = [
        {
            accessor: "id",
            disableFilters: true,
            Cell: ({ row }) => (
                <div style={{ display: "grid" }}>
                    <Typography variant="body2">{row.original.id}</Typography>
                    {row.original.cares?.[0]?.orderOrigin?.id && (
                        <Typography
                            onClick={() =>
                                window.open(
                                    `${window.location.origin}/orders/${row.original.cares[0].orderOrigin.id}`,
                                )
                            }
                            variant="subtitle2"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: theme.palette.blue.electricUltramarine,
                                textDecoration: "underline",
                            }}
                        >
                            <LinkIcon
                                sx={{ marginRight: theme.spacing(0.3) }}
                            />
                            {row.original.cares[0].orderOrigin.id}
                        </Typography>
                    )}
                </div>
            ),
        },
        {
            accessor: "created_at",
            Cell: ({ value }) => <DateCell value={value} locale={locale} />,
        },
        {
            accessor: "order_product_lines[0].left_final_reference",
            Cell: ({ row }) => (
                <div style={{ display: "grid" }}>
                    {row.original.order_product_lines?.[0]?.frame?.label && (
                        <Typography variant="body2">
                            {row.original.order_product_lines[0].frame.label}
                        </Typography>
                    )}
                    {row.original.order_product_lines?.[0]
                        ?.left_final_reference && (
                        <Typography variant="body2">
                            {
                                row.original.order_product_lines[0]
                                    .left_final_reference
                            }
                        </Typography>
                    )}
                </div>
            ),
        },
        {
            accessor: "type",
            Cell: ({ row }) => (
                <div style={{ display: "grid" }}>
                    {row.original.type && (
                        <Chip
                            label={row.original.type}
                            size="small"
                            color={"default"}
                            style={{
                                marginTop: 3,
                                marginLeft: 30,
                                marginRight: 30,
                            }}
                        />
                    )}
                    {row.original.is_care_service && (
                        <Chip
                            label={t("common.care")}
                            size="small"
                            style={{
                                border: `2px solid #2602FF`,
                                backgroundColor: `#2602FF20`,
                                color: "#2602FF",
                                fontWeight: "bold",
                                marginTop: 3,
                                marginLeft: 30,
                                marginRight: 30,
                            }}
                            icon={
                                <HealthAndSafetyIcon
                                    style={{ color: "#2602FF" }}
                                />
                            }
                        />
                    )}
                    {row.original.cares?.[0]?.reason && (
                        <Chip
                            label={`${t("common.careReason")} : ${
                                row.original.cares?.[0]?.reason
                            }`}
                            size="small"
                            color={"default"}
                            style={{
                                marginTop: 3,
                                marginLeft: 30,
                                marginRight: 30,
                            }}
                        />
                    )}
                </div>
            ),
        },
        {
            accessor: "store.id",
            Cell: ({ row }) => (
                <>
                    <Chip
                        color={"default"}
                        label={getStoreNameWithId(
                            stores,
                            row?.original?.store?.id,
                        ).toUpperCase()}
                        size="small"
                        style={{ width: "90%" }}
                        icon={<ShoppingCartIcon />}
                    />
                    {row.original?.destination_store?.id && (
                        <Chip
                            color={"default"}
                            label={getStoreNameWithId(
                                stores,
                                row.original.destination_store.id,
                            ).toUpperCase()}
                            size="small"
                            style={{ width: "90%", marginTop: 3 }}
                            icon={<LocalShippingIcon />}
                        />
                    )}
                </>
            ),
        },
        {
            accessor: "flat_amount_including_tax",
            Cell: ({ row, value }) =>
                row.original?.currency &&
                new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: row.original?.currency,
                }).format(value),
            disableFilters: true,
            disableSortBy: true,
        },
        {
            accessor: "global_status_id",
            Cell: ({ row }) =>
                row.original?.global_status_id ? (
                    <StatusCellComponent
                        stores={stores}
                        label={t(
                            `orderStatus:${row.original?.global_status_id}.label`,
                        )}
                        orderType={row.original?.type}
                        orderStatus={row.original?.global_status_id}
                    />
                ) : (
                    <></>
                ),
        },
    ];

    return (
        <>
            <Typography sx={styles.subtitle}>
                {t("common.orderHistory_plural")}
            </Typography>
            <Fade in={true} timeout={1000}>
                <div>
                    <InsideTable
                        searchBar={false}
                        onRowClick={(rowData) =>
                            window.open(
                                `${window.location.origin}/orders/${rowData.id}`,
                            )
                        }
                        renderTableBody={BodyTableSimple}
                        columns={columns}
                        data={orderData}
                        detailsUrl="/orders"
                    />
                </div>
            </Fade>
        </>
    );
};

export default OrderHistory;
