import React, { useContext } from "react";
import { DialogActions, Button, CircularProgress } from "@mui/material";
import styled from "@mui/styles/styled";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import OrderList from "./OrdersList";
import theme from "theme";
import { AuthContext } from "AuthProvider";
import Text from "components/Text";

const styles = {
    alertButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginRight: theme.spacing(3),
        color: theme.palette.white.absolute,
        borderRadius: theme.border.radius.button,
        backgroundColor: theme.palette.blue.maastricht,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.grey.granite,
        },
    },
    buttonLoader: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        color: theme.palette.grey.gainsboro,
    },
    cancelIcon: {
        position: "absolute",
        top: -14,
        right: -15,
        width: 50,
        height: 50,
        cursor: "pointer",
        color: theme.palette.grey.philippine,
    },
    confirmButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginLeft: theme.spacing(3),
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.blue.maastricht,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.grey.granite,
        },
    },
    description: {
        fontWeight: "bold",
        fontSize: 22,
        color: theme.palette.orange.crayola,
    },
    descriptionSecondPart: {
        fontWeight: "bold",
        fontSize: 22,
        color: theme.palette.orange.crayola,
        marginBottom: theme.spacing(2),
    },
    infoText: {
        fontSize: theme.fontSize.M,
        color: theme.palette.orange.crayola,
    },
    warningIcon: {
        width: 60,
        height: 60,
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1.5),
        color: theme.palette.orange.crayola,
    },
};

const StyledInfoContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(2),
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginRight: theme.spacing(1.5),
    color: theme.palette.orange.crayola,
}));

const StyledActionsContainer = styled(DialogActions)(() => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
}));

const TRANS_PATH = "pages.receipt.stockReceiptPopUp.badDestinationPopUp";

const AUTHORIZED_ROLES_TO_FORCE_RECEPTION = [
    "Inside Admin",
    "Inside Tech",
    "Inside XP",
    "Inside DR",
];

const FirstStep = ({
    close,
    orders,
    nextStep,
    confirmReception,
    isLoading,
}) => {
    const { t } = useTranslation();
    const { userRoles } = useContext(AuthContext);

    const authorizedToForceReception = AUTHORIZED_ROLES_TO_FORCE_RECEPTION.some(
        (authorizedRole) =>
            userRoles.includes(authorizedRole) || authorizedRole.includes("*"),
    );

    return (
        <>
            <WarningIcon sx={styles.warningIcon} />
            <Text style={styles.description}>
                {t(`${TRANS_PATH}.firstStepTitle1`)}
            </Text>
            <Text style={styles.descriptionSecondPart}>
                {t(`${TRANS_PATH}.firstStepTitle2`)}
            </Text>
            <OrderList orders={orders} />
            <StyledInfoContainer>
                <StyledInfoIcon />
                <Text style={styles.infoText}>
                    {t(`${TRANS_PATH}.firstStepInfo`)}
                </Text>
            </StyledInfoContainer>
            <StyledActionsContainer>
                <CancelIcon onClick={close} sx={styles.cancelIcon} />
                <Button onClick={nextStep} sx={styles.alertButton}>
                    {t(`${TRANS_PATH}.firstStepCtaLeft`)}
                </Button>
                {authorizedToForceReception && (
                    <Button
                        onClick={() => {
                            confirmReception();
                            close();
                        }}
                        sx={styles.confirmButton}
                    >
                        {!isLoading ? (
                            t(`${TRANS_PATH}.firstStepCtaRight`)
                        ) : (
                            <CircularProgress
                                sx={styles.buttonLoader}
                                size={25}
                            />
                        )}
                    </Button>
                )}
            </StyledActionsContainer>
        </>
    );
};

export default FirstStep;
