import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableCell, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SortIcon from "@mui/icons-material/Sort";
import { styled } from "@mui/material/styles";
import theme from "theme";

const styles = {
    headerRounded: {
        borderRadius: theme.border.radius.list,
    },
    headerCellTextIconContainer: {
        fontWeight: 600,
        display: "inline-flex",
    },
    headerTextCell: {
        marginRight: 3,
        fontWeight: "bolder",
    },
};

const StyledCellIconContainer = styled("div")(() => ({
    fontWeight: 600,
    display: "inline-flex",
}));

const StyledTableCell = styled(TableCell)(
    ({ theme, indexOfCurrentCell, lengthOfAllCellsArray, style = {} }) => {
        const getHeaderCellStyle = () => {
            if (indexOfCurrentCell === 0) {
                return {
                    padding: "20px 6px 20px 5px",
                    textAlign: "center",
                    verticalAlign: "top",
                    width: "11%",
                    maxWidth: "12%",
                    borderLeft: "solid",
                    borderTopLeftRadius: theme.border.radius.list,
                    borderBottomLeftRadius: theme.border.radius.list,
                    borderLeftColor: theme.palette.secondary.dark,
                    backgroundColor: theme.palette.white.lotion,
                };
            }
            if (indexOfCurrentCell === lengthOfAllCellsArray - 1) {
                return {
                    padding: "20px 6px 20px 5px",
                    textAlign: "center",
                    verticalAlign: "top",
                    width: "11%",
                    maxWidth: "12%",
                    borderRight: "solid",
                    borderTopRightRadius: theme.border.radius.list,
                    borderBottomRightRadius: theme.border.radius.list,
                    borderRightColor: theme.palette.secondary.dark,
                    backgroundColor: theme.palette.white.lotion,
                };
            }
            return {
                padding: "20px 6px 20px 5px",
                textAlign: "center",
                verticalAlign: "top",
                width: "11%",
                maxWidth: "12%",
                backgroundColor: theme.palette.white.lotion,
            };
        };
        return { ...getHeaderCellStyle(), ...style };
    },
);

const HeaderRounded = ({ headerGroups, headerStyle, headerCellsStyle }) => {
    const _renderSortIcon = (column) => {
        if (column.isSorted) {
            if (column.isSortedDesc) {
                return <ArrowDownwardIcon />;
            }
            return <ArrowUpwardIcon />;
        }
        return <SortIcon />;
    };

    return (
        <TableHead sx={{ ...styles.headerRounded, ...headerStyle }}>
            {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index, allCells) => (
                        <StyledTableCell
                            indexOfCurrentCell={index}
                            lengthOfAllCellsArray={allCells.length}
                            style={{
                                ...(typeof column?.headerProps?.style ===
                                "object"
                                    ? column?.headerProps?.style
                                    : {}),
                                ...(headerCellsStyle || {}),
                            }}
                            key={column.Header}
                        >
                            <StyledCellIconContainer
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                )}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={styles.headerTextCell}
                                >
                                    {column.render("Header")}
                                </Typography>
                                {column.canSort && _renderSortIcon(column)}
                            </StyledCellIconContainer>
                            <div>
                                {column.canFilter
                                    ? column.render("Filter")
                                    : null}
                            </div>
                        </StyledTableCell>
                    ))}
                </TableRow>
            ))}
        </TableHead>
    );
};

export default HeaderRounded;
