const roles = {
    INSIDE_TECH: "Inside Tech",
    INSIDE_XP: "Inside XP",
    INSIDE_DR: "Inside DR",
    STORES_PARIS: "Stores Paris",
    STORES_PROVINCE: "Stores Province",
    STORES_UK: "Stores UK",
    HEAD_QUARTERS: "Head quarters",
};

export default roles;
