import React from "react";
import styled from "@mui/styles/styled";
import { Button, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    Info as InfoIcon,
    Cancel as CancelIcon,
    CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { StyledVitalCardLogo } from "pages/Receipt/OrderReceipt/styledComponents";
import Text from "components/Text";
import theme from "theme";

const styles = {
    checkIcon: {
        width: 60,
        height: 60,
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1.5),
        color: theme.palette.green.texas,
    },
    closeIcon: {
        position: "absolute",
        top: -14,
        right: -15,
        width: 50,
        height: 50,
        cursor: "pointer",
        color: theme.palette.grey.philippine,
    },
    confirmButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginLeft: theme.spacing(3),
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.blue.maastricht,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.grey.granite,
        },
    },
    description: {
        fontSize: theme.fontSize.M,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    errorButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginLeft: theme.spacing(3),
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.red.apple,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.red.apple,
        },
    },
    infoIcon: {
        marginRight: theme.spacing(1.5),
        color: theme.palette.grey.granite,
    },
    infoText: {
        fontSize: theme.fontSize.M,
        color: theme.palette.grey.granite,
    },
    nextStepsTitle: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginTop: theme.spacing(2),
        color: theme.palette.grey.granite,
    },
    orderInfo: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
    },
    title: {
        fontWeight: "bold",
        fontSize: 22,
        color: theme.palette.green.texas,
    },
};

const TRANS_PATH = "pages.receipt.stockReceiptPopUp.badDestinationPopUp";

const StyleOrderContainer = styled("div")(() => ({
    maxHeight: "25vh",
    overflowY: "auto",
    padding: theme.spacing(1),
}));

const StyleInfoContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(() => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
}));

const AlertSentAndConfirmed = ({ orders, close }) => {
    const { t } = useTranslation();
    return (
        <>
            <CheckCircleIcon sx={styles.checkIcon} />
            <Text style={styles.title}>
                {t(`${TRANS_PATH}.alertSentTitle`)}
            </Text>
            <Text style={styles.description}>
                {t(`${TRANS_PATH}.alertSentDescription`)}
            </Text>
            <StyleOrderContainer>
                {orders.map((order) => (
                    <Text style={styles.orderInfo}>
                        {order.value}
                        <StyledVitalCardLogo
                            transmissionStatus={
                                order.optimum?.transmission_status &&
                                order.optimum?.transmission_status === "valid"
                                    ? order.optimum?.transmission_status
                                    : null
                            }
                        />
                    </Text>
                ))}
            </StyleOrderContainer>

            <StyleInfoContainer>
                <InfoIcon sx={styles.infoIcon} />
                <Text style={styles.infoText}>
                    {t(`${TRANS_PATH}.alertSentDisclaimer`)}
                </Text>
            </StyleInfoContainer>
            <StyledDialogActions>
                <CancelIcon onClick={close} sx={styles.closeIcon} />
                <Button onClick={close} sx={styles.confirmButton}>
                    {t("common.close")}
                </Button>
            </StyledDialogActions>
        </>
    );
};

export default AlertSentAndConfirmed;
