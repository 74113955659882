import React from "react";
import {
    Checkbox,
    FormControl,
    Input,
    ListItemText,
    MenuItem,
    Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFromControl = styled(FormControl)(({ theme, style }) => ({
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    ...style,
}));

export const formatObjToSelectListArray = (obj) =>
    Object.keys(obj).map((key) => ({
        id: key,
        name: obj[key],
    }));

const SelectListFilter = ({
    column: { filterValue = [], setFilter },
    list,
    style = {},
}) => {
    /*
     *  Get references of every object matching with filter values,
     *  at every render because <Select/> component from MUI
     *  work with objects references
     *  */
    const valueRef = list.filter((elem) =>
        filterValue.find((value) => elem.id === value.id),
    );

    const handleOnChange = (event) => {
        setFilter(event.target.value);
    };

    const MenuItems = () =>
        list.map((elem) => (
            <MenuItem key={elem.id} value={elem}>
                <Checkbox
                    checked={!!valueRef.find((value) => value.id === elem.id)}
                />
                <ListItemText primary={elem.name} />
            </MenuItem>
        ));

    return (
        <StyledFromControl style={style}>
            <Select
                multiple
                value={valueRef}
                onChange={handleOnChange}
                input={<Input />}
                renderValue={(selected) =>
                    selected.map((elem) => elem?.name).join(", ")
                }
            >
                {MenuItems()}
            </Select>
        </StyledFromControl>
    );
};

export default SelectListFilter;
