import React, { useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import styled from "@mui/styles/styled";
import { Button, DialogActions, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import BulletPointList from "components/common/BulletPointList";
import CancelIcon from "@mui/icons-material/Cancel";
import Text from "components/Text";
import theme from "theme";
import { StyledVitalCardLogo } from "../styledComponents";

const styles = {
    bulletPoint: {
        marginTop: theme.spacing(2),
    },
    buttonLoader: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        color: theme.palette.grey.gainsboro,
    },
    closeIcon: {
        position: "absolute",
        top: -14,
        right: -15,
        width: 50,
        height: 50,
        cursor: "pointer",
        color: theme.palette.grey.philippine,
    },
    confirmButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginLeft: theme.spacing(3),
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.blue.maastricht,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.grey.granite,
        },
    },
    description: {
        fontSize: theme.fontSize.M,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    dialogActionsContainer: {
        marginTop: theme.spacing(2),
    },
    errorButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginLeft: theme.spacing(3),
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.red.apple,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.red.apple,
        },
    },
    errorMessage: {
        color: theme.palette.red.apple,
        marginBottom: theme.spacing(6),
    },
    nextStepsTitle: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginTop: theme.spacing(2),
        color: theme.palette.grey.granite,
    },
    orderInfo: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
    },
    prevButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginRight: theme.spacing(1),
        color: theme.palette.grey.granite,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    title: {
        fontWeight: "bold",
        fontSize: 22,
        color: theme.palette.orange.crayola,
    },
    warningIcon: {
        width: 60,
        height: 60,
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1.5),
        color: theme.palette.orange.crayola,
    },
    ordersContainer: {
        maxHeight: "25vh",
        overflowY: "auto",
        padding: theme.spacing(1),
    },
};

const StyledActions = styled(DialogActions)(() => ({
    marginTop: theme.spacing(2),
}));

const StyledOrderContainer = styled("div")(() => ({
    maxHeight: "25vh",
    overflowY: "auto",
    padding: theme.spacing(1),
}));

const TRANS_PATH = "pages.receipt.stockReceiptPopUp.badDestinationPopUp";

const SendAlert = ({
    orders,
    prevStep,
    isLoading,
    nextStep,
    close,
    handleConfirmDispatchError,
}) => {
    const { t } = useTranslation();
    const [errorFromApi, setErrorFromApi] = useState(false);

    const stepsList = [
        t(`${TRANS_PATH}.sendAlertBulletPoint1`),
        t(`${TRANS_PATH}.sendAlertBulletPoint2`),
    ];

    const onClickPrevButton = () => {
        prevStep();
        setErrorFromApi(false);
    };

    const onClickConfirmSendAlert = () =>
        handleConfirmDispatchError()
            .then(() => {
                nextStep();
                setErrorFromApi(false);
            })
            .catch(() => setErrorFromApi(true));

    const confirmButtonContent = () => {
        if (isLoading) {
            return <CircularProgress sx={styles.buttonLoader} size={25} />;
        }
        if (errorFromApi) {
            return t("common.retry");
        }
        return t(`${TRANS_PATH}.sendAlertCtaRight`);
    };

    return (
        <>
            <WarningIcon sx={styles.warningIcon} />
            <Text style={styles.title}>
                {t(`${TRANS_PATH}.sendAlertTitle`)}
            </Text>
            <Text style={styles.description}>
                {t(`${TRANS_PATH}.sendAlertDescription`)}
            </Text>
            <StyledOrderContainer>
                {orders.map((order) => (
                    <Text className={styles.orderInfo}>
                        {order.value}
                        <StyledVitalCardLogo
                            transmissionStatus={
                                order.optimum?.transmission_status &&
                                order.optimum?.transmission_status === "valid"
                                    ? order.optimum?.transmission_status
                                    : null
                            }
                        />
                    </Text>
                ))}
            </StyledOrderContainer>
            <Text style={styles.nextStepsTitle}>
                {t(`${TRANS_PATH}.sendAlertNextStepsTitle`)}
            </Text>
            <BulletPointList list={stepsList} style={styles.bulletPoint} />
            <StyledActions>
                {!isLoading && (
                    <CancelIcon onClick={close} sx={styles.closeIcon} />
                )}
                <Button
                    onClick={onClickPrevButton}
                    disabled={isLoading}
                    sx={styles.prevButton}
                >
                    {t(`${TRANS_PATH}.sendAlertCtaLeft`)}
                </Button>
                <Button
                    onClick={onClickConfirmSendAlert}
                    disabled={isLoading}
                    sx={
                        errorFromApi ? styles.errorButton : styles.confirmButton
                    }
                >
                    {confirmButtonContent()}
                </Button>
            </StyledActions>
            <Text style={styles.errorMessage}>
                {errorFromApi ? t(`${TRANS_PATH}.sendAlertErrorMessage`) : ""}
            </Text>
        </>
    );
};

export default SendAlert;
