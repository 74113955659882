import React from "react";
import {
    Checkbox,
    FormGroup,
    Input,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPlaceHolder = styled(Typography)(() => ({
    color: "#aaa",
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
}));

const DoubleSelectListFilter = ({
    column: { filterValue = {}, setFilter },
    list,
    secondList,
    listFilterId,
    secondListFilterId,
    listPlaceHolder,
    secondListPlaceHolder,
    disableList,
    disableSecondList,
    style = {},
}) => {
    /*
     *  Get references of every object matching with filter values,
     *  at every render because <Select/> component from MUI
     *  work with objects references
     *  */
    const valueRef = (list, listId) =>
        list.filter(
            (elem) =>
                filterValue[listId] &&
                filterValue[listId].find((value) => elem.id === value.id),
        );

    const handleOnChange = (firstFilterValue, secondFilterValue) => {
        if (firstFilterValue) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                [listFilterId]: firstFilterValue,
            }));
        }
        setFilter((prevFilter) => ({
            ...prevFilter,
            [secondListFilterId]: secondFilterValue,
        }));
    };

    const MenuItems = (list, listID) =>
        list.map((elem) => (
            <MenuItem key={elem.id} value={elem}>
                <Checkbox
                    checked={
                        !!valueRef(list, listID).find(
                            (value) => value.id === elem.id,
                        )
                    }
                />
                <ListItemText primary={elem.name} />
            </MenuItem>
        ));

    const renderValue = (selected, placeHolder) => {
        if (!selected.length) {
            return <StyledPlaceHolder>{placeHolder}</StyledPlaceHolder>;
        }
        const selectedValuesElement = selected
            ?.slice(0, 2)
            ?.map((elem) => elem?.name)
            .join(", ");

        if (selected.length > 2) {
            return `${selectedValuesElement}...`;
        }

        return selectedValuesElement;
    };

    return (
        <StyledFormGroup style={style}>
            <Select
                multiple
                displayEmpty
                disabled={disableList}
                value={valueRef(list, listFilterId)}
                onChange={(event) => handleOnChange(event.target.value, null)}
                input={<Input />}
                renderValue={(selected) =>
                    renderValue(selected, listPlaceHolder)
                }
            >
                {MenuItems(list, listFilterId)}
            </Select>
            <Select
                multiple
                displayEmpty
                disabled={disableSecondList}
                value={valueRef(secondList, secondListFilterId)}
                onChange={(event) => handleOnChange(null, event.target.value)}
                input={<Input />}
                renderValue={(selected) =>
                    renderValue(selected, secondListPlaceHolder)
                }
            >
                {MenuItems(secondList, secondListFilterId)}
            </Select>
        </StyledFormGroup>
    );
};

export default DoubleSelectListFilter;
