import React, { useContext, useEffect, useState } from "react";
import { Dialog, Button, CircularProgress, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "@mui/styles/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import SatisfactionInformation from "./SatisfactionInformations";
import Form from "./Form";
import InfoPopUpBody from "components/common/InfoPopUpBody";
import { REPORT_STATUS } from "../constants";
import postForm from "../utils/postForm";
import { AuthContext } from "AuthProvider";
import theme from "theme";

const styles = {
    buttonLoader: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        color: theme.palette.grey.gainsboro,
    },
    buttonError: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginLeft: theme.spacing(3),
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.red.apple,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.red.apple,
        },
    },
    cancelButton: {
        fontWeight: "bold",
        fontSize: 13,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        color: theme.palette.grey.granite,
        "&:hover": {
            color: theme.palette.blue.maastricht,
        },
    },
    confirmButton: {
        fontWeight: "bold",
        fontSize: 13,
        marginLeft: theme.spacing(3),
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.blue.maastricht,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.grey.granite,
        },
    },
    confirmDisclaimerIconStyle: {
        alignSelf: "start",
        marginTop: theme.spacing(0.1),
        color: theme.palette.grey.granite,
    },
    confirmDisclaimerStyle: {
        color: theme.palette.grey.granite,
    },
};

const currentDataInitialState = {
    status: "",
    contactMethod: [],
    clientMood: "",
    comment: "",
};

const TRANS_PATH = "pages.satisfaction.reportPopUp";

const STEPS = {
    CONFIRM_STEP: "CONFIRM_STEP",
    FORM_STEP: "FORM_STEP",
};

const StyledConfirmContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12),
}));

const StyledFormStepContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(5),
}));

const StyledFormStepButtonContainer = styled("div")(() => ({
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const ReportPopUp = ({
    open,
    satisfactionReportData,
    satisfactionData,
    onClickClose,
    satisfactionId,
    onConfirmReportCreated,
}) => {
    const { t } = useTranslation();
    const { user, storeName } = useContext(AuthContext);

    const [step, setStep] = useState(STEPS.FORM_STEP);
    const [currentData, setCurrentData] = useState(currentDataInitialState);
    const [showFormErrors, setShowFormErrors] = useState(false);
    const [postIsLoading, setPostIsLoading] = useState(false);
    const [postIsError, setPostIsError] = useState(false);

    const initState = () => {
        const contactMethod = satisfactionReportData?.contact_method
            ? satisfactionReportData?.contact_method.split("|")
            : [];
        setCurrentData({
            status: satisfactionReportData?.status,
            contactMethod,
            clientMood: satisfactionReportData?.client_mood,
            comment: satisfactionReportData?.comment,
            proposedSolution: satisfactionReportData?.solution,
        });
    };

    useEffect(() => {
        setShowFormErrors(false);
    }, [currentData.status]);

    const onClickConfirm = async () => {
        const { status, contactMethod, clientMood, comment, proposedSolution } =
            currentData;

        if (status === REPORT_STATUS.TO_CONTACT && !comment) {
            setShowFormErrors(true);
        } else if (status === REPORT_STATUS.TO_NOT_CONTACT && !comment) {
            setShowFormErrors(true);
        } else if (
            status === REPORT_STATUS.WAITING &&
            (!contactMethod?.length || !comment)
        ) {
            setShowFormErrors(true);
        } else if (
            status === REPORT_STATUS.RESOLVED &&
            (!contactMethod?.length ||
                !comment ||
                !clientMood ||
                !proposedSolution)
        ) {
            setShowFormErrors(true);
        } else {
            setPostIsLoading(true);
            try {
                await postForm(currentData, satisfactionId, user, storeName);
                setPostIsLoading(false);
                setStep(STEPS.CONFIRM_STEP);
            } catch {
                setPostIsLoading(false);
                setPostIsError(true);
            }
        }
    };

    const changeCurrentData = (newFieldName, newFieldValue) => {
        setCurrentData((prevData) => ({
            ...prevData,
            [newFieldName]: newFieldValue,
        }));
    };

    const confirmButtonContent = () => {
        if (postIsLoading) {
            return <CircularProgress sx={styles.buttonLoader} size={25} />;
        }
        if (postIsError) {
            return t("common.retry");
        }
        return t("common.confirm").toUpperCase();
    };

    const onConfirmPost = () => {
        onClickClose();
        /*
         * Hotfix: set timeout because the post sent an event and we need to wait
         * for the change are effective in the DB
         * Todo: check for a solution in back end
         * **/
        setTimeout(onConfirmReportCreated, 1000);
    };

    const StepContent = () => {
        // eslint-disable-next-line no-constant-condition
        if (step === STEPS.CONFIRM_STEP) {
            return (
                <StyledConfirmContainer>
                    <InfoPopUpBody
                        title={t(`${TRANS_PATH}.confirmPopUpTitle`)}
                        IconTitle={CheckCircleIcon}
                        DisclaimerIcon={InfoIcon}
                        disclaimerIconStyle={styles.confirmDisclaimerIconStyle}
                        disclaimerStyle={styles.confirmDisclaimerStyle}
                        disclaimer={t(`${TRANS_PATH}.confirmPopUpDisclaimer`)}
                        onClickCta={onConfirmPost}
                        ctaText={t("common.close")}
                    />
                </StyledConfirmContainer>
            );
        }
        return (
            <>
                <StyledFormStepContainer>
                    <SatisfactionInformation data={satisfactionData} />
                    <Form
                        currentData={currentData}
                        changeCurrentData={changeCurrentData}
                        activateErrors={showFormErrors}
                    />
                </StyledFormStepContainer>
                <DialogActions>
                    <StyledFormStepButtonContainer>
                        <Button
                            disabled={postIsLoading}
                            onClick={onClickClose}
                            sx={styles.cancelButton}
                        >
                            {t("common.cancel").toUpperCase()}
                        </Button>
                        <Button
                            disabled={postIsLoading}
                            onClick={onClickConfirm}
                            sx={
                                postIsError
                                    ? styles.buttonError
                                    : styles.confirmButton
                            }
                        >
                            {confirmButtonContent()}
                        </Button>
                    </StyledFormStepButtonContainer>
                </DialogActions>
            </>
        );
    };

    return (
        <Dialog
            open={open}
            maxWidth={"md"}
            onEnter={initState}
            onExited={() => setStep(STEPS.FORM_STEP)}
        >
            {StepContent()}
        </Dialog>
    );
};

export default ReportPopUp;
