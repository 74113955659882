import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useContext,
} from "react";
import { useTranslation } from "react-i18next";
import ContentWrapper from "components/Layout/ContentWithHeader";
import HeaderRounded from "components/Table/HeaderComponents/HeaderRounded";
import BodyTableSimple from "components/Table/BodyComponents/BodyTableSimple";
import FooterPaginationSimple from "components/Table/FooterComponents/FooterPaginationSimple";
import InsideTable from "components/Table";
import makeColumns from "./utils/makeColumns";
import HeaderTableFilters from "./components/HeaderTableFilters";

import { REPORT_STATUS } from "./constants";
import { satisfactionQuery } from "./queries.graphql";
import { useQuery } from "@apollo/client";
import {
    buildGraphQLWhereClauseWithFilters,
    formatFiltersForGraphQL,
} from "./utils/filters";
import { decode, encode } from "lib/utils/urlFilters";
import { AuthContext } from "AuthProvider";
import ReportPopUp from "./ReportPopUp";
import { useHistory, useLocation } from "react-router-dom";
import InsideLoader from "components/common/InsideLoader";

const initialReportPopUpState = {
    open: false,
    satisfactionData: null,
};

const Satisfaction = () => {
    const lastRemoteDataHandlerParams = useRef(null);
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    const [columns, setColumns] = useState(null);
    const { refetch } = useQuery(satisfactionQuery, { skip: true });
    const [filters, setFilters] = useState([]);
    const [search, setSearch] = useState("");
    const { storeId } = useContext(AuthContext);
    const [reportPopUp, setReportPopUp] = useState(initialReportPopUpState);
    const [fetchSatStatus, setFetchSatStatus] = useState({
        isLoading: true,
        data: [],
        page: 0,
        totalCount: 0,
    });

    const history = useHistory();
    const location = useLocation();

    const onClickStatus = (data) => {
        setReportPopUp({ open: true, satisfactionData: data });
    };

    useEffect(() => {
        const columnsTemp = makeColumns(t, locale, storeId, onClickStatus);
        setColumns(columnsTemp);
        const IGNORE_DECODE = ["store"];
        const filtersFromUrl = decode(location.search, IGNORE_DECODE);
        setFilters(filtersFromUrl);
    }, [t, locale, storeId, location.search]);

    const remoteDataHandler = useCallback(
        async (remoteDataHandlerParams) => {
            const {
                pageSize,
                pageIndex,
                filters: tableFilters,
                globalFilter,
            } = remoteDataHandlerParams || {};
            let tempTableFilters = [...tableFilters];
            if (storeId) {
                tempTableFilters = [
                    ...tempTableFilters,
                    { id: "store", value: storeId },
                ];
            }

            const filtersFormatted = formatFiltersForGraphQL(
                tempTableFilters,
                globalFilter,
            );
            const whereClause =
                buildGraphQLWhereClauseWithFilters(filtersFormatted);

            const fetchVariables = {
                limit: pageSize,
                offset: pageIndex * pageSize,
                order_by: { created_at: "desc" },
                where: whereClause,
            };
            setFetchSatStatus((currentStatus) => ({
                ...currentStatus,
                isLoading: true,
            }));
            /*
             * save params of data handler
             * */
            lastRemoteDataHandlerParams.current = remoteDataHandlerParams;
            try {
                history.push({
                    pathname: location.pathname,
                    search: encode(filtersFormatted),
                });

                const {
                    data: satsData,
                    errors: refetchErrors,
                    error: refetchError,
                } = await refetch(fetchVariables);

                if (refetchErrors || refetchError) {
                    console.error("Problem fetching data");
                    console.debug(refetchErrors);
                    console.debug(refetchError);
                }
                /*
                 * Set table data info only if last saved params are same of current params
                 * in case of <InsideTable/> call remote data handler
                 * before last remote data handler call still waiting
                 * */
                if (
                    JSON.stringify(lastRemoteDataHandlerParams.current) ===
                    JSON.stringify(remoteDataHandlerParams)
                ) {
                    setFetchSatStatus({
                        isLoading: false,
                        data: satsData?.satisfactions || [],
                        page: pageIndex,
                        totalCount:
                            satsData?.satisfactions_aggregate?.aggregate?.count,
                    });
                }
            } catch (e) {
                console.error("catch error ==> ", e);
            }
        },
        [history, location.pathname, refetch, storeId],
    );

    const handleSearchChange = (value) => {
        setSearch(value);
    };

    const handleFiltersChange = (value) => {
        const filtersToReturn = [];
        const typeValues = [];
        const categoryValues = [];
        const commentValues = [];
        const statusValues = [];

        const keepCreated_at = filters.find(
            (filter) => filter.id === "created_at",
        );
        if (keepCreated_at) {
            filtersToReturn.push(keepCreated_at);
        }
        for (let i = 0; i < value.length; i++) {
            switch (value[i].value) {
                case "detractor":
                case "passive":
                case "promoter":
                    categoryValues.push(value[i].value);
                    break;
                case "withComment":
                    commentValues.push(true);
                    break;
                case "withoutComment":
                    commentValues.push(false);
                    break;
                case "contacted":
                    filtersToReturn.push({
                        id: "contacted",
                        value: true,
                    });
                    break;
                case "after_3_months":
                case "after_ordering":
                    typeValues.push(value[i].value);
                    break;
                case REPORT_STATUS.WAITING:
                case REPORT_STATUS.TO_CONTACT:
                case REPORT_STATUS.TO_NOT_CONTACT:
                case REPORT_STATUS.RESOLVED:
                    statusValues.push(value[i].value);
                    break;
                default:
                    break;
            }
        }
        if (categoryValues.length > 0) {
            filtersToReturn.push({
                id: "category",
                value: categoryValues,
            });
        }
        if (commentValues.length > 0) {
            filtersToReturn.push({
                id: "comment",
                value: commentValues,
            });
        }
        if (typeValues.length > 0) {
            filtersToReturn.push({
                id: "type",
                value: typeValues,
            });
        }
        if (statusValues.length > 0) {
            filtersToReturn.push({
                id: "status",
                value: statusValues,
            });
        }

        setFilters(filtersToReturn);
    };

    const handleDateChange = (value) => {
        setFilters((existingFilters) => {
            const filtersToReturn = existingFilters.filter(
                (filter) => filter.id !== "created_at",
            );
            return [...filtersToReturn, { id: "created_at", value }];
        });
    };

    const onClickCloseReportPopUp = () =>
        setReportPopUp(initialReportPopUpState);

    const refetchOnConfirmReportPosted = () => {
        setFilters([]);
        setFilters(filters);
        setSearch("");
    };

    return (
        <ContentWrapper title={t("common.satisfaction")}>
            <HeaderTableFilters
                onSearchChange={handleSearchChange}
                onDateChange={handleDateChange}
                onFilterChange={handleFiltersChange}
                values={filters}
            />
            <ReportPopUp
                open={reportPopUp.open}
                satisfactionReportData={
                    reportPopUp?.satisfactionData?.satisfaction_reports?.[0]
                }
                satisfactionData={reportPopUp.satisfactionData}
                satisfactionId={reportPopUp?.satisfactionData?.id}
                onClickClose={onClickCloseReportPopUp}
                onConfirmReportCreated={refetchOnConfirmReportPosted}
            />
            {columns ? (
                <InsideTable
                    searchBar={false}
                    renderTableHeader={(props) => (
                        <HeaderRounded
                            {...props}
                            headerCellsStyle={{ width: "8%" }}
                        />
                    )}
                    renderTableBody={BodyTableSimple}
                    renderTableFooter={FooterPaginationSimple}
                    columns={columns}
                    extFilters={filters}
                    extGlobalFilter={search}
                    remoteDataHandler={remoteDataHandler}
                    data={fetchSatStatus.data}
                    loading={fetchSatStatus.isLoading}
                    pageCount={fetchSatStatus.totalCount}
                    disableContextMenu
                    disableRowClick
                />
            ) : (
                <InsideLoader />
            )}
        </ContentWrapper>
    );
};

export default Satisfaction;
