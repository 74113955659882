import React from "react";
import {
    FormControl,
    ListItemText,
    MenuItem,
    Select,
    Checkbox,
    OutlinedInput,
    InputLabel,
    TextField,
} from "@mui/material";

import theme from "theme";

import {
    CLIENT_MOODS,
    REPORT_STATUS,
    CONTACT_METHOD,
    PROPOSED_SOLUTION,
    PROPOSED_SOLUTION_LIST,
    CLIENT_MOOD_LIST,
    CONTACT_METHOD_LIST,
    REPORT_STATUSES_LIST,
} from "../constants";
import { useTranslation } from "react-i18next";

const styles = {
    formControl: {
        display: "flex",
        width: "100%",
        "& .MuiFormLabel-root": {
            marginTop: 12,
            marginLeft: 13.6,
        },
        "& .MuiFormLabel-filled": {
            marginTop: 7,
        },
        "& label.Mui-focused": {
            marginTop: 7,
        },
    },
    comment: {
        display: "flex",
        width: "100%",
        marginTop: theme.spacing(2),
    },
    select: {
        marginTop: theme.spacing(2),
    },
};

const TRANS_PATH = "pages.satisfaction.reportPopUp";

const Form = ({ currentData, changeCurrentData, activateErrors }) => {
    const { t } = useTranslation();

    const TRAD_OF_LISTS = {
        [CLIENT_MOODS.HAPPY]: t(`${TRANS_PATH}.happyClientMood`),
        [CLIENT_MOODS.MIXED]: t(`${TRANS_PATH}.mixedClientMood`),
        [CLIENT_MOODS.UNHAPPY]: t(`${TRANS_PATH}.unhappyClientMood`),
        [CONTACT_METHOD.PHONE]: t(`${TRANS_PATH}.phoneContactMethod`),
        [CONTACT_METHOD.EMAIL]: t(`${TRANS_PATH}.emailContactMethod`),
        [CONTACT_METHOD.AT_STORE]: t(`${TRANS_PATH}.atStoreContactMethod`),
        [REPORT_STATUS.WAITING]: t(`${TRANS_PATH}.waitingReportStatus`),
        [REPORT_STATUS.TO_CONTACT]: t(`${TRANS_PATH}.toContactReportStatus`),
        [REPORT_STATUS.TO_NOT_CONTACT]: t(
            `${TRANS_PATH}.doNotContactReportStatus`,
        ),
        [REPORT_STATUS.RESOLVED]: t(`${TRANS_PATH}.resolvedReportStatus`),
        [PROPOSED_SOLUTION.FOO]: "foo",
        [PROPOSED_SOLUTION.BAR]: "bar",
        status: t("common.status"),
        clientMood: t("common.mood"),
        contactMethod: t("common.contactMethod"),
        proposedSolution: t(`${TRANS_PATH}.solution`),
        comment: t("common.comment"),
    };

    const { status, clientMood, contactMethod, proposedSolution, comment } =
        currentData;

    const onChangeInput = (fieldName, fieldValue) => {
        const newValue = Array.isArray(fieldValue)
            ? fieldValue.map((value) => value)
            : fieldValue;
        changeCurrentData(fieldName, newValue);
    };

    const selectInput = (list, value, fieldName, multiple = false) => (
        <FormControl
            sx={styles.formControl}
            error={(value === "" || !value?.length) && activateErrors}
        >
            <InputLabel>{TRAD_OF_LISTS[fieldName]}*</InputLabel>
            <Select
                multiple={multiple}
                value={value}
                sx={styles.select}
                onChange={(d) => onChangeInput(fieldName, d?.target?.value)}
                input={<OutlinedInput label={TRAD_OF_LISTS[fieldName]} />}
                renderValue={(selected) =>
                    Array.isArray(selected)
                        ? selected.map((elem) => TRAD_OF_LISTS[elem]).join(", ")
                        : TRAD_OF_LISTS[selected]
                }
            >
                {list.map((elem) => (
                    <MenuItem key={elem} value={elem}>
                        {Array.isArray(value) && (
                            <Checkbox
                                checked={!!value.find((v) => v === elem)}
                            />
                        )}
                        <ListItemText primary={TRAD_OF_LISTS[elem]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const activateContactMethodSelect = [REPORT_STATUS.WAITING].some(
        (elem) => elem === currentData?.status,
    );

    const activateAllSelects = [REPORT_STATUS.RESOLVED].some(
        (elem) => elem === currentData?.status,
    );

    return (
        <>
            {selectInput(REPORT_STATUSES_LIST, status, "status")}
            {(activateContactMethodSelect || activateAllSelects) &&
                selectInput(
                    CONTACT_METHOD_LIST,
                    contactMethod,
                    "contactMethod",
                    true,
                )}
            {activateAllSelects &&
                selectInput(
                    PROPOSED_SOLUTION_LIST,
                    proposedSolution,
                    "proposedSolution",
                )}
            {activateAllSelects &&
                selectInput(CLIENT_MOOD_LIST, clientMood, "clientMood")}
            <TextField
                value={comment}
                error={activateErrors && !comment}
                onChange={(d) => onChangeInput("comment", d?.target?.value)}
                sx={styles.comment}
                variant="outlined"
                label={`${TRAD_OF_LISTS["comment"]}*`}
                multiline
                rows={4}
            />
        </>
    );
};

export default Form;
