import React from "react";
import { Box, Typography, Grid, Fade } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { formatDateLocales } from "services/toolbox";
import { useTranslation } from "react-i18next";
import theme from "theme";
import OrderHistory from "./OrdersHistory";

const styles = {
    subtitle: {
        borderBottom: "1px solid #000",
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        fontWeight: "bold",
    },
};

const CustomerInfo = ({ customerData }) => {
    const orders = customerData.orders;
    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    return (
        <>
            <Box display="flex" alignItems="center">
                <AccountCircle style={{ paddingRight: 5 }} fontSize="large" />
                <Typography variant="h5">
                    {customerData.firstname} {customerData.lastname}
                </Typography>
            </Box>
            <Typography sx={styles.subtitle}>
                {t("common.contactInfo")}
            </Typography>
            <Fade in={true} timeout={1000}>
                <Grid>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography>{`${t(
                                "common.birthdate",
                            )}:`}</Typography>
                        </Grid>
                        <Grid item>
                            {customerData.birthday && (
                                <Typography>
                                    {formatDateLocales(
                                        customerData.birthday,
                                        locale,
                                        "P",
                                    )}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography>{`${t("common.mail")}:`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{customerData.email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography>{`${t("common.phone")}: `}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{customerData.phone_number}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography>{`${t(
                                "common.newsletter",
                            )}: `}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                {customerData.subscriber
                                    ? t("common.yes")
                                    : t("common.no")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
            <OrderHistory orderData={orders} />
        </>
    );
};

export default CustomerInfo;
