import React, { useState } from "react";
import { Dialog } from "@mui/material";
import FirstStep from "./FirstStep";
import SendAlert from "./SendAlert";
import AlertSentAndConfirmed from "./AlertSentAndConfirmed";
import styled from "@mui/styles/styled";

const styles = {
    dialog: {
        "& .MuiDialog-paperWidthMd": {
            maxWidth: 715,
        },
    },
};

const StyledContentContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(10),
}));

const ConfirmBadDestinationPopUp = ({
    open,
    close,
    orders,
    onClickConfirmReception,
    scanConfirmationIsLoading,
    statusChangeIsLoading,
    handleConfirmDispatchError,
}) => {
    const [currentStep, setCurrentStep] = useState("FirstStep");
    const [currentOrders, setCurrentOrders] = useState(orders);

    const _RenderSelectedStep = () => {
        switch (currentStep) {
            case "FirstStep":
                return (
                    <FirstStep
                        close={close}
                        isLoading={scanConfirmationIsLoading}
                        confirmReception={onClickConfirmReception}
                        orders={currentOrders}
                        nextStep={() => setCurrentStep("SendAlert")}
                    />
                );
            case "SendAlert":
                return (
                    <SendAlert
                        close={close}
                        orders={currentOrders}
                        isLoading={statusChangeIsLoading}
                        handleConfirmDispatchError={handleConfirmDispatchError}
                        prevStep={() => setCurrentStep("FirstStep")}
                        nextStep={() => setCurrentStep("AlertSentAndConfirmed")}
                    />
                );
            case "AlertSentAndConfirmed":
                return (
                    <AlertSentAndConfirmed
                        close={close}
                        orders={currentOrders}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth={"md"}
            sx={styles.dialog}
            TransitionProps={{
                onEnter: () => setCurrentOrders(orders),
                onExited: () => {
                    setCurrentStep("FirstStep");
                    setCurrentOrders(null);
                },
            }}
            PaperProps={{ sx: { overflowY: "unset" } }}
        >
            <StyledContentContainer>
                {currentOrders && _RenderSelectedStep()}
            </StyledContentContainer>
        </Dialog>
    );
};

export default ConfirmBadDestinationPopUp;
