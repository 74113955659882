import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "AuthProvider";
import { endOfToday, startOfToday } from "date-fns";
import ORDER_STATUS from "constants/order-status";
import { useQuery } from "@apollo/client";
import { orderQuery } from "./queries.graphql";
import Recap from "./Recap";
import Scan from "./Scan";

const TYPES_QUERY_VAR = ["VP", "VDS", "VF"];

const OrderReceipt = () => {
    const timeoutRef = useRef();
    const [disableRefresh, setDisableRefetch] = useState(false);

    const { storeId } = useContext(AuthContext);

    const queryVars = {
        store: storeId || "",
        beginUpdatedAt: startOfToday(),
        endUpdatedAt: endOfToday(),
        statusOrderAtShop: ORDER_STATUS.ORDER_AT_SHOP,
        globalStatusOrderShipped: ORDER_STATUS.S_ORDER_SHIPPED,
        globalStatusOrderEnded: ORDER_STATUS.S_ORDER_ENDED,
        types: TYPES_QUERY_VAR,
    };

    const {
        data,
        error,
        loading,
        refetch: refetchOrders,
    } = useQuery(orderQuery, {
        variables: queryVars,
        skip: !storeId,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (!error) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            setDisableRefetch(true);

            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null;
                setDisableRefetch(false);
            }, 5000);
        }
        return () => clearTimeout(timeoutRef.current);
    }, [error, loading, refetchOrders]);

    return (
        <>
            {storeId && (
                <Recap
                    orders={data?.orders}
                    loading={loading}
                    refetch={refetchOrders}
                    error={error}
                    disableRefresh={disableRefresh}
                />
            )}
            <Scan />
        </>
    );
};

export default OrderReceipt;
