import React, { useContext } from "react";
import styled from "@mui/styles/styled";
import { AuthContext } from "AuthProvider";
import { Link } from "react-router-dom";
import {
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Skeleton,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material/";

import Logo from "assets/logoJFB.png";
import SettingsMenu from "./SettingsMenu";
import LocalizationMenu from "./LocalizationMenu";
import StoreViewMenu from "./StoreViewMenu";
import theme from "theme";

const drawerWidth = 220;

const styles = {
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    skeletonMenu: {
        backgroundColor: "lightgrey",
    },
    skeletonName: {
        backgroundColor: "lightgrey",
        marginRight: "1rem",
    },
    toolBar: {
        paddingLeft: theme.spacing(1),
        justifyContent: "center",
    },
};

const StyledLogo = styled("img")(() => ({
    width: 150,
    paddingLeft: 5,
}));

const StyledLeftMenuContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "center",
}));

const StyledLeftMenu = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
}));

const StyledUserNameWrapper = styled("div")(() => ({
    textAlign: "right",
    marginRight: theme.spacing(2),
}));

const StyledUserImg = styled("img")(() => ({
    width: 50,
    height: 50,
    borderRadius: "50%",
    padding: 5,
    marginRight: 5,
}));

const StyledUserInfo = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
}));

const StyledDivider = styled("span")(({ theme: spanTheme }) => ({
    display: "inline-block",
    borderLeft: `1px solid ${spanTheme.palette.white.absolute}`,
    marginLeft: spanTheme.spacing(2),
    marginRight: spanTheme.spacing(2),
    marginTop: spanTheme.spacing(0.8),
    marginBottom: spanTheme.spacing(0.8),
}));

const Header = ({ handleDrawerOpen, open }) => {
    const { user } = useContext(AuthContext);
    const RenderUserNameAndMenus = () => (
        <>
            <StyledUserInfo>
                <StyledUserNameWrapper>
                    <Typography noWrap variant="caption">
                        Bonjour
                    </Typography>
                    <Typography noWrap>{user && user.displayName}</Typography>
                </StyledUserNameWrapper>
                <StyledUserImg src={user.photoURL} alt="profile" />
                <StyledDivider />
            </StyledUserInfo>
            <SettingsMenu />
            <LocalizationMenu />
        </>
    );

    const RenderUserAndMenusLoading = () => (
        <>
            <Skeleton
                sx={styles.skeletonName}
                animation="pulse"
                variant="circular"
                width={40}
                height={40}
            />
            <Skeleton
                sx={styles.skeletonMenu}
                animation="pulse"
                width={100}
                variant="text"
            />
        </>
    );

    return (
        <AppBar sx={{ ...styles.appBar, ...(open ? styles.appBarShift : {}) }}>
            <Toolbar sx={styles.toolBar}>
                {open === true ? null : (
                    <IconButton
                        color="secondary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Link to="/">
                    {open === true ? null : (
                        <StyledLogo src={Logo} alt="Jimmy fairly" />
                    )}
                </Link>
                <StoreViewMenu />
                <StyledLeftMenuContainer>
                    <StyledLeftMenu>
                        {user
                            ? RenderUserNameAndMenus
                            : RenderUserAndMenusLoading}
                    </StyledLeftMenu>
                </StyledLeftMenuContainer>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
