import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ProductInfosDetails from "./ProductInfosDetails";
import ProductInfosDetailsHeader from "./ProductInfosDetailsHeader";
import {
    orderQueryTypes,
    orderIsWebMultiQuery,
    orderByIdQuery,
} from "../utils/queries.graphql";
import { useQuery, useLazyQuery } from "@apollo/client";

//TODO FAIRE 3 TYPES DE VISUELS POUR ACCESSOIRE / VERRES / MONTURE / PACK COMPLET (VERRE + MONTURE)

export default function ProductInfos({
    classes,
    orderData,
    RenderDownloadPdfList,
}) {
    const {
        id,
        billing_address,
        order_product_lines,
        flat_amount_including_tax,
        currency,
        prescriptions,
    } = orderData;
    const [isOrderWeb, setIsOrderWeb] = useState(false);
    const [isOrderWebMulti, setIsOrderWebMulti] = useState(false);
    const [getOrderIsWebMulti, { data: orderIsWebMultiResult }] = useLazyQuery(
        orderIsWebMultiQuery,
        {
            variables: { id },
        },
    );
    const [getWebOrderData, { loading, error, data: childOrders }] =
        useLazyQuery(orderByIdQuery(orderQueryTypes.queryByParentId), {
            variables: { id },
        });

    useEffect(() => {
        setIsOrderWeb(id.startsWith("WEB"));
    }, [id]);

    useEffect(() => {
        if (isOrderWeb) {
            getWebOrderData();
            getOrderIsWebMulti();
        }
    }, [isOrderWeb]);

    useEffect(() => {
        if (orderIsWebMultiResult?.orders[0]?.is_multi)
            setIsOrderWebMulti(true);
    }, [orderIsWebMultiResult]);

    if (error) {
        console.error(error);
    }

    const getProductPrice = () => {
        let price = `${
            flat_amount_including_tax >= 0 ? flat_amount_including_tax : "none"
        } ${currency ? currency : "none"}`;
        return price;
    };

    return isOrderWebMulti && childOrders
        ? childOrders?.orders.map((childOrder, i) => (
              <Grid key={childOrder.id} sx={{ mb: "2em" }}>
                  <ProductInfosDetailsHeader
                      order={childOrder}
                      RenderDownloadPdfList={RenderDownloadPdfList}
                      opticalDataOrder={{
                          parendId: billing_address.external_id,
                          childId: order_product_lines[i].external_id,
                          prescription: childOrder.prescriptions,
                          country: childOrder.store?.country.toUpperCase(),
                      }}
                      isOrderWeb={isOrderWeb}
                  />
                  <ProductInfosDetails
                      getProductPrice={getProductPrice}
                      orderLine={childOrder.order_product_lines[0]}
                      prescriptions={childOrder.prescriptions}
                  />
              </Grid>
          ))
        : !isOrderWebMulti &&
              order_product_lines.map((orderLine) => (
                  <Grid key={orderLine.id}>
                      <ProductInfosDetailsHeader
                          order={orderLine}
                          RenderDownloadPdfList={RenderDownloadPdfList}
                          opticalDataOrder={{
                              parendId: billing_address.external_id,
                              childId: order_product_lines[0].external_id,
                              prescription:
                                  childOrders?.orders[0]?.prescriptions,
                              country:
                                  childOrders?.orders[0]?.store?.country.toUpperCase(),
                          }}
                          isOrderWeb={isOrderWeb}
                      />
                      <ProductInfosDetails
                          getProductPrice={getProductPrice}
                          orderLine={orderLine}
                          prescriptions={prescriptions}
                      />
                  </Grid>
              ));
}
