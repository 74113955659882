import { gql } from "@apollo/client";

const orderQueryTypes = {
    queryByPK: {
        type: "orders_by_pk(id: $id)",
        prescription: "(where: { order_id: { _eq: $id } })",
    },
    queryByParentId: {
        type: "orders(where: {parent_id: {_eq: $id}})",
        prescription: "",
    },
};

const orderIsWebMultiQuery = gql`
    query OrderById($id: String!) {
        orders(where: { id: { _eq: $id }, is_multi: { _eq: true } }) {
            id
            is_multi
            origin
        }
    }
`;

const orderByIdQuery = (orderQueryType) => gql`
    query OrderById($id: String!) {
        order_statuses_definitions(order_by: { index: asc }) {
            id
            parent_id
            index
            max_delay
            label
            description
            is_problem
            is_warning
        }
        ${orderQueryType.type} {
            id
            keybuild_id
            currency
            created_at
            carrier_type
            tracking_number
            type
            is_care_service
            is_multi
            origin
            shipping_method
            global_status_detail {
                label
                id
            }
            vendor_info {
                firstname
                lastname
            }
            cares {
                id
                created_at
                store {
                    name
                }
                type
                reason
                orderOrigin {
                    id
                    created_at
                    store {
                        name
                    }
                }
                orderCreated {
                    id
                    created_at
                    store {
                        name
                    }
                }
            }
            caresByOrderIdOrigin {
                id
                created_at
                store {
                    name
                }
                type
                reason
                orderOrigin {
                    id
                    created_at
                    store {
                        name
                    }
                }
                orderCreated {
                    id
                    created_at
                    store {
                        name
                    }
                }
            }
            order_status_changes: order_status_changes(
                order_by: { created_at: desc }
            ) {
                created_at
                id
                order_statuses_id
                order_statuses_definition {
                    id
                    index
                    label
                    is_problem
                    description
                }
                comment
            }
            flat_amount_including_tax
            customer_account {
                firstname
                lastname
                created_at
                birthday
                social_security_number
                email
                external_id
                id
                subscriber
                phone_number
            }
            billing_address {
                city
                company
                country_code
                firstname
                lastname
                line1
                line2
                phone_number
                postal_code
                state
                external_id
            }
            shipping_address {
                city
                company
                country_code
                firstname
                lastname
                line1
                line2
                phone_number
                postal_code
                state
            }
            store {
                id
                name
                country
            }
            destination_store {
                name
            }
            order_product_lines {
                glassByLeftGlass {
                    type
                }
                glassByRightGlass {
                    type
                }
                lens_pack {
                    label
                    id
                }
                refinement {
                    label
                }
                option {
                    label
                }
                order_id
                flat_amount_excl_tax
                flat_amount_incl_tax
                external_id
                frame {
                    label
                    id
                    image {
                        url
                    }
                }
                accessory {
                    id
                    created_at
                    label
                    image {
                        url
                        alt
                    }
                }
                carrier_type
                tracking_number
            }
            prescriptions${orderQueryType.prescription} {
                left_axis
                left_cylinder
                left_pd
                left_sphere
                left_height
                left_addition
                right_axis
                right_cylinder
                right_pd
                right_sphere
                right_height
                right_addition
                type
                url
                id
            }
            optimum {
                transmission_status
                client_id
                created_at
                frame_price
                id
                invoiced
                left_lens_price
                offre_id
                opticien_id
                pairing_price
                ref_verre_droit
                ref_verre_gauche
                right_lens_price
                token_code
                updated_at
                url
                visite_id
            }
        }
    }
`;

export { orderQueryTypes, orderIsWebMultiQuery, orderByIdQuery };
