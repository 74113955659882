import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Typography,
} from "@mui/material";
import styled from "@mui/styles/styled";
import theme from "theme";

const StyledContainer = styled("div")(({ style }) => ({
    margin: theme.spacing(5),
    ...style,
}));

const StyledActionsContainer = styled(DialogActions)(() => ({
    display: "flex",
    justifyContent: "space-around",
}));

const StyledConfirmButton = styled(Button)(({ theme }) => ({
    fontWeight: "bold",
    fontSize: theme.fontSize.M,
    color: theme.palette.white.absolute,
    backgroundColor: theme.palette.blue.maastricht,
    borderRadius: theme.border.radius.button,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    "&:hover": {
        color: theme.palette.grey.granite,
    },
}));

const styles = {
    cancelButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    confirmButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.blue.maastricht,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.grey.granite,
        },
    },
    title: {
        textAlign: "center",
        fontWeight: "bolder",
        fontSize: 24,
        color: theme.palette.blue.maastricht,
    },
};

const ConfirmationDialog = (props) => (
    <Dialog
        open={props.open}
        onClose={props.handleNOK}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <StyledContainer style={props.style}>
            {props.title && (
                <DialogTitle id="alert-dialog-title">
                    <Typography sx={styles.title} variant={"h5"}>
                        {props.title}
                    </Typography>
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText
                    component="div"
                    id="alert-dialog-description"
                >
                    {props.children ? props.children : props.content}
                </DialogContentText>
            </DialogContent>
            <StyledActionsContainer>
                {props.handleNOK && (
                    <Button
                        disabled={props.isLoading}
                        onClick={props.handleNOK}
                        sx={styles.cancelButton}
                    >
                        {props.buttonLabels.cancel}
                    </Button>
                )}

                {props.handleOK && (
                    <StyledConfirmButton
                        disabled={props.isLoading}
                        onClick={props.handleOK}
                        autoFocus
                        variant="contained"
                        color="primary"
                        sx={styles.confirmButton}
                    >
                        {props.isLoading && <CircularProgress size={14} />}{" "}
                        {props.buttonLabels.confirm}
                    </StyledConfirmButton>
                )}
            </StyledActionsContainer>
        </StyledContainer>
    </Dialog>
);

ConfirmationDialog.propTypes = {
    className: PropTypes.object,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    handleOK: PropTypes.func,
    handleNOK: PropTypes.func,
    content: PropTypes.string,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
};

export default ConfirmationDialog;
