import React, { useContext } from "react";
import styled from "@mui/styles/styled";
import { AuthContext } from "AuthProvider";

import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { List, Drawer, Icon, Divider, CardMedia } from "@mui/material";

import Logo from "assets/logoJFB.png";
import LogoInside from "assets/insideLogo.svg";
import MenuItem from "./VerticalMenuItem";
import { routesFn } from "Routes";
import { useTranslation } from "react-i18next";
import theme from "theme";

const drawerWidth = 220;

const styles = {
    chevronIcon: {
        paddingLeft: theme.spacing(1),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        borderRight: "none",
        boxSizing: "unset",
        width: drawerWidth,
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        borderRight: "none",
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(9),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    insideLogoImg: {
        margin: `${theme.spacing(3.3)} 0`,
        alignSelf: "center",
        width: "50%",
    },
};

const StyledToolBar = styled("div")(({ theme: divTheme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: divTheme.palette.primary.main,
    paddingRight: divTheme.spacing(2),
    // necessary for content to be below app bar
    ...divTheme.mixins.toolbar,
}));

const StyledLogo = styled("img")(() => ({
    width: 150,
    paddingLeft: 5,
}));

const StyledLogoDisabled = styled("div")(() => ({
    marginTop: 55,
}));

const VerticalMenu = ({ handleDrawerClose, open }) => {
    const { userRoles } = useContext(AuthContext);
    const { t } = useTranslation();
    const routes = routesFn(t);

    const authorizedRoutes = routes.filter(
        (route) =>
            route.roles.some((authorizedRole) =>
                userRoles.includes(authorizedRole),
            ) && route.menu,
    );

    return (
        <Drawer
            variant="permanent"
            sx={{
                ...styles.drawer,
                ...(open ? styles.drawerOpen : {}),
                ...(!open ? styles.drawerClose : {}),
            }}
            PaperProps={{
                sx: {
                    ...(open ? styles.drawerOpen : {}),
                    ...(!open ? styles.drawerClose : {}),
                },
            }}
        >
            <StyledToolBar>
                <Link to="/">
                    {open === false ? null : (
                        <StyledLogo
                            src={Logo}
                            className="logo"
                            alt="Jimmy fairly"
                        />
                    )}
                </Link>
                <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerClose}
                    edge="start"
                >
                    {open === false ? null : (
                        <Icon color="secondary" style={styles.chevronIcon}>
                            chevron_left
                        </Icon>
                    )}
                </IconButton>
            </StyledToolBar>
            <Divider />
            <>
                {open === false ? (
                    <StyledLogoDisabled />
                ) : (
                    <CardMedia
                        sx={styles.insideLogoImg}
                        image={LogoInside}
                        component="img"
                        alt="Inside"
                    />
                )}
                <List>
                    {authorizedRoutes.map(({ text, icon, path }, i) => (
                        <MenuItem
                            key={text}
                            icon={icon}
                            text={open && text}
                            to={path}
                        />
                    ))}
                </List>
            </>
        </Drawer>
    );
};

export default VerticalMenu;
