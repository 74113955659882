import React, { useState, useEffect, useContext } from "react";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/styles";
import { ControlPoint, Favorite } from "@mui/icons-material";
import { useQuery, gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useDebouncedCallback } from "use-debounce";
import axios from "axios";
import { isEqual } from "lodash";
import TagsInput from "components/common/TagInput";
import { useTranslation } from "react-i18next";
import ConfirmBadDestinationPopUp from "./ConfirmBadDestinationPopUp";
import { AuthContext } from "AuthProvider";
import HandoverPopUp from "./HandoverPopUp";
import theme from "theme";

import ROLES from "constants/roles";
import ConfirmationPopUp from "./ConfirmationPopUp";
import Text from "components/Text";

const TRANS_PATH = "pages.receipt.scan";

const statusesConfigurationFn = (t) => ({
    admission: {
        dialogTitle: t(`${TRANS_PATH}.admissionDialogTitle`),
        dialogContent: t(`${TRANS_PATH}.admissionDialogContent`),
        boldContent: t(`${TRANS_PATH}.availableAtStore`),
        disclaimer: t(`${TRANS_PATH}.disclaimer`),
        buttons: {
            confirm: t("common.confirm"),
            cancel: t("common.cancel"),
        },
        status: "ORDER_AT_SHOP",
    },
    handOver: {
        dialogTitle: t(`${TRANS_PATH}.handOverDialogTitle`),
        dialogContent: t(`${TRANS_PATH}.handOverDialogContent`),
        boldContent: t(`${TRANS_PATH}.ended`),
        disclaimer: t(`${TRANS_PATH}.disclaimer`),
        buttons: {
            confirm: t("common.confirmDeliveryToCustomer"),
            cancel: t("common.cancel"),
        },
        status: "ORDER_DELIVERED",
    },
});

const orderExistenceQuery = gql`
    query checkOrdersExistence($orderIds: [String!]) {
        orders(
            where: {
                _or: [
                    { id: { _in: $orderIds } }
                    { keybuild_id: { _in: $orderIds } }
                ]
            }
        ) {
            id
            store_destination_id
            origin
            optimum {
                transmission_status
            }
            destination_store {
                name
            }
            order_status_changes(order_by: { created_at: desc }, limit: 4) {
                order_statuses_id
            }
            customer_account {
                firstname
                lastname
            }
            keybuild_id
        }
        satisfactions(where: { order_id: { _in: $orderIds } }) {
            order_id
            n_avg
        }
    }
`;

const orderFormat = /^(WEB|MAG)/;
const kbOrderFormat = /_[0-9]$/;

const styles = {
    tagInput: {
        minHeight: "200px",
        padding: theme.spacing(1),
        margin: theme.spacing(2),
        marginTop: theme.spacing(3),
        marginBottom: 0,
        border: `solid 1px ${theme.palette.primary.main}`,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
};

const tagEqualityFn = (prev, next) => {
    return isEqual(prev, next);
};

const StyledGridContainer = styled(Grid)(() => ({ textAlign: "center" }));

const StyledScanButton = styled(Button)(({ disabled }) => ({
    backgroundColor: theme.palette.blue.maastricht,
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize * 1.5,
    minHeight: "90px",
    minWidth: "210px",
    fontWeight: "bolder",
}));

const StyledButtonTextWrapper = styled("div")(() => ({
    flexDirection: "column",
    fontWeight: "light",
    textAlign: "left",
    paddingLeft: theme.spacing(1.5),
}));

const StyledTotalCommand = styled("div")(({ theme }) => ({
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderTop: "none",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: "center",
}));

const StyledFavorite = styled(Favorite)(() => ({ fontSize: "35px" }));

const StyledControlPoint = styled(ControlPoint)(() => ({ fontSize: "35px" }));

const NUMBER_OF_TAGS_BEFORE_DEBOUNCE = 1;

const AUTHORIZED_ROLES_FOR_ROLLBACK_STATUS = [
    ROLES.INSIDE_TECH,
    ROLES.INSIDE_DR,
    ROLES.INSIDE_XP,
];

/* const AUTHORIZED_ROLES_FOR_FORCE_HANDOVER = [
    ROLES.INSIDE_TECH,
    ROLES.INSIDE_DR,
    ROLES.INSIDE_XP,
];*/

const Scan = () => {
    const { t } = useTranslation();
    const { storeId, userRoles, user } = useContext(AuthContext);
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogType, setDialogType] = useState(null);
    // const [confirmDialogTitle, setConfirmDialogTitle] = useState("");
    // const [confirmDialogContent, setConfirmDialogContent] = useState("");
    // const [confirmDialogButtons, setConfirmDialogButtons] = useState("");
    const [confirmationPopUpOpen, setConfirmationPopUpOpen] = useState(false);
    const [confirmationLayout, setConfirmationLayout] = useState("");
    const [errorOnHandOver, setErrorOnHandOver] = useState(false);
    const [badDestinationPopUp, setBadDestinationPopUp] = useState({
        open: false,
        statusChangeIsLoading: false,
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { loading: orderExistenceLoading, refetch } = useQuery(
        orderExistenceQuery,
        {
            skip: true,
        },
    );
    const debouncedCheckOrders = useDebouncedCallback(
        (tagToCheck) => {
            checkOrdersExistance(tagToCheck);
        },
        tags.length >= NUMBER_OF_TAGS_BEFORE_DEBOUNCE ? 2500 : 0,
        { equalityFn: tagEqualityFn },
    );
    const statusesConfiguration = statusesConfigurationFn(t);

    const changeOrderStatusService = async (
        orders,
        newStatus,
        comment = null,
    ) => {
        const body = {
            orders,
            newStatus,
        };
        if (comment) {
            body.comment = comment;
        }
        return axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/connectors-rest-api/status`,
            body,
        );
    };

    const changeKBOrderStatusService = async (
        orders,
        newStatus,
        comment = null,
    ) => {
        const body = {
            orders,
            newStatus,
        };
        if (comment) {
            body.comment = comment;
        }
        console.log(body);
        return axios.post(
            `${process.env.REACT_APP_API_GATEWAY_URL}/connectors/v2/status`,
            body,
            { headers: { "x-api-key": process.env.REACT_APP_X_API_KEY } },
        );
    };

    const CloseActionButton = (props) => (
        <Button
            onClick={() => {
                closeSnackbar(props.actionKey);
            }}
        >
            {t("common.close").toUpperCase()}
        </Button>
    );

    useEffect(() => {
        setIsButtonDisabled(
            !(
                tags.filter((tag) => tag.isLoading).length === 0 &&
                !isLoading &&
                !orderExistenceLoading &&
                tags.length !== 0
            ),
        );
    }, [isLoading, orderExistenceLoading, tags]);

    const buttonClickHandler = (e, openAdmission) => {
        const haveErrorDispatchTags = tags.find((tag) => tag.isDispatchError);
        /*const haveErrorOnHandOver = tags.find(
            (tag) =>
                tag.lastStatuses && !tag.lastStatuses.includes("ORDER_AT_SHOP"),
        );*/

        if (openAdmission) {
            setDialogType("admission");
            // setConfirmDialogTitle(statusesConfiguration.admission.dialogTitle);
            // setConfirmDialogContent(
            //     statusesConfiguration.admission.dialogContent,
            // );
            // setConfirmDialogButtons(statusesConfiguration.admission.buttons);
            setConfirmationLayout("admission");
            setConfirmationPopUpOpen(true);
            return;
        }

        if (e.currentTarget.id === "admission" && haveErrorDispatchTags) {
            setBadDestinationPopUp((prevState) => ({
                ...prevState,
                open: true,
            }));
            return;
        }

        // Avoid to pass from shippped to ended without passing by
        // available in store
        /*
        if (
            e.currentTarget.id === "handOver" &&
            haveErrorOnHandOver &&
            !AUTHORIZED_ROLES_FOR_FORCE_HANDOVER.some(
                (authorizedRole) =>
                    userRoles.includes(authorizedRole) ||
                    authorizedRole.includes("*"),
            )
        ) {
            setErrorOnHandOver(haveErrorOnHandOver);
            return;
        }*/

        setDialogType(e.currentTarget.id);
        // setConfirmDialogTitle(
        //     statusesConfiguration[e.currentTarget.id].dialogTitle,
        // );
        // setConfirmDialogContent(
        //     statusesConfiguration[e.currentTarget.id].dialogContent,
        // );
        // setConfirmDialogButtons(
        //     statusesConfiguration[e.currentTarget.id].buttons,
        // );
        setConfirmationLayout(e.currentTarget.id);
        setConfirmationPopUpOpen(true);
    };

    const scanConfirmed = async () => {
        setIsLoading(true);
        try {
            if (dialogType) {
                const ordersAlreadyAtShop = [];
                const ordersAlreadyDelivered = [];
                const statusDestination =
                    statusesConfiguration[dialogType].status;
                const orders = tags.filter((tag) => {
                    let lastTagIsSame = false;
                    let statusGoBack = false;
                    // We check if the last status is the same to avoid duplicates
                    if (
                        tag.lastStatuses &&
                        tag.lastStatuses[0] === statusDestination
                    ) {
                        lastTagIsSame = true;
                        tag.lastStatuses[0] === "ORDER_DELIVERED"
                            ? ordersAlreadyDelivered.push(tag.value)
                            : ordersAlreadyAtShop.push(tag.value);
                    }

                    // For ORDER_AT_SHOP we check that the order is not already ORDER_DELIVERED to avoid status to go back
                    // except for authorized roles
                    if (
                        statusDestination === "ORDER_AT_SHOP" &&
                        tag.lastStatuses &&
                        tag.lastStatuses.find(
                            (lastStatus) => lastStatus === "ORDER_DELIVERED",
                        ) &&
                        !AUTHORIZED_ROLES_FOR_ROLLBACK_STATUS.some(
                            (authorizedRole) =>
                                userRoles.includes(authorizedRole) ||
                                authorizedRole.includes("*"),
                        )
                    ) {
                        statusGoBack = true;
                        ordersAlreadyDelivered.push(tag.value);
                    }
                    return tag.isValid && !lastTagIsSame && !statusGoBack;
                });

                const magAndWebOrders = [];
                const kbOrders = [];

                orders.forEach((order) => {
                    order.origin === "KB"
                        ? kbOrders.push(order.value)
                        : magAndWebOrders.push(order.value);
                });

                if (magAndWebOrders.length > 0) {
                    await changeOrderStatusService(
                        magAndWebOrders,
                        statusesConfiguration[dialogType].status,
                        JSON.stringify({
                            scanned_by: storeId ? storeId : user.email,
                        }),
                    );
                }

                if (kbOrders.length > 0) {
                    await changeKBOrderStatusService(
                        kbOrders,
                        statusesConfiguration[dialogType].status,
                        JSON.stringify({
                            scanned_by: storeId ? storeId : user.email,
                        }),
                    );
                }

                if (ordersAlreadyAtShop.length > 0) {
                    enqueueSnackbar(
                        `${t(`${TRANS_PATH}.errorAlreadyAtShop`, {
                            count: ordersAlreadyAtShop.length,
                        })} ${Array.from(new Set(ordersAlreadyAtShop)).join(
                            ", ",
                        )}`,
                        {
                            persist: true,
                            variant: "error",
                            action: (key) => (
                                <CloseActionButton actionKey={key} />
                            ),
                        },
                    );
                }

                if (ordersAlreadyDelivered.length > 0) {
                    enqueueSnackbar(
                        `${t(`${TRANS_PATH}.errorAlreadyCompleted`, {
                            count: ordersAlreadyDelivered.length,
                        })} ${Array.from(
                            new Set(ordersAlreadyDelivered),
                        ).toString()}`,
                        {
                            persist: true,
                            variant: "error",
                            action: (key) => (
                                <CloseActionButton actionKey={key} />
                            ),
                        },
                    );
                }
                clearAllTags();
            } else {
                enqueueSnackbar(t(`${TRANS_PATH}.errorGeneral`), {
                    persist: true,
                    variant: "error",
                    action: (key) => <CloseActionButton actionKey={key} />,
                });
            }
        } catch (AxiosError) {
            console.error(AxiosError);
            enqueueSnackbar(t(`${TRANS_PATH}.errorConnection`), {
                persist: true,
                variant: "error",
                action: (key) => <CloseActionButton actionKey={key} />,
            });
        }
        setIsLoading(false);
        setConfirmationPopUpOpen(false);
    };

    const clearAllTags = () => {
        setTags([]);
    };

    const scanCancel = () => {
        setConfirmationPopUpOpen(false);
    };

    const handleConfirmDispatchError = async () => {
        const dispatchErrorsTags = tags
            .filter((tag) => tag.isDispatchError)
            .map((tag) => tag.value);
        setBadDestinationPopUp((prevState) => ({
            ...prevState,
            statusChangeIsLoading: true,
        }));
        try {
            await changeOrderStatusService(
                dispatchErrorsTags,
                "DISPATCH_ERROR",
                JSON.stringify({ scanned_by: storeId ? storeId : user.email }),
            );
            setTags((prevTags) =>
                prevTags.filter((tag) => !tag.isDispatchError),
            );
        } catch (e) {
            setBadDestinationPopUp((prevState) => ({
                ...prevState,
                statusChangeIsLoading: false,
            }));
            throw new Error(e);
        }
    };

    const delayRemoveTag = (tagId) =>
        setTimeout(() => {
            onRemove(tagId);
        }, 3000);

    const checkOrdersExistance = async (allTags) => {
        // We only keep the loading tags, others assume to already be checked
        const tagToCheck = allTags.filter((tag) => tag.isLoading);
        const orderIds = tagToCheck.map((tag) => tag.value);
        try {
            if (orderIds.length > 0) {
                const ordersIdsNotFound = [];
                const { data: results } = await refetch({ orderIds });
                for (let i = 0; i < orderIds.length; i += 1) {
                    //If the order don't exists in db, we warn the user and remove the tag
                    if (
                        !results.orders.find(
                            (order) =>
                                order.id === orderIds[i] ||
                                order.keybuild_id === orderIds[i],
                        )
                    ) {
                        ordersIdsNotFound.push(orderIds[i]);
                    }
                }
                setTags((tempTags) =>
                    tempTags.map((tag) => {
                        if (orderIds.includes(tag.value)) {
                            // we only modify orders that are on the call of this debounce
                            // it avoid deleting/modifying orders added during the call
                            const orderNotFound = tag.isValid
                                ? !ordersIdsNotFound.includes(tag.value)
                                : tag.isValid;

                            tag.isLoading = false;
                            tag.isValid = orderNotFound;
                            tag.timeoutId =
                                !tag.isValid && tag.timeoutId === null
                                    ? delayRemoveTag(tag.id)
                                    : tag.timeoutId;
                            tag.lastStatuses = null;
                            if (tag.isValid) {
                                //We add additional order information to valid tags
                                const orderData = results.orders.find(
                                    (order) =>
                                        order.id === tag.value ||
                                        order.keybuild_id === tag.value,
                                );
                                if (tag.value === orderData.keybuild_id) {
                                    tag.value = orderData.id;
                                }
                                //We add additional satisfaction information to valid tags
                                const satisfactionData =
                                    results.satisfactions.find(
                                        (satisfaction) =>
                                            satisfaction.order_id === tag.value,
                                    );

                                const customerFirstname =
                                    orderData?.customer_account?.firstname;
                                const customerLastname =
                                    orderData?.customer_account?.lastname;
                                tag.customerName =
                                    customerLastname &&
                                    customerFirstname &&
                                    `${customerFirstname} ${customerLastname}`;

                                tag.satisfactionAvg = satisfactionData?.n_avg;

                                // check if are dispatch error
                                tag.isDispatchError =
                                    orderData.store_destination_id &&
                                    orderData.store_destination_id !== storeId;

                                tag.destinationStore = {
                                    name: orderData?.destination_store?.name,
                                };
                                tag.origin = orderData?.origin;
                                tag.optimum = orderData?.optimum;

                                if (
                                    orderData &&
                                    orderData.order_status_changes &&
                                    orderData.order_status_changes[0]
                                ) {
                                    tag.lastStatuses =
                                        orderData.order_status_changes.map(
                                            (order_status_change) =>
                                                order_status_change.order_statuses_id,
                                        ) || [];
                                }
                            }
                        }
                        return tag;
                    }),
                );
                if (ordersIdsNotFound.length > 0) {
                    enqueueSnackbar(
                        t(`${TRANS_PATH}.errorMissing`, {
                            orderId: ordersIdsNotFound.toString(),
                        }),
                        {
                            persist: true,
                            variant: "error",
                            action: (key) => (
                                <CloseActionButton actionKey={key} />
                            ),
                        },
                    );
                }
            }
        } catch (checkOrderExistanceError) {
            console.error(checkOrderExistanceError);
            enqueueSnackbar(t(`${TRANS_PATH}.errorGeneral`), {
                persist: true,
                variant: "error",
                action: (key) => <CloseActionButton actionKey={key} />,
            });
            return false;
        }
    };

    const checkTagDuplicate = (tagsToCheck, tagToAdd) => {
        const tempTagToAdd = { ...tagToAdd };
        const duplicateTag = tagsToCheck.find(
            (tag) => tag.value === tempTagToAdd.value,
        );
        if (duplicateTag) {
            tempTagToAdd.isLoading = false;
            tempTagToAdd.isValid = false;
            tempTagToAdd.timeoutId = delayRemoveTag(tempTagToAdd.id);
            enqueueSnackbar(
                t(`${TRANS_PATH}.errorDuplicateScan`, {
                    orderId: tempTagToAdd.value,
                }),
                {
                    autoHideDuration: 15000,
                    variant: "warning",
                    action: (key) => <CloseActionButton actionKey={key} />,
                },
            );
        }
        return tempTagToAdd;
    };

    const onAdd = (tagToAdd) => {
        setTags((oldTags) => {
            let tempTag = { ...tagToAdd };
            tempTag.isLoading = true;
            tempTag.id = `${tempTag.value.replace("§", "-")}-${tags.length}`;
            tempTag.value = tempTag.value.replace("§", "-");

            if (tempTag.isValid) {
                tempTag = checkTagDuplicate(oldTags, tempTag);
            }
            const tempTags = [...oldTags, tempTag];
            debouncedCheckOrders(tempTags);
            return tempTags;
        });
    };

    const onRemove = (idToRemove) => {
        setTags((oldTags) => {
            const tempTags = oldTags.filter((tag) => tag.id !== idToRemove);
            debouncedCheckOrders(tempTags);
            return tempTags;
        });
    };

    return (
        <div>
            <TagsInput
                tags={tags}
                onAdd={onAdd}
                onRemove={onRemove}
                style={styles.tagInput}
                placeholder={t(`${TRANS_PATH}.placeHolder`)}
                autoFocus
            />
            <StyledTotalCommand>
                <Text fontWeight="bolder">
                    {t(`${TRANS_PATH}.orderCount`, { count: tags.length })}
                </Text>
            </StyledTotalCommand>
            <StyledGridContainer container spacing={3} alignItems="center">
                <Grid item xs={6}>
                    <StyledScanButton
                        variant="contained"
                        color="primary"
                        id="admission"
                        size="large"
                        disabled={isButtonDisabled}
                        onClick={buttonClickHandler}
                        startIcon={<StyledControlPoint />}
                    >
                        <StyledButtonTextWrapper>
                            <Text variant="h6" fontWeight="bolder">
                                {t(`${TRANS_PATH}.availableCtaPart1`)}
                            </Text>
                            <Text variant="h6">
                                {t(`${TRANS_PATH}.availableCtaPart2`)}
                            </Text>
                        </StyledButtonTextWrapper>
                    </StyledScanButton>
                </Grid>
                <Grid item xs={6}>
                    <StyledScanButton
                        variant="contained"
                        color="primary"
                        id="handOver"
                        size="large"
                        disabled={isButtonDisabled}
                        onClick={buttonClickHandler}
                        startIcon={<StyledFavorite />}
                    >
                        <StyledButtonTextWrapper>
                            <Text variant="h6" fontWeight="bolder">
                                {t(`${TRANS_PATH}.giveCtaPart1`)}
                            </Text>
                            <Text variant="h6">
                                {t(`${TRANS_PATH}.giveCtaPart2`)}
                            </Text>
                        </StyledButtonTextWrapper>
                    </StyledScanButton>
                </Grid>
            </StyledGridContainer>
            <ConfirmBadDestinationPopUp
                open={badDestinationPopUp.open}
                statusChangeIsLoading={
                    badDestinationPopUp.statusChangeIsLoading
                }
                handleConfirmDispatchError={handleConfirmDispatchError}
                close={() =>
                    setBadDestinationPopUp({
                        loading: false,
                        open: false,
                    })
                }
                scanConfirmationIsLoading={isLoading}
                onClickConfirmReception={() => buttonClickHandler(null, true)}
                orders={tags.filter((tag) => tag.isDispatchError)}
            />
            <HandoverPopUp
                open={errorOnHandOver}
                onPressConfirm={() => setErrorOnHandOver(false)}
                tags={tags}
            />
            <ConfirmationPopUp
                open={confirmationPopUpOpen}
                layout={confirmationLayout}
                isLoading={isLoading}
                tags={tags}
                onClickCancel={scanCancel}
                onClickConfirm={scanConfirmed}
            />
        </div>
    );
};

export default Scan;
