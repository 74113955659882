import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Slide from "@mui/material/Slide";
import { ThemeProvider } from "@mui/material/styles";

import Routes from "Routes";
import defaultTheme from "./theme";
import "./App.css";
import { gql, useApolloClient } from "@apollo/client";
import { AuthContext } from "AuthProvider";

const storeRoleRegex = /^Stores/;

const storeInfosQuery = gql`
    query storeIDWithMail($storeMail: String!) {
        storeWithEmail: stores(where: { email: { _eq: $storeMail } }) {
            id
        }
        stores(order_by: { name: asc }) {
            id
            name
        }
    }
`;

const storeInfoQuery = gql`
    query getStores {
        stores(order_by: { name: asc }) {
            id
            name
            country
            definitively_closed
        }
    }
`;

export default function App() {
    const client = useApolloClient();
    const {
        userRoles,
        user,
        setStoreId,
        storeId,
        authenticated,
        setIsLoadingAuthState,
        setStores,
        setStoreName,
    } = useContext(AuthContext);
    const [isLoadingStore, setIsLoadingStore] = useState(false);

    useEffect(() => {
        const getStoreInfos = async () => {
            setIsLoadingStore(true);
            const storeRole = userRoles.find((role) =>
                storeRoleRegex.test(role),
            );
            // storeRole = { test: true };

            if (storeRole) {
                const { data } = await client.query({
                    query: storeInfosQuery,
                    variables: {
                        storeMail: user.email?.replace(
                            "jimmyfairly-retail",
                            "jimmyfairly",
                        ),
                    },
                    // variables: { storeMail: "montorgueil@jimmyfairly.com" },
                });
                if (data.storeWithEmail && data.storeWithEmail?.length > 0) {
                    const tempStoreId = data.storeWithEmail[0].id;
                    const storeName = data?.stores?.find(
                        (store) => store.id === tempStoreId,
                    )?.name;
                    setStoreId(tempStoreId);
                    setStoreName(storeName || null);
                } else {
                    setStoreId(null);
                }
                setStores(data.stores);
            } else {
                setStoreId(null);
                const { data } = await client.query({
                    query: storeInfoQuery,
                });
                setStores(data.stores);
            }
            setIsLoadingAuthState(false);
            setIsLoadingStore(false);
        };
        if (authenticated && storeId === -1 && !isLoadingStore) {
            getStoreInfos();
        }
    }, [
        authenticated,
        client,
        isLoadingStore,
        setIsLoadingAuthState,
        setStoreId,
        setStoreName,
        setStores,
        storeId,
        user,
        userRoles,
    ]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <SnackbarProvider
                maxSnack={15}
                dense
                hideIconVariant
                preventDuplicate={false}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                TransitionComponent={Slide}
            >
                <Router>
                    <Routes />
                </Router>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
