import React from "react";
import { Grid, Paper } from "@mui/material";
import { formatDateLocales } from "services/toolbox";
import AdressGrid from "components/common/AdressGrid";
import Label from "components/common/InsideLabel";
import { getCarrierTrackingUrl } from "services/utils/carrier";
import { useTranslation } from "react-i18next";
import theme from "theme";

const styles = {
    root: {
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.mainGrey.main,
    },
    globalInfosGrid: {
        paddingBottom: theme.spacing(2),
    },
    detail: {
        fontSize: "1rem",
    },
    centeredText: {
        textAlign: "center",
    },
    leftText: {
        textAlign: "left",
    },
    chip: {
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    status: {
        backgroundColor: theme.palette.common.white,
    },
    info: {
        backgroundColor: theme.palette.common.white,
    },
    infoDeploy: {
        backgroundColor: theme.palette.info.light,
    },
};

/**
 *
 * ## OrderStatusPreview
 * Show utils infos from the order
 */
export default function OrderStatusPreview({ orderData, created_at }) {
    const { t, i18n } = useTranslation();
    const { t: statusTranslated } = useTranslation("orderStatus");
    const locale = i18n.language;

    const ShippingAdress = (
        <AdressGrid
            label={t("common.shippingAddress")}
            adress={orderData.shipping_address}
        />
    );
    const BillingAdress = (
        <AdressGrid
            label={t("common.billingAddress")}
            adress={orderData.billing_address}
        />
    );
    const _RenderStores = () => (
        <Grid
            container
            spacing={5}
            justifyContent="space-evenly"
            sx={{ textAlign: "center" }}
        >
            {orderData?.vendor_info?.firstname &&
                orderData?.vendor_info?.lastname && (
                    <Grid item xs={4}>
                        <Label
                            label={t("common.seller")}
                            value={`${orderData?.vendor_info?.firstname} ${orderData?.vendor_info?.lastname}`}
                            style={styles.info}
                            onExtend={[12, 12]}
                        />
                    </Grid>
                )}
            {orderData?.store?.name && (
                <Grid item xs={4}>
                    <Label
                        label={t("common.placeOfOrder")}
                        value={orderData.store.name}
                        style={styles.info}
                        onExtend={[12, 12]}
                    />
                </Grid>
            )}
            {orderData?.destination_store?.name && (
                <Grid item xs={4}>
                    <Label
                        label={t("common.destination")}
                        value={orderData?.destination_store?.name}
                        style={styles.info}
                        onExtend={[12, 12]}
                    />
                </Grid>
            )}
        </Grid>
    );
    const OrderQuickView = (
        <Paper sx={styles.paper}>
            <Grid
                sx={styles.globalInfosGrid}
                container
                spacing={1}
                justifyContent="space-evenly"
            >
                <Grid item xs={4}>
                    <Label
                        label={t("common.orderType")}
                        value={orderData.type}
                        style={styles.status}
                        onExtend={[5, 7]}
                    />
                    <Label
                        label={t("common.orderDate")}
                        value={formatDateLocales(created_at, locale, "P")}
                        style={styles.status}
                        onExtend={[5, 7]}
                    />
                    <Label
                        label={t("common.priceAllTaxes")}
                        value={new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: orderData.currency,
                        }).format(orderData.flat_amount_including_tax)}
                        style={styles.status}
                        onExtend={[5, 7]}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Label
                        label={t("common.updatedOn")}
                        value={formatDateLocales(
                            orderData.order_status_changes.length
                                ? orderData.order_status_changes[0].created_at
                                : orderData.created_at,
                            locale,
                            "P",
                        )}
                        style={styles.status}
                        onExtend={[5, 7]}
                    />
                    <Label
                        label={t("common.orderStatus")}
                        value={
                            orderData.global_status_detail &&
                            statusTranslated(
                                `${orderData.global_status_detail.id}.label`,
                            )
                        }
                        style={styles.status}
                        onExtend={[5, 7]}
                    />
                    <Label
                        label={t("common.tracking")}
                        value={{
                            type: "url",
                            href: getCarrierTrackingUrl(
                                orderData.carrier_type,
                                orderData.tracking_number,
                            ),
                            text: orderData.tracking_number,
                        }}
                        style={styles.status}
                        onExtend={[5, 7]}
                    />
                </Grid>
            </Grid>
            <hr />
            {orderData?.store?.name &&
                orderData?.destination_store?.name &&
                _RenderStores()}
        </Paper>
    );

    return (
        <>
            <Grid sx={styles.root} container spacing={2}>
                <Grid item xs={12}>
                    {OrderQuickView}
                </Grid>
                <Grid item xs={6}>
                    {BillingAdress}
                </Grid>
                <Grid item xs={6}>
                    {ShippingAdress}
                </Grid>
            </Grid>
        </>
    );
}
