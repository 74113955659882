import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableCell, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SortIcon from "@mui/icons-material/Sort";
import { styled } from "@mui/material/styles";

const StyledTableHead = styled(TableHead)(({ style }) => style);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "20px 6px 20px 5px",
    textAlign: "center",
    verticalAlign: "top",
    width: "11%",
    maxWidth: "12%",
    backgroundColor: theme.palette.white.lotion,
}));

const StyledTableCellText = styled(Typography)(() => ({
    marginRight: 3,
    fontWeight: "bolder",
}));

const StyledTextCellContainer = styled("div")(() => ({
    fontWeight: 600,
    display: "inline-flex",
}));

const HeaderSimple = ({ headerGroups, style }) => {
    const _renderSortIcon = (column) => {
        if (column.isSorted) {
            if (column.isSortedDesc) {
                return <ArrowDownwardIcon />;
            }
            return <ArrowUpwardIcon />;
        }
        return <SortIcon />;
    };

    return (
        <StyledTableHead style={style}>
            {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <StyledTableCell key={column.Header}>
                            <StyledTextCellContainer
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                )}
                            >
                                <StyledTableCellText variant="subtitle2">
                                    {column.render("Header")}
                                </StyledTableCellText>
                                {column.canSort && _renderSortIcon(column)}
                            </StyledTextCellContainer>
                            <div>
                                {column.canFilter
                                    ? column.render("Filter")
                                    : null}
                            </div>
                        </StyledTableCell>
                    ))}
                </TableRow>
            ))}
        </StyledTableHead>
    );
};

export default HeaderSimple;
