/**
 *
 * @param {Object} param productDetail
 * @returns {String} Product type
 */

const getProductType = (productLine) => {
    if (productLine.accessory) {
        return "ACCESSORY";
    } else if (productLine.frame && productLine.lens_pack) {
        return "PACK";
    } else if (productLine.frame && !productLine.lens_pack) {
        return "FRAME";
    } else if (!productLine.frame && productLine.lens_pack) {
        return "LENS";
    }
};

/**
 *
 * @param {Object} param productDetail
 * @returns {Object} { type, name, id, label }
 */
const getProductTypeAndName = (productLine) => {
    if (productLine.accessory) {
        return {
            type: "ACCESSOIRE",
            name: productLine.accessory.label,
            id: productLine.accessory.id,
        };
    } else if (productLine.frame && productLine.lens_pack) {
        // let lens_pack_id = "nt";
        if (
            productLine.lens_pack.id &&
            productLine.lens_pack.id === "no-glass"
        ) {
            return {
                type: "SOLAIRE",
                name: productLine.frame.label,
                id: productLine.frame.id,
                label: "frame",
            };
        } else
            return {
                type: "PACK",
                label: "pack",
                name: productLine.lens_pack.label,
                id: productLine.lens_pack.id,
            };
    } else if (productLine.frame && !productLine.lens_pack) {
        return {
            type: "MONTURE",
            name: productLine.frame.label,
            id: productLine.frame.id,
            label: "frame",
        };
    } else if (!productLine.frame && productLine.lens_pack) {
        return {
            type: "VERRES",
            name: productLine.lens_pack.label,
            id: productLine.lens_pack.id ? productLine.lens_pack.id : "nt",
            label: "pack",
        };
    }
};

/**
 *
 * @param {String} param lens_packs.label
 * @returns {String} "TEINTÉE" / "NON TEINTÉE"
 */

const defineTint = (param, type) => {
    let tint = "";

    if (param === "verres-seuls") {
        tint = "NON TEINTÉE";
    } else {
        if (type === "frame") {
            tint = param[param.length - 1] === "s" ? "TEINTÉE" : "NON TEINTÉE";
        } else if (type === "pack") {
            const lensInfo = param.split("-")[0];
            tint = lensInfo[0] === "t" ? "TEINTÉE" : "NON TEINTÉE";
        }
    }
    return tint;
};

/**
 *
 * @param {String} param lens_packs.label
 * @returns {String} correction type
 */
const defineCorrection = (param) => {
    let correction = "";
    const lensInfo = param.split("-")[0];
    let correctionType = lensInfo[0] === "t" ? lensInfo[1] : lensInfo[2];
    if (param === "no-glass") {
        correctionType = param;
    }

    switch (correctionType) {
        case "u":
            correction = "UNIFOCALE";
            break;
        case "no-glass":
            correction = "-";
            break;
        case "n":
            correction = "NEUTRE";
            break;
        case "p":
            correction = "PROGRESSIVE";
            break;
        case "d":
            correction = "DEGRESSIVE";
            break;
        default:
            correction = "UNKNOW";
            break;
    }

    return correction;
};

module.exports = {
    getProductType,
    getProductTypeAndName,
    defineTint,
    defineCorrection,
};
