import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from "AuthProvider";
import InsideLoader from "components/common/InsideLoader";
import Layout from "components/Layout";

const Authorize = ({ authorizedRoles, component: Component, ...options }) => {
    const { authenticated, isLoadingAuthState, userRoles } =
        useContext(AuthContext);

    const isAccessGranted = authorizedRoles.some(
        (authorizedRole) =>
            userRoles.includes(authorizedRole) || authorizedRole.includes("*"),
    );

    if (isLoadingAuthState) {
        return <InsideLoader />;
    }
    if (!authenticated) {
        return <Redirect to="/login" />;
    }

    if (!isAccessGranted) {
        return <Redirect to="/unauthorized" />;
    }
    return (
        <Route
            {...options}
            render={(routeProps) => (
                <Layout>
                    <Component {...routeProps} />
                </Layout>
            )}
        />
    );
};
export default Authorize;
