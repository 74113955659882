const carrierUrl = {
    CIBLEX: "http://extranet.ciblex.fr/extranet/client/corps.php?module=colis&colis=",
    COLISSIMO: "https://www.laposte.fr/outils/suivre-vos-envois?code=",
    FEDEX: "https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=",
    TNT: "https://www.tnt.com/express/fr_fr/site/outils-expedition/suivi.html?searchType=con&cons=",
};

const getCarrierTrackingUrl = (carrier, trackingNumber) =>
    `${carrierUrl[carrier]}${trackingNumber}`;

module.exports = { getCarrierTrackingUrl };
