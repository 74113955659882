import React from "react";
import ReactDOM from "react-dom";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./AuthProvider";
import { CssBaseline } from "@mui/material";
import "./services/i18n";

// if (process.env.NODE_ENV === "production") {
//     Sentry.init({
//         dsn: "https://7cc22c428f0448a883180a257cef6648@o482329.ingest.sentry.io/5532407",
//         integrations: [new Integrations.BrowserTracing()],
//         tracesSampleRate: 1.0,
//     });
// }

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <CssBaseline />
            <App />
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
