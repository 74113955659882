/**
 * This component allow the developper to manage
 * routes and items displayed in the vertical menu
 */

import React, { useEffect, useContext } from "react";
import { AuthContext } from "AuthProvider";
import { Route, Switch, Redirect } from "react-router-dom";

import CustomersPage from "pages/Customers";
import CustomerDetailsPage from "pages/CustomerDetails";
import OrdersPage from "pages/Orders";
import OrderDetailsPage from "pages/OrderDetails";
import Unauthorized from "pages/Unauthorized";
import NotFound from "pages/NotFound";
import LoginPage from "pages/Login";
import Stickers from "pages/Stickers";
import Home from "pages/Home";
import Satisfaction from "../pages/Satisfaction";
// import Stock from "pages/Stock";
import OrderReceipt from "pages/Receipt";
import InsideLoader from "components/common/InsideLoader";

import Authorize from "Routes/Authorize";

import "App.css";
import { useTranslation } from "react-i18next";

// This variable allow the developper to manage
// the items displayed in the vertical menu
export const routesFn = (t) => [
    {
        text: t("common.customer_plural"),
        icon: "account_circle",
        path: "/",
        menu: false,
        home: [],
        roles: ["*"],
        component: Home,
    },
    {
        text: t("common.customer_plural"),
        icon: "account_circle",
        path: "/customers",
        menu: true,
        home: [],
        roles: [
            "Stores Paris",
            "Stores Province",
            "Stores UK",
            "Stores BE",
            "Head quarters",
            "Inside Tech",
            "Inside XP",
            "Inside DR",
        ],
        component: CustomersPage,
    },
    {
        text: null,
        icon: null,
        path: "/customers/:id",
        menu: false,
        home: [],
        roles: [
            "Stores Paris",
            "Stores Province",
            "Stores UK",
            "Stores BE",
            "Head quarters",
            "Inside Tech",
            "Inside XP",
            "Inside DR",
        ],
        component: CustomerDetailsPage,
    },
    {
        text: t("common.order_plural"),
        icon: "shopping_cart_icon",
        path: "/orders",
        menu: true,
        home: [
            "Stores Paris",
            "Stores Province",
            "Stores UK",
            "Stores BE",
            "Head quarters",
            "Inside Tech",
            "Inside XP",
            "Inside DR",
        ],
        roles: [
            "Stores Paris",
            "Stores Province",
            "Stores UK",
            "Stores BE",
            "Head quarters",
            "Inside Tech",
            "Inside XP",
            "Inside DR",
        ],
        component: OrdersPage,
    },
    {
        text: null,
        icon: null,
        path: "/orders/:id",
        menu: false,
        home: [],
        roles: [
            "Stores Paris",
            "Stores Province",
            "Stores UK",
            "Stores BE",
            "Head quarters",
            "Inside Tech",
            "Inside XP",
            "Inside DR",
        ],
        component: OrderDetailsPage,
    },
    {
        text: t("common.label_plural"),
        icon: "grid_on",
        path: "/inventory",
        menu: true,
        home: [],
        roles: [
            "Stores Paris",
            "Stores Province",
            "Stores UK",
            "Stores BE",
            "Head quarters",
            "Inside Tech",
            "Inside DR",
        ],
        component: Stickers,
    },
    {
        text: t("common.delivery_plural"),
        icon: "local_shipping",
        path: "/receipt",
        menu: true,
        home: [],
        roles: [
            "Stores Paris",
            "Stores Province",
            "Stores UK",
            "Stores BE",
            "Inside Tech",
            "Inside XP",
            "Inside DR",
            "Inside Logistics",
        ],
        component: OrderReceipt,
    },
    {
        text: t("common.satisfaction"),
        icon: "thumbs_up_down",
        path: "/satisfaction",
        menu: true,
        home: [],
        roles: [
            "Stores Paris",
            "Stores Province",
            "Stores UK",
            "Stores BE",
            "Head quarters",
            "Inside Tech",
            "Inside XP",
            "Inside DR",
        ],
        component: Satisfaction,
    },
    // Disabled, waiting for product confirmation
    // {
    //     text: t("common.stock"),
    //     icon: "StockIcon",
    //     path: "/stock",
    //     menu: true,
    //     home: [],
    //     roles: [
    //         "Stores Paris",
    //         "Stores Province",
    //         "Stores UK",
    //         "Stores BE",
    //         "Inside Tech",
    //         "Inside XP",
    //     ],
    //     component: Stock,
    // },
];

const Routes = () => {
    const { welcomeAnimation, setWelcomeAnimation } = useContext(AuthContext);
    const { t, ready } = useTranslation("", { useSuspense: false });

    useEffect(() => {
        if (welcomeAnimation) {
            setTimeout(() => setWelcomeAnimation(false), 2100);
        }
    }, [welcomeAnimation, setWelcomeAnimation]);

    if (!ready) {
        return <InsideLoader />;
    }

    const routes = routesFn(t);

    //CONDITIONNAL RENDERING FOR THE ANIMATION ON SIGN IN
    return welcomeAnimation ? (
        <div className="start-layer">
            <div className="text">
                <div className="bg"></div>
                <div className="line line1">INSIDE</div>
                <div className="line line2">JIMMY</div>
                <div className="line line3">FAIRLY</div>
            </div>
        </div>
    ) : (
        <Switch>
            <Route exact path="/login" component={LoginPage} />
            {routes.map((route, index) => (
                <Authorize
                    key={`route${index}`}
                    authorizedRoles={route.roles}
                    exact
                    path={route.path}
                    component={route.component}
                ></Authorize>
            ))}
            <Route exact path="/unauthorized" component={Unauthorized} />
            <Route exact path="/not-found" component={NotFound} />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
