import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    ExitToAppOutlined,
    Settings as SettingsIcon,
} from "@mui/icons-material/";
import { Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuthContext } from "AuthProvider";
import Text from "components/Text";
import theme from "theme";

const styles = {
    icon: {
        cursor: "pointer",
        marginRight: 1,
    },
    menuItemTypo: {
        marginRight: theme.spacing(4),
        color: theme.palette.grey.granite,
    },
};

const SettingsMenu = () => {
    const { t } = useTranslation();
    const { logout } = useContext(AuthContext);
    const history = useHistory();
    const [displayMenu, setDisplayMenu] = useState(false);
    const refElem = useRef(0);

    const signOut = async (event) => {
        event.preventDefault();
        await logout();
        history.push("/login");
    };

    const toggleMenu = () => setDisplayMenu((prevstate) => !prevstate);

    return (
        <>
            <SettingsIcon
                ref={refElem}
                fontSize={"large"}
                onClick={toggleMenu}
                sx={styles.icon}
            />
            <Menu
                id="popover"
                anchorEl={refElem.current}
                open={displayMenu}
                onClose={toggleMenu}
            >
                <MenuItem onClick={signOut}>
                    <ExitToAppOutlined fontSize="small" />
                    <Text style={styles.menuItemTypo}>
                        {t("common.disconnect")}
                    </Text>
                </MenuItem>
            </Menu>
        </>
    );
};

export default SettingsMenu;
