import React from "react";
import styled from "@mui/styles/styled";
import { CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
}));

const InsideLoader = () => {
    const { t, ready } = useTranslation("", { useSuspense: false });
    return (
        <StyledContainer>
            <CircularProgress typographysize={100} sx={{ marginBottom: 20 }} />
            {ready && <Typography>{`${t("common.loading")}...`}</Typography>}
        </StyledContainer>
    );
};

export default InsideLoader;
