import React from "react";
import { Button, Box, Typography, Grid, Fade } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Arrow from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";

import AdressGrid from "components/common/AdressGrid";
import { formatDateLocales } from "services/toolbox";
import { useTranslation } from "react-i18next";
import theme from "theme";

const CustomerInfos = ({ orderData }) => {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    const { customer_account, shipping_address, billing_address } = orderData;

    return (
        <>
            <Box display="flex" alignItems="center">
                <AccountCircle style={{ paddingRight: 5 }} fontSize="large" />
                <Typography variant="h5">
                    {customer_account.firstname} {customer_account.lastname}
                </Typography>
                <Button
                    variant="contained"
                    className={{
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "48px",
                        color: theme.palette.secondary.main,
                        marginLeft: 15,
                    }}
                    endIcon={<Arrow />}
                    onClick={() => {
                        history.push(`/customers/${customer_account.id}`);
                    }}
                >
                    {t("common.seeCustomerAccount")}
                </Button>
            </Box>
            <Fade in={true} timeout={1000}>
                <Grid
                    className={{
                        marginTop: theme.spacing(4),
                        marginBottom: theme.spacing(4),
                    }}
                >
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography>{`${t(
                                "common.birthdate",
                            )}:`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                {formatDateLocales(
                                    customer_account.birthday,
                                    locale,
                                    "P",
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography>Email: </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{customer_account.email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography>{t("common.phone")}:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                {customer_account.phone_number}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
            <Box display="flex" alignItems="stretch">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AdressGrid
                            extend
                            infoBoxHeight={195}
                            label={t("common.billingAddress")}
                            adress={billing_address}
                            fullInfos={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AdressGrid
                            extend
                            infoBoxHeight={195}
                            label={t("common.shippingAddress")}
                            adress={shipping_address}
                            fullInfos={true}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
export default CustomerInfos;
