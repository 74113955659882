import React, { useRef, useState } from "react";
import styled from "@mui/styles/styled";
import { useHistory } from "react-router-dom";
import { KeyboardArrowDown, Check as CheckIcon } from "@mui/icons-material/";
import { Menu, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import localizationItems from "services/i18n/localizationItems";
import { useTranslation } from "react-i18next";
import theme from "theme";

const styles = {
    iconMenuItem: {
        marginLeft: theme.spacing(2),
    },
    menuItemTypo: {
        color: theme.palette.grey.granite,
    },
};

const StyledIconContainer = styled("div")(({ theme }) => ({
    cursor: "pointer",
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
}));

const LocalizationMenu = () => {
    const { i18n } = useTranslation();
    const refElem = useRef(0);
    const history = useHistory();
    const [displayMenu, setDisplayMenu] = useState(false);
    const currentMarket = i18n.language;
    const currentLocalizationTitle =
        localizationItems.find((locale) => locale.market === currentMarket)
            ?.title || "FR";

    const toggleMenu = () => setDisplayMenu((prevstate) => !prevstate);

    const changeLanguage = (market) =>
        i18n.changeLanguage(market).then(() => history.go(0));

    return (
        <>
            <StyledIconContainer ref={refElem}>
                <Typography variant="h6" noWrap onClick={toggleMenu}>
                    <Box fontWeight="fontWeightBold" m={1}>
                        {currentLocalizationTitle}
                    </Box>
                </Typography>
                <KeyboardArrowDown onClick={toggleMenu} />
            </StyledIconContainer>
            <Menu
                id="popover"
                anchorEl={refElem.current}
                sx={styles.rootMenu}
                open={displayMenu}
                onClose={toggleMenu}
            >
                {localizationItems.map((locale) => (
                    <MenuItem
                        key={locale.market}
                        onClick={() => changeLanguage(locale.market)}
                    >
                        <Typography sx={styles.menuItemTypo}>
                            {locale.description}
                        </Typography>
                        {locale.market === currentMarket && (
                            <CheckIcon sx={styles.iconMenuItem} />
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default LocalizationMenu;
