import React from "react";
import VitaleOk from "assets/vitaleCard/ok.svg";
import VitaleFail from "assets/vitaleCard/fail.svg";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

export default function VitalCardLogo({
    className,
    transmissionStatus,
    style,
}) {
    const { t, i18n } = useTranslation();
    if (i18n.language !== "fr-FR") {
        return null;
    }

    switch (transmissionStatus) {
        case "valid":
            return (
                <Tooltip title={t("common.teletransmissionOk")}>
                    <img
                        className={className ? className : null}
                        style={style ? style : null}
                        src={VitaleOk}
                        alt="VitaleOk svg"
                    />
                </Tooltip>
            );
        case "invalid":
        case "no":
        case "failed":
            return (
                <Tooltip title={t("common.teletransmissionFailed")}>
                    <img
                        className={className ? className : null}
                        style={style ? style : null}
                        src={VitaleFail}
                        alt="VitaleFail svg"
                    />
                </Tooltip>
            );
        default:
            return null;
    }
}
