import React, { useState } from "react";
import { AppBar, Tab, Tabs, Typography } from "@mui/material";
import TabPanel from "./TabPanel";
import { styled } from "@mui/material/styles";

const StyledAppBar = styled(AppBar)(() => ({
    background: "transparent",
    boxShadow: "none",
}));

const styles = {
    labelActivated: {
        fontWeight: "bold",
    },
    label: {},
};

const AppBarInside = ({ content, onChange, value, centered }) => {
    const [currentValue, setCurrentValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        if (onChange) {
            onChange(event, newValue);
        }
        setCurrentValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const tabIndexValue = value || currentValue;

    return (
        <div>
            <StyledAppBar position="static">
                <Tabs
                    value={tabIndexValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered={centered}
                >
                    {content.map((item, index) => (
                        <Tab
                            key={item.label}
                            label={
                                <Typography
                                    sx={
                                        tabIndexValue === index
                                            ? styles.labelActivated
                                            : styles.label
                                    }
                                >
                                    {item.label}
                                </Typography>
                            }
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </StyledAppBar>
            {content.map((item, index) => (
                <TabPanel
                    key={item.label}
                    value={value || currentValue}
                    index={index}
                >
                    {item.component}
                </TabPanel>
            ))}
        </div>
    );
};

export default AppBarInside;
