import { FilterDateRange, SelectListFilter } from "components/Table/Filters";
import {
    AccountInfoCell,
    DateCell,
    LinkCell,
    StatusCellComponent,
} from "components/Table/CellComponents";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { getStoreNameWithId } from "services/toolbox";
import { getCarrierTrackingUrl } from "services/utils/carrier";
import React from "react";

import { TRANS_PATH } from "../constants";
import ORDER_TYPES from "constants/order-types";
import { formatObjToSelectListArray } from "components/Table/Filters/SelectListFilter";
import Chip from "@mui/material/Chip";
import DoubleSelectListFilter from "components/Table/Filters/DoubleSelectListFilter";
import VitaleCardLogo from "components/common/VitaleCardLogo";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import { Typography } from "@mui/material";

const teletransmissionStatusList = [
    { id: "valid", name: "Oui" },
    { id: "invalid", name: "Non" },
];

const makeColumns = ({
    stores,
    t,
    statusTranslated,
    locale,
    destinations_stores,
    storesIsRefetching,
}) => {
    const typeFilterList = formatObjToSelectListArray(ORDER_TYPES);
    const careElement = { id: "care", name: t("common.care") };
    const multiElement = { id: "VM", name: t("common.multi").toUpperCase() };
    const statusFilterList = formatObjToSelectListArray(statusTranslated);
    const columns = [
        {
            Header: t(`${TRANS_PATH}.orderId`),
            accessor: "ids",
            Cell: ({ value: { mainId, secondaryId } }) => (
                <>
                    <Typography variant="subtitle2">{mainId}</Typography>
                    {secondaryId && (
                        <Typography variant="body2">{secondaryId}</Typography>
                    )}
                </>
            ),
            disableFilters: true,
        },
        {
            Header: t("common.date"),
            accessor: "created_at",
            Cell: ({ value }) => <DateCell value={value} locale={locale} />,
            Filter: (props) => <FilterDateRange {...props} />,
        },
        {
            Header: t(`${TRANS_PATH}.orderType`),
            accessor: "type",
            Cell: ({ row }) => (
                <div style={{ display: "grid" }}>
                    {row.original.type && (
                        <Chip
                            label={row.original.type}
                            size="small"
                            color={"default"}
                            style={{
                                marginTop: 3,
                                marginLeft: 30,
                                marginRight: 30,
                            }}
                        />
                    )}
                    {row.original.is_care_service && (
                        <Chip
                            label={t("common.care")}
                            size="small"
                            style={{
                                border: `2px solid #2602FF`,
                                backgroundColor: `#2602FF20`,
                                color: "#2602FF",
                                fontWeight: "bold",
                                marginTop: 3,
                                marginLeft: 30,
                                marginRight: 30,
                            }}
                            icon={
                                <HealthAndSafetyIcon
                                    style={{ color: "#2602FF" }}
                                />
                            }
                        />
                    )}
                    {row.original.is_multi &&
                        row.original.origin.startsWith("WEB") && (
                            <Chip
                                label={`${row.original.order_product_lines_aggregate.aggregate.count} articles`}
                                size="small"
                                style={{
                                    border: `2px solid #f49804`,
                                    backgroundColor: `#f4e9d7`,
                                    color: "#f49804",
                                    fontWeight: "bold",
                                    marginTop: 3,
                                    marginLeft: 30,
                                    marginRight: 30,
                                }}
                            />
                        )}
                </div>
            ),
            Filter: (props) => (
                <SelectListFilter
                    {...props}
                    list={[...typeFilterList, multiElement, careElement]}
                    style={{ padding: "0px 15px" }}
                />
            ),
            disableSortBy: true,
        },
        {
            Header: t("common.customer"),
            accessor: "customer_account.lastname",
            Cell: ({ row }) => (
                <AccountInfoCell
                    firstname={row.original?.customer_account?.firstname}
                    lastname={row.original?.customer_account?.lastname}
                    email={row.original?.customer_account?.email}
                />
            ),
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: t("common.store"),
            accessor: "store.id",
            Cell: ({ row }) => (
                <>
                    <Chip
                        color={"default"}
                        label={getStoreNameWithId(
                            stores,
                            row?.original?.store?.id,
                        ).toUpperCase()}
                        size="small"
                        style={{ width: "90%" }}
                        icon={<ShoppingCartIcon />}
                    />
                    {row.original?.destination_store?.id && (
                        <Chip
                            color={"default"}
                            label={getStoreNameWithId(
                                stores,
                                row.original.destination_store.id,
                            ).toUpperCase()}
                            size="small"
                            style={{ width: "90%", marginTop: 3 }}
                            icon={<LocalShippingIcon />}
                        />
                    )}
                </>
            ),
            Filter: (props) => (
                <DoubleSelectListFilter
                    {...props}
                    list={stores}
                    secondList={[...destinations_stores].sort((a, b) =>
                        a.name > b.name ? 1 : -1,
                    )}
                    listFilterId={"store"}
                    secondListFilterId={"destination_store"}
                    listPlaceHolder={t(
                        `${TRANS_PATH}.storeOriginFilterPlaceHolder`,
                    )}
                    secondListPlaceHolder={t(
                        `${TRANS_PATH}.storeDestinationFilterPlaceHolder`,
                    )}
                    disableSecondList={
                        storesIsRefetching ||
                        !destinations_stores ||
                        !destinations_stores.length
                    }
                />
            ),
            disableSortBy: true,
        },
        {
            Header: t("common.priceAllTaxes"),
            accessor: "flat_amount_including_tax",
            Cell: ({ row, value }) =>
                row.original?.currency &&
                new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: row.original?.currency,
                }).format(value),
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: t("common.orderStatus"),
            accessor: "global_status_id",
            Cell: ({ row }) =>
                row.original?.global_status_id && (
                    <StatusCellComponent
                        stores={stores}
                        label={t(
                            `orderStatus:${row.original?.global_status_id}.label`,
                        )}
                        // dateText={formatDateLocales(
                        //     row.original?.lastStatus[0]?.created_at,
                        //     locale,
                        //     "P",
                        // )}
                        orderType={row.original?.type}
                        orderStatus={row.original?.global_status_id}
                        // date={row.original?.lastStatus[0]?.created_at}
                        // comment={row.original?.lastStatus[0]?.comment}
                    />
                ),
            Filter: (props) => (
                <SelectListFilter {...props} list={statusFilterList} />
            ),
            disableSortBy: true,
        },
        {
            Header: t("common.tracking"),
            accessor: "tracking_number",
            sorting: false,
            Cell: ({ row }) =>
                row.original?.tracking_number &&
                row.original?.carrier_type && (
                    <LinkCell
                        text={row.original?.tracking_number}
                        url={getCarrierTrackingUrl(
                            row.original?.carrier_type,
                            row.original?.tracking_number,
                        )}
                    />
                ),
            disableFilters: true,
            disableSortBy: true,
        },
    ];
    if (locale === "fr-FR") {
        columns.push({
            Header: "Télétransmission",
            id: "teletransmission",
            accessor: (row) => {
                if (row.optimum) {
                    return row.optimum.transmission_status;
                }
                return "no";
            },
            sorting: true,
            Cell: ({ value }) => (
                <VitaleCardLogo
                    style={{ width: "30px", height: "auto" }}
                    transmissionStatus={value}
                />
            ),
            Filter: (props) => (
                <SelectListFilter
                    {...props}
                    list={teletransmissionStatusList}
                />
            ),
            disableSortBy: true,
        });
    }
    return columns;
};

export default makeColumns;
