import React, { useEffect, useState } from "react";
import styled from "@mui/styles/styled";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import TableToolbar from "components/Table/TableToolbar";
import DatePickers from "components/common/DatePickers";
import { useTranslation } from "react-i18next";
import theme from "theme";

const styles = {
    autocomplete: {
        width: "40%",
        marginLeft: theme.spacing(3),
    },
    datePickers: {
        marginLeft: theme.spacing(3),
    },
    tableToolBar: {
        border: "solid",
        width: "40%",
        height: 45,
        alignItems: "center",
        borderColor: theme.palette.grey.granite,
        borderWidth: theme.border.width.thinner,
        borderRadius: theme.border.radius.button,
    },
    tableToolBarInput: {
        display: "flex",
        width: "95%",
        height: "unset",
        marginLeft: 0,
    },
};

const StyledContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}));

const HeaderTableFilters = ({
    onSearchChange,
    onDateChange,
    onFilterChange,
    values,
}) => {
    const [filters, setFilters] = useState([{}]);
    const { t } = useTranslation();

    useEffect(() => {
        setFilters([
            {
                text: `${t("pages.satisfaction.promoter")} (9-10)`,
                value: "promoter",
            },
            {
                text: `${t("pages.satisfaction.passive")} (7-8)`,
                value: "passive",
            },
            {
                text: `${t("pages.satisfaction.detractor")} (0-6)`,
                value: "detractor",
            },
            {
                text: t("pages.satisfaction.withComment"),
                value: "withComment",
            },
            {
                text: t("pages.satisfaction.withoutComment"),
                value: "withoutComment",
            },
            {
                text: t("pages.satisfaction.after_ordering"),
                value: "after_ordering",
            },
            {
                text: t("pages.satisfaction.after_3_months"),
                value: "after_3_months",
            },
            // {
            //     text: t("pages.satisfaction.reportPopUp.waitingReportStatus"),
            //     value: REPORT_STATUS.WAITING,
            // },
            // {
            //     text: t("pages.satisfaction.reportPopUp.toContactReportStatus"),
            //     value: REPORT_STATUS.TO_CONTACT,
            // },
            // {
            //     text: t(
            //         "pages.satisfaction.reportPopUp.doNotContactReportStatus",
            //     ),
            //     value: REPORT_STATUS.TO_NOT_CONTACT,
            // },
            // {
            //     text: t("pages.satisfaction.reportPopUp.resolvedReportStatus"),
            //     value: REPORT_STATUS.RESOLVED,
            // },
        ]);
    }, [t]);
    const check = (valueToCheck) =>
        values.find(
            (filter) =>
                filter.value === valueToCheck ||
                filter.value.includes(valueToCheck) ||
                (filter.id === "comment" &&
                    (filter.value.includes(true) ||
                        filter.value.includes("true")) &&
                    valueToCheck === "withComment") ||
                (filter.id === "comment" &&
                    (filter.value.includes(false) ||
                        filter.value.includes("false")) &&
                    valueToCheck === "withoutComment"),
        );

    const createdAtValue =
        values?.find((value) => value.id === "created_at")?.value || [];
    return (
        <StyledContainer>
            <TableToolbar
                fontSize="large"
                onChange={(value) => {
                    onSearchChange(value);
                }}
                style={styles.tableToolBar}
                inputStyle={styles.tableToolBarInput}
                disableUnderline
            />
            <DatePickers
                onChange={(value) => {
                    onDateChange(value);
                }}
                filterValue={createdAtValue}
                style={styles.datePickers}
            />
            <Autocomplete
                multiple
                id="tags-standard"
                options={filters}
                disableCloseOnSelect
                sx={styles.autocomplete}
                onChange={(event, newValue) => {
                    onFilterChange(newValue);
                }}
                getOptionLabel={(option) => option.text}
                value={filters.filter((filter) => check(filter.value))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Filtres"
                        placeholder="Filtres"
                    />
                )}
            />
        </StyledContainer>
    );
};

export default HeaderTableFilters;
