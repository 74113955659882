import { Grid, Typography, Paper, Box } from "@mui/material";
import { LinkCell } from "components/Table/CellComponents";
import { getCarrierTrackingUrl } from "services/utils/carrier";
import theme from "theme";

const ProductInfosDetailsHeader = ({
    order,
    RenderDownloadPdfList,
    opticalDataOrder,
    isOrderWeb,
}) => {
    return (
        <Grid item sx={{ textAlign: "center", mb: "1em" }}>
            <Paper
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    padding: "1em",
                    backgroundColor: theme.palette.mainGrey.main,
                }}
            >
                <Box>
                    <Typography variant="h5">
                        {order.id ? order.id : order.order_id}
                    </Typography>
                    {order.carrier_type && order.tracking_number && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography sx={{ marginRight: ".5em" }}>
                                Tracking :
                            </Typography>
                            <LinkCell
                                text={order.tracking_number}
                                url={getCarrierTrackingUrl(
                                    order.carrier_type,
                                    order.tracking_number,
                                )}
                            />
                        </Box>
                    )}
                </Box>
                <Box sx={{ position: "absolute", right: "1em" }}>
                    <RenderDownloadPdfList
                        opticalDataInfo={
                            isOrderWeb
                                ? {
                                      parentId: Math.floor(
                                          opticalDataOrder?.parendId.replace(
                                              /\D/g,
                                              "",
                                          ) / 10,
                                      ),
                                      childId:
                                          opticalDataOrder?.childId.replace(
                                              /\D/g,
                                              "",
                                          ),
                                      prescription:
                                          opticalDataOrder?.prescription,
                                      country: opticalDataOrder?.country,
                                  }
                                : ""
                        }
                    />
                </Box>
            </Paper>
        </Grid>
    );
};

export default ProductInfosDetailsHeader;
