import React from "react";
import TableRow from "@mui/material/TableRow";
import { TableCell, Typography } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledBodyTableCell = styled(TableCell, {
    shouldForwardProp: (prop) =>
        ![
            "theme",
            "indexOfCurrentCell",
            "lengthOfAllCellsArray",
            "disableRowClick",
            "style",
            "rowIndex",
        ].includes(prop),
})(
    ({
        theme,
        indexOfCurrentCell,
        lengthOfAllCellsArray,
        disableRowClick,
        style,
        rowIndex,
    }) => {
        const rowBackgroundColor =
            rowIndex % 2 === 0
                ? { backgroundColor: "rgb(196, 196, 196, 15%)" }
                : {};
        const getBodyCellStyle = () => {
            if (indexOfCurrentCell === 0) {
                return {
                    borderLeftStyle: "solid",
                    textAlign: "center",
                    borderTopLeftRadius: theme.border.radius.list,
                    borderBottomLeftRadius: theme.border.radius.list,
                    borderBottom: "solid",
                    borderTop: "solid",
                    borderWidth: "1px",
                    borderColor: theme.palette.grey.gainsboro,
                    borderBottomColor: theme.palette.grey.gainsboro,
                };
            }
            if (indexOfCurrentCell === lengthOfAllCellsArray - 1) {
                return {
                    borderRightStyle: "solid",
                    borderTopRightRadius: theme.border.radius.list,
                    borderBottomRightRadius: theme.border.radius.list,
                    borderBottom: "solid",
                    borderTop: "solid",
                    textAlign: "center",
                    borderWidth: "1px",
                    borderColor: theme.palette.grey.gainsboro,
                    borderBottomColor: theme.palette.grey.gainsboro,
                };
            }
            return {
                ...(disableRowClick
                    ? {
                          cursorPointer: {
                              cursor: "pointer",
                          },
                      }
                    : {}),
                padding: "12px 6px 12px 5px",
                textAlign: "center",
                borderBottom: "solid",
                borderTop: "solid",
                borderWidth: "1px",
                borderColor: theme.palette.grey.gainsboro,
                borderBottomColor: theme.palette.grey.gainsboro,
            };
        };

        return { ...getBodyCellStyle(), ...rowBackgroundColor, ...style };
    },
);

const BodyTableSimple = ({
    rows,
    prepareRow,
    handleContextClick,
    history,
    location,
    renderRow,
    loading,
    noResultMessage,
    minHeightInRows,
    disableRowClick,
    onRowClick,
}) => {
    const { t } = useTranslation();

    if (loading) {
        return null;
    }

    const _renderBodyRows = () =>
        rows.map((row, rowIndex) => {
            prepareRow(row);
            if (renderRow) {
                const isLastRow = rowIndex === rows.length - 1;
                return renderRow(row, isLastRow);
            }
            return (
                <TableRow
                    sx={!disableRowClick ? { cursor: "pointer" } : {}}
                    {...row.getRowProps()}
                    onClick={() => {
                        if (!disableRowClick) {
                            onRowClick
                                ? onRowClick(row.original)
                                : history.push(
                                      `${location.pathname}/${row.original?.id}`,
                                  );
                        }
                    }}
                    onContextMenu={(e) =>
                        handleContextClick(e, row.original?.id, row.original)
                    }
                >
                    {row.cells.map((cell, index, allCells) => {
                        return (
                            <StyledBodyTableCell
                                indexOfCurrentCell={index}
                                lengthOfAllCellsArray={allCells.length}
                                disableRowClick={disableRowClick}
                                rowIndex={rowIndex}
                                {...cell.getCellProps()}
                            >
                                {cell.render("Cell")}
                            </StyledBodyTableCell>
                        );
                    })}
                </TableRow>
            );
        });

    const _renderEmptyBody = () => {
        if (!noResultMessage) {
            return null;
        }
        return (
            <TableRow>
                <TableCell
                    style={{
                        paddingTop: 150,
                        paddingBottom: 150,
                        textAlign: "center",
                    }}
                    colSpan={1000}
                >
                    <Typography>{t("components.table.noResults")}</Typography>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <TableBody>
            {rows.length === 0 ? _renderEmptyBody() : _renderBodyRows()}
            {/* Add min height */}
            {rows.length !== 0 && rows.length < minHeightInRows && (
                <TableRow>
                    <TableCell
                        style={{ height: (minHeightInRows - rows.length) * 60 }}
                    ></TableCell>
                </TableRow>
            )}
        </TableBody>
    );
};

BodyTableSimple.defaultProps = {
    minHeightInRows: 5,
    noResultMessage: true,
};

export default BodyTableSimple;
