import axios from "axios";

const postForm = (form, satisfactionId, user, storeName) => {
    const { status, contactMethod, clientMood, comment, proposedSolution } =
        form;
    const serializedContactMethod = contactMethod.join("|");
    const getContactedBy = () => {
        if (storeName) {
            return { contactedBy: storeName };
        }
        if (user?.email) {
            return { contactedBy: user.email };
        }
        return {};
    };
    const body = {
        status,
        comment,
        satisfactionId,
        ...getContactedBy(),
        ...(serializedContactMethod
            ? { contactMethod: serializedContactMethod }
            : {}),
        ...(clientMood ? { clientMood } : {}),
        ...(proposedSolution ? { solution: proposedSolution } : {}),
    };

    return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/connectors-rest-api/satisfaction-report`,
        body,
    );
};

export default postForm;
