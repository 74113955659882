import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";

import ContentWrapper from "components/Layout/ContentWithHeader";
// import StockReceipt from "./StockReceipt";
import OrderReceipt from "./OrderReceipt";
import { styled } from "@mui/material/styles";
import CustomsDocument from "./CustomsDocuments";

import AppBarInside from "components/common/AppBarInside";
import { AuthContext } from "AuthProvider";

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: "transparent",
    paddingBottom: theme.spacing(2),
}));

const Receipt = () => {
    const { t } = useTranslation();
    const { userRoles } = useContext(AuthContext);

    const appBarContent = [
        {
            label: t("common.customerOrder_plural"),
            component: <OrderReceipt />,
        },
        // disabled, waiting for po confirmation
        // { label: t("common.stock"), component: <StockReceipt /> },
    ];

    if (
        ["Inside Logistics", "Inside Tech", "Inside Admin"].some((el) =>
            userRoles.includes(el),
        )
    ) {
        appBarContent.push({
            label: t("common.customsDocument"),
            component: <CustomsDocument />,
        });
    }

    return (
        <ContentWrapper title={t("common.delivery_plural")}>
            <StyledContainer>
                <AppBarInside content={appBarContent} />
            </StyledContainer>
        </ContentWrapper>
    );
};

export default Receipt;
