import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "AuthProvider";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import theme from "theme";

const AUTHORIZED_ROLES = [
    "Dev team",
    "Inside Admin",
    "Inside Tech",
    "Inside XP",
    "Inside DR",
];

const styles = {
    autocomplete: {
        width: 230,
        marginLeft: theme.spacing(4),
        "& .MuiFormLabel-root": {
            position: "absolute",
            top: -9,
        },
        "& label.Mui-focused": {
            top: -7,
            color: theme.palette.grey.gainsboro,
            backgroundColor: "transparent",
        },
        "& .MuiFormLabel-filled": {
            top: -7,
            color: theme.palette.grey.gainsboro,
            backgroundColor: "transparent",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.grey.gainsboro,
        },
        "& .MuiInputBase-input": {
            paddingTop: 9.5,
            paddingBottom: 9.5,
        },
        "& .MuiOutlinedInput-root": {
            paddingTop: 0,
            padding: 0,
            paddingBottom: 0,
            backgroundColor: theme.palette.white.antiFlash,
            "& fieldset": {
                border: "none",
            },
            "&:hover fieldset": {
                border: "none",
            },
            "&.Mui-focused fieldset": {
                border: "none",
            },
        },
    },
};

const StoreViewMenu = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { setStoreId, storeId, userRoles, stores } = useContext(AuthContext);

    if (stores.length === 0) return null;

    if (
        !AUTHORIZED_ROLES.some(
            (authorizedRole) =>
                userRoles.includes(authorizedRole) ||
                authorizedRole.includes("*"),
        )
    )
        return null;

    const value = stores.find((store) => store.id === storeId);

    return (
        <Autocomplete
            sx={styles.autocomplete}
            options={stores}
            value={value}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => {
                setStoreId(value?.id);
                history.push({
                    pathname: "/",
                });
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("common.storeView")}
                    variant="outlined"
                    fullWidth
                />
            )}
        />
    );
};

export default StoreViewMenu;
