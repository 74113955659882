import React from "react";
import { Typography } from "@mui/material";
import TablePrescriptionProduct from "components/common/TablePrescription";
import { formatDateLocales } from "services/toolbox";
import { useTranslation } from "react-i18next";
import theme from "theme";

const styles = {
    subtitle: {
        borderBottom: "1px solid #000",
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        fontWeight: "bold",
    },
};

const Prescription = ({ customerData, classes }) => {
    const { orders } = customerData;
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    return (
        <>
            <Typography sx={styles.subtitle}>
                {t("common.allPrescription")}
            </Typography>
            {orders.map((order) => {
                return order.prescriptions
                    ? order.prescriptions.map((prescription, index) => (
                          <div key={index}>
                              {" "}
                              <Typography variant="subtitle2">{`${t(
                                  "common.dateOfPrescription",
                              )}: ${formatDateLocales(
                                  prescription.created_at,
                                  locale,
                                  "P",
                              )}`}</Typography>
                              <TablePrescriptionProduct
                                  prescription={prescription}
                              />
                          </div>
                      ))
                    : null;
            })}
        </>
    );
};

export default Prescription;
