import { Chip, Typography } from "@mui/material";
import { LocalShipping, ShoppingCart } from "@mui/icons-material";
import { DateCell } from "components/Table/CellComponents";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "services/toolbox";
// import ButtonCellStatus from "../components/ButtonCellStatus";

const makeColumns = (t, locale, currentStore, onClickStatus) => [
    {
        Header: t("common.order"),
        accessor: "order.id",
        Cell: ({ row }) => (
            <>
                <Link
                    to={`/orders/${row?.original?.order?.id}`}
                    target="_blank"
                >
                    <Typography variant="body2">
                        {row?.original?.order?.id}
                    </Typography>
                </Link>
                <Typography variant="caption" component="div">
                    <DateCell
                        value={row?.original?.order?.created_at}
                        locale={locale}
                    />
                </Typography>
            </>
        ),
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: t("common.customer"),
        accessor: "order.customer_account.lastname",
        Cell: ({ row }) => (
            <Link
                to={`/customers/${row?.original?.order?.customer_account?.id}`}
                target="_blank"
            >
                <Typography variant="caption" component="div">
                    {row?.original?.order?.customer_account?.firstname}{" "}
                    {row?.original?.order?.customer_account?.lastname}
                </Typography>
                <Typography variant="caption" component="div">
                    {row?.original?.order?.customer_account?.email}
                </Typography>
                <Typography variant="caption" component="div">
                    {row?.original?.order?.customer_account?.phone_number}
                </Typography>
            </Link>
        ),
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: t("common.store"),
        accessor: "order.store.id",
        Cell: ({ row }) => (
            <>
                {(row?.original?.order?.vendor_info?.firstname ||
                    row?.original?.order?.vendor_info?.lastname) && (
                    <Typography variant="caption">
                        {"("}
                        {row?.original?.order?.vendor_info?.firstname}{" "}
                        {row?.original?.order?.vendor_info?.lastname}
                        {")"}
                    </Typography>
                )}

                <Chip
                    color={"default"}
                    label={row.original?.order?.store?.name?.toUpperCase()}
                    size="small"
                    style={{ width: "90%" }}
                    icon={<ShoppingCart />}
                />
                {row.original?.order.destination_store?.name && (
                    <Chip
                        color={"default"}
                        label={row.original?.order?.destination_store?.name?.toUpperCase()}
                        size="small"
                        style={{ width: "90%", marginTop: 3 }}
                        icon={<LocalShipping />}
                    />
                )}
            </>
        ),
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: capitalizeFirstLetter(t("pages.satisfaction.customerCategory")),
        accessor: "category",
        Cell: ({ row }) => (
            <>
                <Typography variant="body2">
                    {t(`pages.satisfaction.${row?.original?.category}`)}
                </Typography>
                <Typography
                    style={{
                        fontSize: 10,
                        color: "#646464",
                        fontWeight: "bold",
                    }}
                    variant="body2"
                >
                    NPS : {row?.original?.n1}
                </Typography>
                <Typography
                    style={{
                        fontSize: 10,
                        color: "#646464",
                        fontWeight: "bold",
                    }}
                    variant="body2"
                >
                    NDS : {row?.original?.n1}
                </Typography>
            </>
        ),
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: t("common.ratingType"),
        accessor: "type",
        Cell: ({ row }) => (
            <>
                <Typography variant="body2">
                    {t(`pages.satisfaction.${row?.original?.type}`)}
                </Typography>
            </>
        ),
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: t("common.date"),
        Cell: ({ value }) => <DateCell value={value} locale={locale} />,
        accessor: "created_at",
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: t("common.comment"),
        headerProps: {
            style: { width: "12%", paddingLeft: 0, paddingRight: 0 },
        },
        cellProps: {
            style: { paddingRight: 0, paddingLeft: 0, textAlign: "left" },
        },
        accessor: "comment",
        disableSortBy: true,
        disableFilters: true,
    },
    // {
    //     Header: t("common.status"),
    //     accessor: "status",
    //     Cell: ({ row }) => (
    //         <ButtonCellStatus onClickStatus={onClickStatus} data={row} />
    //     ),
    //     cellProps: { style: { paddingRight: 0, paddingLeft: 0 } },
    //     disableSortBy: true,
    //     disableFilters: true,
    // },
];

export default makeColumns;
