import React from "react";
import styled from "@mui/styles/styled";
import { Cancel } from "@mui/icons-material";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { useTranslation } from "react-i18next";

const TRANS_PATH = "pages.receipt.stockReceiptPopUp";

const styles = {
    dialog: {
        marginTop: 0,
    },
};

const StyledConfirmContent = styled("div")(() => ({
    textAlign: "center",
}));

const StyledWarningIcon = styled(Cancel)(({ theme }) => ({
    width: 60,
    height: 60,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1.5),
    color: theme.palette.red.corsa,
}));

const StyledTitle = styled("div")(({ theme }) => ({
    fontWeight: "bold",
    fontSize: 22,
    color: theme.palette.red.corsa,
    whiteSpace: "pre-line",
}));

const StyledTags = styled("div")(({ theme }) => ({
    ...theme.typography.h6,
    fontWeight: "bold",
    fontSize: theme.fontSize.M,
    marginTop: theme.spacing(2),
}));

const HandoverPopUp = ({ open, onPressConfirm, tags }) => {
    const { t } = useTranslation();
    return (
        <ConfirmationDialog
            open={open}
            buttonLabels={{
                confirm: t("common.close"),
            }}
            handleOK={onPressConfirm}
            className={styles.dialog}
            isLoading={false}
        >
            <StyledConfirmContent>
                <StyledWarningIcon />
                <StyledTitle>
                    Oops!
                    <br />{" "}
                    {t(`${TRANS_PATH}.errorHandOver`, {
                        count: tags.filter(
                            (tag) =>
                                tag.lastStatuses &&
                                !tag.lastStatuses.includes("ORDER_AT_SHOP"),
                        ).length,
                    })}
                </StyledTitle>
                <StyledTags>
                    {tags
                        .filter(
                            (tag) =>
                                tag.lastStatuses &&
                                !tag.lastStatuses.includes("ORDER_AT_SHOP"),
                        )
                        .map((tag) => tag.value)
                        .join(", ")}
                </StyledTags>
            </StyledConfirmContent>
        </ConfirmationDialog>
    );
};

export default HandoverPopUp;
