const ORDER_STATUS = {
    ORDER_SHIPPED: "ORDER_SHIPPED",
    LAB_ENTERED: "LAB_ENTERED",
    LAB_EXITED: "LAB_EXITED",
    LAB_GLASS_ORDERED: "LAB_GLASS_ORDERED",
    LAB_ORDER_PACKAGED: "LAB_ORDER_PACKAGED",
    LAB_WAITING: "LAB_WAITING",
    ORDER_AT_SHOP: "ORDER_AT_SHOP",
    ORDER_DELIVERED: "ORDER_DELIVERED",
    ORDER_ENDED: "ORDER_ENDED",
    LAB_LAB: "LAB_LAB",
    LAB_WAITING_QUEUE: "LAB_WAITING_QUEUE",
    DISPATCH_ERROR: "DISPATCH_ERROR",
    S_ORDER_SHIPPED: "S_ORDER_SHIPPED",
    S_ORDER_ENDED: "S_ORDER_ENDED",
    S_AVAILABLE_IN_STORE: "S_AVAILABLE_IN_STORE",
};

export default ORDER_STATUS;
