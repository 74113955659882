import React from "react";
import styled from "@mui/styles/styled";
import { useTranslation } from "react-i18next";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { CircularProgress, Box, Grow, Typography } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Place } from "@mui/icons-material";
import PropTypes from "prop-types";
import theme from "theme";

import { formatDateLocales, getStoreNameWithId } from "services/toolbox";
import { getCarrierTrackingUrl } from "../../services/utils/carrier";

import ORDER_STATUS from "constants/order-status";

const styles = {
    flexCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
    },
    displayDate: {
        width: "100%",
        textAlign: "center",
    },
    scannedByInfo: {
        overflowWrap: "anywhere",
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    textTitle: {
        fontWeight: "bolder",
    },
};

const StepIconComponent = ({ passed }) => {
    if (passed) {
        return <FiberManualRecordIcon />;
    } else return <FiberManualRecordOutlinedIcon />;
};

const StyledContainer = styled("div")(() => ({
    width: "100%",
    marginTop: theme.spacing(3),
}));

const StyledLink = styled("a")(() => ({
    fontWeight: "bolder",
}));

/**
 * ## InsideStepper
 * Display chrono frise according to steps array passed in props
 */
export default function InsideStepper({
    activeSteps,
    activeStepsCount,
    orderData,
    stores,
}) {
    const { t: statusTranslated, i18n } = useTranslation("orderStatus");

    const RenderTitleLink = (step) => (
        <StyledLink
            href={getCarrierTrackingUrl(
                orderData.carrier_type,
                orderData.tracking_number,
            )}
            target={"_blank"}
            rel="noreferrer"
        >
            {statusTranslated(`${step.id}.label`)}
        </StyledLink>
    );

    const RenderTitleText = (step) => (
        <>
            <Typography sx={styles.textTitle} variant="subtitle2">
                {statusTranslated(`${step.id}.label`)}
            </Typography>
        </>
    );

    const RenderScannedBy = (step) => (
        <Typography variant="body2" sx={styles.flexCenter}>
            <Place fontSize="inherit" />{" "}
            {step.comment.scanned_by.includes("@") ? (
                <Typography sx={styles.scannedByInfo}>
                    {step.comment.scanned_by}
                </Typography>
            ) : (
                getStoreNameWithId(stores, step.comment.scanned_by)
            )}
        </Typography>
    );

    return (
        <StyledContainer>
            {activeSteps.length > 0 ? (
                <Grow {...{ timeout: 1000 }} in={true}>
                    <Stepper activeStep={activeStepsCount} alternativeLabel>
                        {activeSteps.map((step, index) => (
                            <Step key={step.id}>
                                <StepLabel
                                    StepIconComponent={() => {
                                        return (
                                            <StepIconComponent
                                                passed={
                                                    index <= activeStepsCount
                                                }
                                            />
                                        );
                                    }}
                                >
                                    <>
                                        {step.id ===
                                            ORDER_STATUS.ORDER_SHIPPED &&
                                        orderData.carrier_type &&
                                        orderData.tracking_number
                                            ? RenderTitleLink(step)
                                            : RenderTitleText(step)}
                                        {step?.comment?.scanned_by &&
                                            RenderScannedBy(step)}
                                        <Typography
                                            variant="subtitle2"
                                            sx={styles.displayDate}
                                        >
                                            {step.date
                                                ? formatDateLocales(
                                                      step.date,
                                                      i18n.language,
                                                      "P",
                                                  )
                                                : ""}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {step.date
                                                ? formatDateLocales(
                                                      step.date,
                                                      i18n.language,
                                                      "p",
                                                  )
                                                : ""}
                                        </Typography>
                                    </>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grow>
            ) : (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            )}
        </StyledContainer>
    );
}

InsideStepper.propTypes = {
    activeStep: PropTypes.arrayOf(
        PropTypes.shape({
            problem: PropTypes.bool,
            created_at: PropTypes.string,
        }),
    ),
};
