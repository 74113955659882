import React, { useState, useRef } from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { getProductInfos } from "services/utils/stickers";
import { useTranslation } from "react-i18next";

const initialProduct = {
    sku: "",
    productName: "",
    quantity: 1,
    ean13: "",
};

const TRANS_PATH = "components.addStickerDialog";

const AddProductDialog = (props) => {
    const [product, setProduct] = useState(initialProduct);
    const [productErrors, setProductErrors] = useState([]);
    const [error, setError] = useState(false);
    const { addDataHandler } = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const productInput = useRef();
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setProductErrors([]);
        setError(false);
    };

    const handleAdd = async (event) => {
        if (product.sku !== "") {
            const eventID = event.currentTarget.id;
            const products = [];
            let tempProductErrors = [];
            setProductErrors(tempProductErrors);
            setLoading(true);
            const productsSku = product.sku.split(/\n|,\s?/);
            const productInfos = await getProductInfos(productsSku);
            if (productInfos) {
                // For each product info we get, we check if the product exists
                // productInfos[sku] = null then the product does not exists
                Object.keys(productInfos).forEach((sku) => {
                    if (productInfos[sku]) {
                        products.push({
                            ...product,
                            sku,
                            productName: productInfos[sku][0].name,
                        });
                    } else {
                        tempProductErrors = [...tempProductErrors, sku];
                        setProductErrors(tempProductErrors);
                    }
                });
                addDataHandler(products);
                setProduct(initialProduct);
                if (productInput && productInput.current) {
                    productInput.current.focus();
                }
            } else {
                setError(true);
            }
            setLoading(false);
            if (
                eventID === "add&close" &&
                tempProductErrors.length === 0 &&
                !error
            ) {
                handleClose();
            }
        }
    };

    const handleChange =
        (name) =>
        ({ target: { value } }) => {
            setProduct({ ...product, [name]: value });
        };

    return (
        <div>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
            >
                {t(`${TRANS_PATH}.title`)}
            </Button>

            <Dialog
                open={open}
                disableBackdropClick
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {t(`${TRANS_PATH}.title`)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(`${TRANS_PATH}.description`)}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t("common.sku").toUpperCase()}
                        required
                        multiline
                        rowsMax={4}
                        fullWidth
                        inputRef={productInput}
                        value={product.sku}
                        onChange={handleChange("sku")}
                    />
                    <TextField
                        margin="dense"
                        label={t("common.quantity")}
                        type="number"
                        inputProps={{ min: 1 }}
                        fullWidth
                        value={product.quantity}
                        onChange={handleChange("quantity")}
                    />

                    {productErrors.length > 0 && (
                        <Typography variant="body2" color="error">
                            Nous n'avons pas trouvé d'informations pour les
                            produits suivants et ils n'ont pas été ajoutés à la
                            liste: {productErrors.join(", ")}
                        </Typography>
                    )}
                    {error && (
                        <Typography variant="body2" color="error">
                            Un problème est survenu lors de la récupération des
                            informations sur les produits. Veuillez réessayer
                            dans quelques instants. Si le problème persiste,
                            veuillez contacter support-technique@jimmyfairly.com
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        disabled={loading}
                    >
                        {t("common.cancel").toUpperCase()}
                    </Button>
                    <Button
                        id="add"
                        disabled={product.sku.length === 0 || loading}
                        onClick={handleAdd}
                        color="primary"
                    >
                        {loading && (
                            <span>
                                <CircularProgress size={14} />
                            </span>
                        )}
                        {!loading && (
                            <span>{t("common.add").toUpperCase()}</span>
                        )}
                    </Button>
                    <Button
                        id="add&close"
                        disabled={product.sku.length === 0 || loading}
                        onClick={handleAdd}
                        color="primary"
                    >
                        {loading && (
                            <span>
                                <CircularProgress size={14} />
                            </span>
                        )}
                        {!loading && (
                            <span>
                                {t(
                                    `${TRANS_PATH}.addAndCloseCta`,
                                ).toUpperCase()}
                            </span>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

AddProductDialog.propTypes = {
    addDataHandler: PropTypes.func.isRequired,
};

export default AddProductDialog;
