import React from "react";
import _upperFirst from "lodash/upperFirst";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatDateLocales } from "../../../services/toolbox";
import { useTranslation } from "react-i18next";

const DateCell = ({ value, locale }) =>
    formatDateLocales(new Date(value), locale, "P");

const ChipCell = ({ label, style }) => (
    <Chip color={"default"} label={label} size="small" sx={style} />
);

const TypographyCell = ({ label, style }) => (
    <Typography sx={style}>{label}</Typography>
);

const AccountInfoCell = ({ firstname, lastname, email }) => (
    <>
        <Typography variant="subtitle2">
            {firstname && _upperFirst(firstname)}{" "}
            {lastname && _upperFirst(lastname)}
        </Typography>
        {email && <Typography variant="body2">{email}</Typography>}
    </>
);

const ChipWithTextCell = ({ chipLabel, text }) => (
    <div style={{ textAlign: "center", display: "grid" }}>
        <Chip color={"default"} label={chipLabel} size="small" />
        <Typography variant="caption">{text}</Typography>
    </div>
);

const LinkCell = ({ url, text }) => (
    <a
        href={url}
        target="_blank"
        onClick={(e) => {
            e.stopPropagation();
        }}
        rel="noopener noreferrer"
    >
        {text}
    </a>
);

const ActionCell = ({ id }) => {
    const { t } = useTranslation();
    const location = useLocation();
    return (
        <div
            style={{ cursor: "pointer" }}
            title={t("components.table.openTab")}
            onClick={() => window.open(`${location.pathname}/${id}`, "_blank")}
        >
            <OpenInNewIcon />
        </div>
    );
};

export {
    DateCell,
    ChipCell,
    AccountInfoCell,
    ChipWithTextCell,
    LinkCell,
    ActionCell,
    TypographyCell,
};
