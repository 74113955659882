import React from "react";
import styled from "@mui/styles/styled";
import { useTranslation } from "react-i18next";
import { isValid, parseISO, startOfDay, endOfDay } from "date-fns";
import TextField from "@mui/material/TextField";
import CustomDatePicker from "components/common/CustomDatePicker";

const StyledContainer = styled("div")(() => ({ minWidth: 89 }));

const FilterDateRange = ({ column: { filterValue = [], setFilter } }) => {
    const { t } = useTranslation();

    const onStartDateChange = (date) => {
        setFilter((old = []) => [date ? startOfDay(date) : undefined, old[1]]);
    };

    const onEndDateChange = (date) => {
        setFilter((old = []) => [old[0], date ? endOfDay(date) : undefined]);
    };

    /*
     *  TODO: check in next version better way for doing this because
     *   isValid() polluting console with warnings
     * */
    const filterValueFormatted = filterValue.map((date) =>
        !date || isValid(date) ? date : parseISO(date),
    );

    return (
        <StyledContainer>
            <CustomDatePicker
                selected={filterValueFormatted[0]}
                onChange={onStartDateChange}
                selectsStart
                placeholderText={t("common.start")}
                maxDate={new Date()}
                customInput={<TextField variant="standard" />}
                startDate={filterValueFormatted[0]}
                endDate={filterValueFormatted[1]}
            />
            <CustomDatePicker
                selected={filterValueFormatted[1]}
                onChange={onEndDateChange}
                selectsEnd
                placeholderText={t("common.end")}
                customInput={<TextField variant="standard" />}
                startDate={filterValueFormatted[0]}
                endDate={filterValueFormatted[1]}
                minDate={filterValueFormatted[0]}
            />
        </StyledContainer>
    );
};

export default FilterDateRange;
