import React, { useContext, useState } from "react";
import {
    TextField,
    CircularProgress,
    Autocomplete,
    Box,
    Button,
} from "@mui/material";

import { AuthContext } from "AuthProvider";
import { useTranslation } from "react-i18next";
import {
    getPdfCustomsDocument,
    openPdfDataToNewWindow,
} from "services/utils/pdf";
import { useSnackbar } from "notistack";
import { gql, useLazyQuery } from "@apollo/client";
import { DataGrid } from "@mui/x-data-grid";
import format from "date-fns/format";

const getCustomDocumentCandidates = gql`
    query docExportCandidates($storeId: String!, $minDate: timestamptz!) {
        orders(
            where: {
                _or: [
                    { store_id: { _eq: $storeId } }
                    { store_destination_id: { _eq: $storeId } }
                ]
                global_status_detail: { index: { _gte: 850, _lt: 1100 } }
                created_at: { _gte: $minDate }
                origin: { _is_null: false }
            }
            order_by: { created_at: desc }
        ) {
            id
            created_at
        }
        dossiers(
            where: {
                store_id: { _eq: $storeId }
                status: { _eq: "En préparation" }
                type: { _eq: "TDE" }
                created_at: { _gte: $minDate }
            }
            order_by: { created_at: desc }
        ) {
            id
            created_at
        }
    }
`;

const columns = [
    { field: "id", flex: 0.3, headerName: "n° commande" },
    {
        field: "created_at",
        flex: 0.5,
        type: "dateTime",
        headerName: "Date de création",
        valueGetter: (params) => new Date(params.value),
        valueFormatter: (params) =>
            format(new Date(params.value), "dd/MM/yy HH:mm"),
    },
];

const CustomsDocuments = () => {
    const { t } = useTranslation();
    const { stores } = useContext(AuthContext);
    const [storeSelected, setStoreSelected] = useState(null);
    const [pdfGenerationLoading, setPdfGenerationLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [displayGrid, setDisplayGrid] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [getCandidates, { loading, data }] = useLazyQuery(
        getCustomDocumentCandidates,
    );

    const handleGenerateButtonClick = async () => {
        setPdfGenerationLoading(true);
        const [pdf, filename] = await getPdfCustomsDocument({
            store: storeSelected.id,
            tdes: candidates.filter((candidate) => candidate.startsWith("TDE")),
            orders: candidates.filter(
                (candidate) => !candidate.startsWith("TDE"),
            ),
        });

        if (pdf) {
            openPdfDataToNewWindow(pdf, filename);
        } else {
            enqueueSnackbar("Problème dans la génération du PDF", {
                variant: "error",
                autoHideDuration: 5000,
            });
        }
        setPdfGenerationLoading(false);
    };

    const handleSearchButtonClick = () => {
        if (!storeSelected) {
            enqueueSnackbar("Séléectionnez un magasin", {
                variant: "error",
                autoHideDuration: 5000,
            });
            return null;
        }
        const minDate = new Date();
        minDate.setMonth(minDate.getMonth() - 2);
        getCandidates({
            variables: {
                storeId: storeSelected.id,
                minDate: minDate.toISOString(),
            },
        });
        setDisplayGrid(true);
    };
    const onCandidatesSelected = (selection) => {
        setCandidates(selection);
    };

    return (
        <div>
            <Autocomplete
                disablePortal
                value={storeSelected}
                onChange={(e, change) => {
                    setCandidates([]);
                    setDisplayGrid(false);
                    setStoreSelected(change);
                }}
                isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                }
                options={stores
                    .filter(
                        (store) =>
                            store.country !== "FRA" &&
                            !store.definitively_closed,
                    )
                    .map((store) => ({
                        label: store.name,
                        id: store.id,
                    }))}
                sx={{ width: 300, my: 2 }}
                renderInput={(params) => (
                    <TextField
                        variant="standard"
                        {...params}
                        label={t("common.store")}
                    />
                )}
            />
            <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={handleSearchButtonClick}
            >
                {loading && <CircularProgress size={14} />}
                Rechercher
            </Button>
            {candidates.length > 0 && (
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginLeft: 2 }}
                    disabled={pdfGenerationLoading}
                    onClick={handleGenerateButtonClick}
                >
                    {pdfGenerationLoading && <CircularProgress size={14} />}
                    Générer le PDF
                </Button>
            )}
            {displayGrid && data && !loading && (
                <Box
                    sx={{ marginTop: 2 }}
                    style={{ height: 400, width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                rows={[...data.orders, ...data.dossiers]}
                                columns={columns}
                                pageSize={30}
                                rowsPerPageOptions={[20, 30, 50]}
                                checkboxSelection
                                disableSelectionOnClick
                                density="confortable"
                                disableColumnMenu
                                sortModel={[
                                    {
                                        field: "created_at",
                                        sort: "desc",
                                    },
                                ]}
                                onSelectionModelChange={onCandidatesSelected}
                            />
                        </div>
                    </div>
                </Box>
            )}
        </div>
    );
};

export default CustomsDocuments;
