import queryString from "query-string";
import { endOfDay, format, startOfDay } from "date-fns";

const encode = (filters) => {
    const arrangedFilters = { ...filters };

    if (filters.beginCreatedAt) {
        arrangedFilters.beginCreatedAt = format(
            filters.beginCreatedAt,
            "yyyy-MM-dd",
        );
    }

    if (filters.endCreatedAt) {
        arrangedFilters.endCreatedAt = format(
            filters.endCreatedAt,
            "yyyy-MM-dd",
        );
    }

    return queryString.stringify(arrangedFilters, {
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
    });
};

/**
 * decode a url string and transform to react-table array of filter
 * @param {string} urlFilterString
 * @param {Array.<string>} exceptionsToIgnore
 * @returns {[{id:string,value:string}]}
 */
const decode = (urlFilterString, exceptionsToIgnore = []) => {
    if (!urlFilterString) {
        return [];
    }

    const filterObj = queryString.parse(urlFilterString, {
        arrayFormat: "comma",
    });

    // created_at filter need to be concatenate
    const decodedCreatedAtFilter =
        filterObj.beginCreatedAt || filterObj.endCreatedAt
            ? [
                  {
                      id: "created_at",
                      value: [
                          filterObj.beginCreatedAt
                              ? startOfDay(new Date(filterObj.beginCreatedAt))
                              : null,
                          filterObj.endCreatedAt
                              ? endOfDay(new Date(filterObj.endCreatedAt))
                              : null,
                      ],
                  },
              ]
            : [];

    const keyToNotDecode = [
        "beginCreatedAt",
        "endCreatedAt",
        "search",
        ...exceptionsToIgnore,
    ];

    const decodedFilters = Object.keys(filterObj)
        .filter((filterKey) => !keyToNotDecode.includes(filterKey))
        .map((filterKey) => ({
            id: filterKey,
            value: Array.isArray(filterObj[filterKey])
                ? filterObj[filterKey]
                : [filterObj[filterKey]],
        }));

    return [...decodedCreatedAtFilter, ...decodedFilters];
};

export { decode, encode };
