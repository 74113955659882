const localizationItems = [
    {
        market: "fr-FR",
        title: "FR",
        description: "FR - Français",
    },
    {
        market: "en-GB",
        title: "EN",
        description: "EN - English",
    },
];

export default localizationItems;
