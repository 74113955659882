import axios from "axios";

/**
 * @typedef {Object} ProductInfo
 * @property {string} name
 * @property {string} ean13
 * @property {string} sku
 */

/**
 *
 * @param {string[]} sku
 * @returns {{
 *    [sku: string]: ProductInfo
 *  }|null}
 */
const getProductInfos = async (sku) => {
    const urlApi = `${process.env.REACT_APP_API_BASE_URL}/datawarehouse-query/get-products-from-skus`;
    try {
        const response = await axios.post(urlApi, {
            refs: sku,
        });
        if (response.status === 200) {
            return response.data;
        }

        return {};
    } catch (error) {
        console.error(error);
        return null;
    }
};

export { getProductInfos };
