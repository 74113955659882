import React from "react";
import TextField from "@mui/material/TextField";
import styled from "@mui/styles/styled";
import DatePicker from "react-datepicker";
import { getLocalDate } from "services/toolbox";

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .react-datepicker__close-icon": {
        padding: `0 0 0 ${theme.spacing(1)}px`,
        "&:after": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        },
    },
}));

const StyledTextField = styled(TextField)(() => ({
    width: "90%",
}));

const StyledContainer = styled("div")(() => ({
    // zIndex: "9 !important",
}));

const styles = {
    placeHolderCentered: {
        textAlign: "center",
    },
};

const CustomDateFilter = ({
    selected,
    onChange,
    locale,
    startDate,
    endDate,
    selectsStart,
    selectsEnd,
    placeholderText,
    placeHolderCentered,
}) => (
    <StyledContainer>
        <StyledDatePicker
            selected={selected}
            onChange={onChange}
            placeholderText={placeholderText}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            dateFormat="P"
            isClearable
            locale={getLocalDate(locale)}
            maxDate={new Date()}
            customInput={
                <StyledTextField
                    variant="standard"
                    inputProps={
                        placeHolderCentered
                            ? { sx: styles.placeHolderCentered }
                            : {}
                    }
                />
            }
            startDate={startDate}
            endDate={endDate}
        />
    </StyledContainer>
);

export default CustomDateFilter;
