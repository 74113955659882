import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery, gql } from "@apollo/client";

import InsideTable from "components/Table";
import ContentWrapper from "components/Layout/ContentWithHeader";
import HeaderSimple from "components/Table/HeaderComponents/HeaderSimple";
import { AccountInfoCell, DateCell } from "components/Table/CellComponents";
import { globalFilterFormatter } from "components/Table/utils";

import { useTranslation } from "react-i18next";
import FooterPaginationSimple from "components/Table/FooterComponents/FooterPaginationSimple";
import BodyTableSimple from "components/Table/BodyComponents/BodyTableSimple";

const customerQuery = gql`
    query Customers(
        $search: String
        $limit: Int
        $offset: Int
        $order_by: [customer_accounts_order_by!]
    ) {
        customer_accounts_aggregate(
            where: {
                _or: [
                    { firstname: { _ilike: $search } }
                    { lastname: { _ilike: $search } }
                    { email: { _ilike: $search } }
                    { phone_number: { _ilike: $search } }
                    {
                        _and: [
                            { firstname: { _similar: $search } }
                            { lastname: { _similar: $search } }
                        ]
                    }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
        customer_accounts(
            where: {
                _or: [
                    { firstname: { _ilike: $search } }
                    { lastname: { _ilike: $search } }
                    { email: { _ilike: $search } }
                    { phone_number: { _ilike: $search } }
                    {
                        _and: [
                            { firstname: { _similar: $search } }
                            { lastname: { _similar: $search } }
                        ]
                    }
                ]
            }
            limit: $limit
            offset: $offset
            order_by: $order_by
        ) {
            id
            firstname
            lastname
            email
            phone_number
            birthday
        }
    }
`;

const CustomersPage = () => {
    const lastRemoteDataHandlerParams = useRef(null);
    const [columns, setColumns] = useState(null);
    const { refetch } = useQuery(customerQuery, { skip: true });
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    const [fetchCustomerStatus, setFetchCustomerStatus] = useState({
        isLoading: true,
        data: [],
        page: 0,
        totalCount: 0,
    });

    useEffect(() => {
        const columnsTemp = [
            {
                Header: t("common.customer"),
                accessor: "customer_account.lastname",
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row }) => (
                    <AccountInfoCell
                        firstname={row.original?.firstname}
                        lastname={row.original?.lastname}
                    />
                ),
            },
            {
                Header: t("common.mail"),
                accessor: "email",
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: t("common.phone"),
                accessor: "phone_number",
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: t("common.birthdate"),
                accessor: "birthday",
                Cell: ({ value }) => <DateCell value={value} locale={locale} />,
                disableSortBy: true,
                disableFilters: true,
            },
        ];
        setColumns(columnsTemp);
    }, [t, locale]);

    const remoteDataHandler = useCallback(
        async (remoteDataHandlerParams) => {
            const { pageSize, pageIndex, globalFilter } =
                remoteDataHandlerParams || {};
            setFetchCustomerStatus((fetchCustomerStatusTemp) => ({
                ...fetchCustomerStatusTemp,
                isLoading: true,
            }));
            /*
             * save params of data handler
             * */
            lastRemoteDataHandlerParams.current = remoteDataHandlerParams;
            try {
                const { data, error } = await refetch({
                    search: globalFilter
                        ? globalFilterFormatter(globalFilter)
                        : "%%",
                    limit: pageSize,
                    offset: pageIndex,
                    order_by: { lastname: "asc" },
                });

                if (error) {
                    console.error("Problem fetching data");
                    console.error(error);
                }

                /*
                 * Set table data info only if last saved params are same of current params
                 * in case of <InsideTable/> call remote data handler
                 * before last remote data handler call still waiting
                 * */
                if (
                    JSON.stringify(lastRemoteDataHandlerParams.current) ===
                    JSON.stringify(remoteDataHandlerParams)
                ) {
                    setFetchCustomerStatus({
                        isLoading: false,
                        data: data?.customer_accounts || [],
                        page: pageIndex,
                        totalCount:
                            data?.customer_accounts_aggregate?.aggregate?.count,
                    });
                }
            } catch (e) {
                console.error("catch error ==> ", e);
            }
        },
        [refetch],
    );

    return (
        <ContentWrapper title={t("common.customer_plural")}>
            {columns ? (
                <>
                    <InsideTable
                        renderTableHeader={({ headerGroups }) => (
                            <HeaderSimple
                                headerGroups={headerGroups}
                                style={{ paddingBottom: 20 }}
                            />
                        )}
                        renderTableBody={BodyTableSimple}
                        renderTableFooter={FooterPaginationSimple}
                        columns={columns}
                        data={fetchCustomerStatus.data}
                        loading={fetchCustomerStatus.isLoading}
                        remoteDataHandler={remoteDataHandler}
                        pageCount={fetchCustomerStatus.totalCount}
                        detailsUrl={`/customers`}
                        searchPlaceholder={t(
                            "pages.customers.searchPlaceholder",
                        )}
                    />
                </>
            ) : (
                <div />
            )}
        </ContentWrapper>
    );
};

export default CustomersPage;
