import React, { useEffect, useContext } from "react";
import { AuthContext } from "AuthProvider";
import { useHistory } from "react-router-dom";

import InsideLoader from "components/common/InsideLoader";
import { routesFn } from "Routes";
import { useTranslation } from "react-i18next";

const Home = () => {
    const { userRoles, isLoadingAuthState } = useContext(AuthContext);
    const { t } = useTranslation();
    const routes = routesFn(t);

    const history = useHistory();

    useEffect(() => {
        if (!isLoadingAuthState && userRoles.length > 0) {
            const homeRoute = routes.find(
                (route) =>
                    route.home.filter((homeRole) =>
                        userRoles.includes(homeRole),
                    ).length > 0,
            );
            if (homeRoute) {
                history.push(homeRoute.path);
            } else {
                history.push("/unauthorized");
            }
        }
    }, [history, isLoadingAuthState, userRoles, routes]);

    return <InsideLoader />;
};

export default Home;
