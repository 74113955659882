import React from "react";

import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import theme from "theme";

const styles = {
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight: {
        color: theme.palette.secondary.main,
    },
    title: {
        flex: "1 1",
    },
};

const TableToolbar = ({
    title,
    numSelected,
    addDataDialog: AddDataDialog,
    addDataHandler,
    deleteDataHandler,
}) => {
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                ...styles.root,
                ...(numSelected > 0 ? styles.highlight : {}),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={styles.title}
                    color="inherit"
                    variant="subtitle1"
                >
                    {numSelected} sélectioné
                </Typography>
            ) : (
                <Typography
                    sx={{ ...styles.highlight, ...styles.title }}
                    variant="h6"
                    id="tableTitle"
                >
                    {title}
                </Typography>
            )}

            {numSelected > 0 ? (
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={deleteDataHandler}
                >
                    {t("common.delete").toUpperCase()}
                </Button>
            ) : (
                AddDataDialog && (
                    <span>
                        <AddDataDialog
                            addDataHandler={addDataHandler}
                            sx={{ ...styles.highlight, ...styles.title }}
                        />
                    </span>
                )
            )}
        </Toolbar>
    );
};

TableToolbar.propTypes = {
    title: PropTypes.string,
    addDataDialog: PropTypes.elementType,
    numSelected: PropTypes.number.isRequired,
    addDataHandler: PropTypes.func.isRequired,
    deleteDataHandler: PropTypes.func.isRequired,
};

export default TableToolbar;
