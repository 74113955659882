import React from "react";
import {
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Fade,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "theme";

const TablePackProduct = ({ lens_pack, refinement, option }) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12}>
            <Fade in={true} timeout={1100}>
                <TableContainer component={Paper}>
                    <Table aria-label="table">
                        <TableHead
                            sx={{
                                backgroundColor: theme.palette.mainGrey.main,
                            }}
                            className={{
                                backgroundColor: theme.palette.mainGrey.main,
                            }}
                        >
                            <TableRow>
                                <TableCell>
                                    {t("common.type").toUpperCase()}
                                </TableCell>
                                <TableCell>
                                    {t("common.wording").toUpperCase()}
                                </TableCell>
                                <TableCell
                                    rowSpan={2}
                                    align="right"
                                ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {t("common.pack").toUpperCase()}
                                </TableCell>
                                <TableCell>
                                    {lens_pack ? lens_pack.label : "∅"}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {t("common.refinement").toUpperCase()}
                                </TableCell>
                                <TableCell>
                                    {refinement ? refinement.label : "∅"}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {t("common.option").toUpperCase()}
                                </TableCell>
                                <TableCell>
                                    {option ? option.label : "∅"}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fade>
        </Grid>
    );
};

export default TablePackProduct;
