import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import theme from "theme";

const StyledInput = styled("input")(({ style }) => ({
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flex: 1,
    border: "none",
    width: "auto",
    outline: "transparent",
    ...style,
}));

const StyledTagCloseSpan = styled("span")(() => ({
    textAlign: "center",
    marginLeft: theme.spacing(0.8),
    background: "transparent",
    cursor: "pointer",
}));

const StyledTagTitleSpan = styled("span")(() => ({
    marginRight: theme.spacing(0.8),
}));

const StyledLi = styled("li")(({ tag }) => ({
    background: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    listStyle: "none",
    borderRadius: "6px",
    margin: theme.spacing(0.8),
    fontWeight: "bold",
    border: `1px solid ${theme.palette.primary.light}`,
    ...(!tag.isValid ? { backgroundColor: theme.palette.red.crimson } : {}),
    ...(tag.isValid && !tag.isLoading
        ? { backgroundColor: theme.palette.green.may }
        : {}),
}));

const StyledUl = styled("ul")(() => ({
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    paddingInline: 0,
    color: theme.palette.secondary.main,
    margin: 0,
}));

const StyledDivContainer = styled("div")(({ style }) => style);

const TagsInput = (props) => {
    const inputRef = useRef(null);
    // We use this ref to make sure the value is really empty
    // without it, the last tag is deleted when it last one character in the input
    const prevInputValue = useRef(null);

    const removeTag = useCallback(
        (idToRemove) => {
            if (idToRemove !== "undefined") {
                props.onRemove(idToRemove);
            } else if (props.tags.length > 0) {
                //If backspace is hit and input is empty we try to remove the last tag
                props.onRemove(props.tags[props.tags.length - 1].id);
            }
        },
        [props],
    );

    const addTags = (event) => {
        event.persist();
        let value = event.target.value;
        if (value !== "") {
            const tagsDetected = event.target.value.split(",");
            for (let i = 0; i < tagsDetected.length; i += 1) {
                props.onAdd({
                    value: tagsDetected[i].trim(),
                    isValid: true,
                    isLoading: false,
                    timeoutId: null,
                });
            }
            event.target.value = "";
        }
    };

    return (
        <StyledDivContainer
            onClick={() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }}
            style={props.style}
        >
            <StyledUl>
                {props.tags.map((tag, index) => (
                    <StyledLi tag={tag} key={index}>
                        <StyledTagTitleSpan>{tag.value}</StyledTagTitleSpan>
                        {tag.isLoading && (
                            <CircularProgress
                                size={14}
                                variant="indeterminate"
                            />
                        )}
                        <StyledTagCloseSpan
                            onClick={() => {
                                removeTag(tag.id);
                            }}
                        >
                            x
                        </StyledTagCloseSpan>
                    </StyledLi>
                ))}
                <StyledInput
                    ref={inputRef}
                    type="text"
                    onPaste={addTags}
                    onKeyUp={(event) => {
                        switch (event.key) {
                            case "Enter":
                                addTags(event);
                                break;
                            case "Backspace":
                                if (prevInputValue.current === "") {
                                    removeTag();
                                }
                                break;
                            default:
                                break;
                        }
                        prevInputValue.current = event.target.value;
                    }}
                    autoFocus={props.autofocus}
                    placeholder={props.placeholder}
                    style={props?.classes?.tagInput || {}}
                />
            </StyledUl>
        </StyledDivContainer>
    );
};

TagsInput.propTypes = {
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            isValid: PropTypes.bool,
            isLoading: PropTypes.bool,
            timeoutId: PropTypes.number,
            id: PropTypes.string,
        }),
    ).isRequired,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    placeholder: PropTypes.string,
    autofocus: PropTypes.bool,
    style: PropTypes.object,
    classes: PropTypes.shape({
        tagInput: PropTypes.object,
    }),
};

export default TagsInput;
