import React from "react";
import styled from "@mui/styles/styled";
import { Button, DialogActions, Typography } from "@mui/material";
import theme from "theme";
const styles = {
    checkIcon: {
        width: 60,
        height: 60,
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1.5),
        color: theme.palette.green.texas,
    },
    confirmButton: {
        fontWeight: "bold",
        fontSize: theme.fontSize.M,
        marginLeft: theme.spacing(3),
        color: theme.palette.white.absolute,
        backgroundColor: theme.palette.blue.maastricht,
        borderRadius: theme.border.radius.button,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.grey.granite,
        },
    },
    description: {
        fontSize: theme.fontSize.M,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        textAlign: "center",
        whiteSpace: "pre",
    },
    dialogActionsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    infoIcon: {
        marginRight: theme.spacing(1.5),
        color: theme.palette.red.corsa,
    },
    infoText: {
        fontSize: theme.fontSize.M,
        fontWeight: "bold",
        color: theme.palette.red.corsa,
    },
    title: {
        fontWeight: "bold",
        fontSize: 22,
        color: theme.palette.green.texas,
    },
};

const StyledContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}));

const StyledInfoContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    whiteSpace: "pre-line",
}));

const InfoPopUpBody = ({
    onClickCta,
    IconTitle,
    bigIconStyle,
    title,
    titleStyle,
    description,
    descriptionStyle,
    disclaimer,
    disclaimerStyle,
    DisclaimerIcon,
    disclaimerIconStyle,
    ctaText,
}) => {
    return (
        <StyledContainer>
            {IconTitle && (
                <IconTitle sx={{ ...styles.checkIcon, ...bigIconStyle }} />
            )}
            {title && (
                <Typography sx={{ ...styles.title, ...titleStyle }}>
                    {title}
                </Typography>
            )}
            {description && (
                <Typography sx={{ ...styles.description, ...descriptionStyle }}>
                    {description}
                </Typography>
            )}
            {(disclaimer || DisclaimerIcon) && (
                <StyledInfoContainer>
                    {DisclaimerIcon && (
                        <DisclaimerIcon
                            sx={{ ...styles.infoIcon, ...disclaimerIconStyle }}
                        />
                    )}
                    {disclaimer && (
                        <Typography
                            sx={{ ...styles.infoText, ...disclaimerStyle }}
                        >
                            {disclaimer}
                        </Typography>
                    )}
                </StyledInfoContainer>
            )}
            <DialogActions sx={styles.dialogActionsContainer}>
                <Button onClick={onClickCta} sx={styles.confirmButton}>
                    {ctaText}
                </Button>
            </DialogActions>
        </StyledContainer>
    );
};

export default InfoPopUpBody;
