import React from "react";
import { Button, Typography } from "@mui/material";
import theme from "theme";

const styles = {
    button: {
        backgroundColor: theme.palette.blue.maastricht,
        borderRadius: theme.border.radius.list,
    },
    buttonDisabled: {
        backgroundColor: theme.palette.secondary.dark,
    },
    label: {
        fontWeight: "bold",
        fontSize: "0.8125rem",
        color: theme.palette.white.absolute,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.3),
        paddingBottom: theme.spacing(0.3),
        "&:hover": {
            color: theme.palette.grey.granite,
        },
    },
    labelDisabled: {
        fontWeight: "bold",
        fontSize: "0.8125rem",
        color: theme.palette.grey.dark,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.3),
        paddingBottom: theme.spacing(0.3),
    },
};

const ButtonCellComponent = ({
    label,
    disabled,
    onClick,
    style,
    labelStyle,
    disableStyle,
}) => {
    const finalButtonDisableStyle = {
        ...styles.buttonDisabled,
        ...disableStyle,
    };
    return (
        <Button
            onClick={onClick}
            sx={{
                ...styles.button,
                ...style,
                ...(disabled ? finalButtonDisableStyle : {}),
            }}
            disabled={disabled}
        >
            <Typography
                sx={{
                    ...(disabled ? styles.labelDisabled : styles.label),
                    labelStyle,
                }}
            >
                {label}
            </Typography>
        </Button>
    );
};

export default ButtonCellComponent;
