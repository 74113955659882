import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { formatDateLocales, getStoreNameWithId } from "services/toolbox";
import { Info, Place } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import theme from "theme";

const styles = {
    root: {
        width: "100%",
    },
    flexVerticalCenter: {
        display: "flex",
        alignItems: "center",
    },
    row: {
        padding: 0,
        backgroundColor: theme.palette.mainGrey.main,
    },
    danger: {
        backgroundColor: theme.palette.error.main,
    },
    calm: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
    },
    detail: {
        width: "13rem",
    },
    iconClass: {
        width: "14%",
        color: "rgba(0, 0, 0, 0.54)",
    },
};

const StepRow = ({ row, stores }) => {
    const { t: statusTranslated, i18n } = useTranslation("orderStatus");
    const locale = i18n.language;

    const RenderScannedBy = () => (
        <>
            {row.comment?.scanned_by && (
                <Typography variant="subtitle2" sx={styles.flexVerticalCenter}>
                    <Place />{" "}
                    {row.comment.scanned_by.includes("@")
                        ? row.comment.scanned_by
                        : getStoreNameWithId(stores, row.comment.scanned_by)}
                </Typography>
            )}
        </>
    );

    return (
        <TableRow sx={styles.row}>
            <TableCell sx={styles.iconClass}>
                <Tooltip
                    title={
                        row.order_statuses_definition &&
                        row.order_statuses_definition.description
                    }
                    placement="top"
                >
                    <Info />
                </Tooltip>
            </TableCell>

            <TableCell size="medium">
                {statusTranslated(`${row.order_statuses_definition?.id}.label`)}
            </TableCell>
            <TableCell>{RenderScannedBy()}</TableCell>
            <TableCell>
                {formatDateLocales(row.created_at, locale, "Pp")}
            </TableCell>
            <TableCell sx={styles.detail} size="medium" align="center">
                <Paper
                    sx={
                        row.order_statuses_definition &&
                        row.order_statuses_definition.is_problem
                            ? styles.danger
                            : styles.calm
                    }
                >
                    <Typography variant="overline">Validé</Typography>
                </Paper>
            </TableCell>
        </TableRow>
    );
};

/**
 * ## CollapsibleTable
 * > Table wrapped component used to display infos from an order
 */
export default function TrackingHistoryList({
    order_status_changes,
    visible,
    order_statuses_definitions,
    created_at,
    stores,
}) {
    const [mainStatus, setMainStatus] = useState([]);

    useEffect(() => {
        //prepare command ordered in case no status changes detected
        let commandStatus = { ...order_statuses_definitions[0] };
        commandStatus.created_at = created_at;
        //if status changes presents, map on those changes to print em on da screen
        order_status_changes.length
            ? setMainStatus(order_status_changes)
            : setMainStatus((tempMainStatus) => [
                  ...tempMainStatus,
                  commandStatus,
              ]);
    }, [order_status_changes, created_at, order_statuses_definitions]);

    return (
        <Collapse sx={styles.root} in={visible}>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {mainStatus.map((row, index) => (
                            <StepRow
                                row={row}
                                stores={stores}
                                key={`status-${index}`}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Collapse>
    );
}
