import {
    formatFiltersToObj,
    globalFilterFormatter,
} from "components/Table/utils";
import { REPORT_STATUS } from "../constants";

/**
 *
 * @param {[]} filters filters issued from react table
 * @param {string} globalFilter
 * @returns {FormatedFilters}
 */
export const formatFiltersForGraphQL = (filters = [], globalFilter) => {
    const filtersObj = formatFiltersToObj(filters);

    return {
        search: globalFilter ? globalFilterFormatter(globalFilter) : null,
        beginCreatedAt: filtersObj.created_at?.[0] || null,
        endCreatedAt: filtersObj.created_at?.[1] || null,
        type: filtersObj?.type || null,
        category: filtersObj?.category || null,
        comment: filtersObj?.comment || null,
        contacted: filtersObj?.contacted || null,
        store: filtersObj?.store || null,
        status: filtersObj?.status || null,
    };
};

/**
 *
 * @param {FormatedFilters} filters
 * @returns a conditionnal where clause where null conditions
 */
export const buildGraphQLWhereClauseWithFilters = (filters) => {
    const whereClause = { _and: [], _or: [] };

    if (filters.search) {
        whereClause._or.push(
            { order_id: { _ilike: filters.search } },
            { comment: { _ilike: filters.search } },
            {
                order: {
                    vendor_info: { firstname: { _ilike: filters.search } },
                },
            },
            {
                order: {
                    vendor_info: { lastname: { _ilike: filters.search } },
                },
            },
            {
                order: {
                    customer_account: { firstname: { _ilike: filters.search } },
                },
            },
            {
                order: {
                    customer_account: { lastname: { _ilike: filters.search } },
                },
            },
            {
                order: {
                    customer_account: { email: { _ilike: filters.search } },
                },
            },
        );
    }
    if (filters.beginCreatedAt || filters.endCreatedAt) {
        const lastIndex = whereClause._and.push({ created_at: {} }) - 1;
        if (filters.beginCreatedAt) {
            whereClause._and[lastIndex].created_at._gte =
                filters.beginCreatedAt;
        }
        if (filters.endCreatedAt) {
            whereClause._and[lastIndex].created_at._lte = filters.endCreatedAt;
        }
    }

    if (filters.type) {
        whereClause._and.push({ type: { _in: filters.type } });
    }

    if (filters.category) {
        for (let i = 0; i < filters.category.length; i += 1) {
            switch (filters.category[i]) {
                case "detractor":
                    whereClause._or.push({
                        n_avg: { _lt: 7 },
                    });
                    break;
                case "passive":
                    whereClause._or.push({
                        _and: [{ n_avg: { _gte: 7 } }, { n_avg: { _lt: 9 } }],
                    });
                    break;
                case "promoter":
                    whereClause._or.push({
                        n_avg: { _gte: 9 },
                    });
                    break;
                default:
                    break;
            }
        }
    }

    if (filters.status) {
        const _inReportStatuses = [];
        for (let i = 0; i < filters.status.length; i += 1) {
            switch (filters.status[i]) {
                case REPORT_STATUS.TO_CONTACT:
                    _inReportStatuses.push(REPORT_STATUS.TO_CONTACT);
                    break;
                case REPORT_STATUS.TO_NOT_CONTACT:
                    _inReportStatuses.push(REPORT_STATUS.TO_NOT_CONTACT);
                    break;
                case REPORT_STATUS.WAITING:
                    _inReportStatuses.push(REPORT_STATUS.WAITING);
                    break;
                case REPORT_STATUS.RESOLVED:
                    _inReportStatuses.push(REPORT_STATUS.RESOLVED);
                    break;
                default:
                    break;
            }
        }
        whereClause._and.push({
            report_status: { _in: _inReportStatuses },
        });
    }

    if (filters.comment) {
        const withComment =
            filters.comment.includes(true) || filters.comment.includes("true");
        const withoutComment =
            filters.comment.includes(false) ||
            filters.comment.includes("false");

        if (withComment && !withoutComment) {
            whereClause._and.push({
                comment: { _is_null: false },
            });
        }

        if (!withComment && withoutComment) {
            whereClause._and.push({
                comment: { _is_null: true },
            });
        }
    }

    if (filters.store) {
        whereClause._and.push({
            order: {
                _or: [
                    { store_id: { _eq: filters.store } },
                    { store_destination_id: { _eq: filters.store } },
                ],
            },
        });
    }
    if (whereClause._and.length === 0) {
        delete whereClause._and;
    }

    if (whereClause._or.length === 0) {
        delete whereClause._or;
    }

    return whereClause;
};
