import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { AccountCircle, ArrowForward as Arrow } from "@mui/icons-material";
import theme from "../../theme";

const styles = {
    buttonJF: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "48px",
        color: theme.palette.secondary.main,
        marginLeft: 15,
    },
};

export default function Teletransmission({ orderData }) {
    const { optimum, customer_account } = orderData;

    return (
        <Box>
            {optimum ? (
                <>
                    <Box
                        display="flex"
                        alignItems="center"
                        style={{ marginBottom: 30 }}
                    >
                        <AccountCircle
                            style={{ paddingRight: 5 }}
                            fontSize="large"
                        />
                        <Typography variant="h5">
                            {customer_account.firstname}{" "}
                            {customer_account.lastname}
                        </Typography>
                        <Button
                            variant="contained"
                            sx={styles.buttonJF}
                            endIcon={<Arrow />}
                            onClick={() => {
                                window
                                    .open(
                                        `${process.env.REACT_APP_OPTIMUM_BASE_URL}${optimum.url}`,
                                        "_blank",
                                    )
                                    .focus();
                            }}
                        >
                            Allez sur Optimum
                        </Button>
                    </Box>
                    {Object.entries(optimum)
                        .filter(
                            ([key, val]) =>
                                key !== "__typename" && key !== "id",
                        )
                        .map(([key, val]) => (
                            <Typography>
                                {key} : {val}
                            </Typography>
                        ))}
                </>
            ) : (
                <Typography>
                    Pas de télétransmission réalisée sur cette commande
                </Typography>
            )}
        </Box>
    );
}
