import React, { useEffect, useState } from "react";
import TableToolbar from "components/Table/TableToolbar";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    useTable,
    usePagination,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useAsyncDebounce,
} from "react-table";
import { styled } from "@mui/material/styles";
import MaUTable from "@mui/material/Table";
import { Menu, MenuItem, TableContainer, Typography } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import LoaderRowsRoundedSkeleton from "components/Table/LoaderComponents/LoaderRowsRoundedSkeleton";

const DEFAULT_PAGE_SIZE = 10;
const LOCAL_STORAGE_KEY = "pageSizeForAllTables";

const StyledTableContainer = styled(TableContainer)(({ style }) => ({
    borderRadius: 5,
    backgroundColor: "transparent",
    ...style,
}));

const StyledMaUTable = styled(MaUTable)(({ style }) => ({
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    ...style,
}));

const StyledContextMenuText = styled(Typography)(() => ({
    marginLeft: 5,
}));

const InsideTable = ({
    loading,
    remoteDataHandler,
    data,
    pageCount: controlledPageCount,
    columns,
    initialFilters,
    initialSortBy,
    renderTableHeader,
    tableStyle,
    tableContainerStyle,
    searchPlaceholder,
    renderTableBody,
    renderLoader,
    searchBar,
    renderTableFooter,
    extGlobalFilter,
    extFilters,
    disableContextMenu = false,
    disableRowClick = false,
    onRowClick,
}) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const [contextMenu, setContextMenu] = useState({
        open: false,
        xPos: 0,
        yPos: 0,
        idClicked: null,
    });
    const initialPageSize =
        parseInt(localStorage.getItem(LOCAL_STORAGE_KEY), 10) ||
        DEFAULT_PAGE_SIZE;

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        setAllFilters,
        gotoPage,
        setPageSize,
        state: { pageSize, pageIndex, filters, globalFilter, sortBy },
    } = useTable(
        {
            columns,
            data,
            pageCount: controlledPageCount,
            initialState: {
                pageIndex: 0,
                pageSize: initialPageSize,
                filters: initialFilters,
                sortBy: initialSortBy || [],
            },
            manualPagination: true,
            manualFilters: true,
            manualGlobalFilter: true,
            disableMultiSort: false,
            manualSortBy: true,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, pageSize.toString());
    }, [pageSize]);

    useEffect(() => {
        if (extFilters) {
            setAllFilters(extFilters);
        }
    }, [extFilters, setAllFilters]);

    useEffect(() => {
        if (extGlobalFilter) {
            setGlobalFilter(extGlobalFilter);
        } else {
            setGlobalFilter("");
        }
    }, [extGlobalFilter, setGlobalFilter]);

    const remoteDataHandlerDebounced = useAsyncDebounce(remoteDataHandler, 100);

    useEffect(() => {
        if (remoteDataHandler) {
            remoteDataHandlerDebounced({
                pageSize,
                pageIndex,
                filters,
                globalFilter,
                sortBy,
            });
        }
    }, [
        remoteDataHandler,
        pageSize,
        pageIndex,
        sortBy,
        remoteDataHandlerDebounced,
        filters,
        globalFilter,
    ]);

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        gotoPage(0);
    };

    const handleContextClick = (event, id, rowData) => {
        if (!disableContextMenu) {
            event.preventDefault();
            setContextMenu({
                open: true,
                xPos: event.clientX,
                yPos: event.clientY,
                idClicked: id,
                rowData,
            });
        }
    };

    const _RenderContextMenu = () => (
        <Menu
            keepMounted
            open={contextMenu.open}
            onClose={() => setContextMenu({ ...contextMenu, open: false })}
            anchorReference="anchorPosition"
            anchorPosition={{
                top: contextMenu.yPos,
                left: contextMenu.xPos,
            }}
        >
            <MenuItem
                onClick={() =>
                    onRowClick
                        ? onRowClick(contextMenu.rowData)
                        : window.open(
                              `${location.pathname}/${contextMenu.idClicked}`,
                              "_blank",
                          )
                }
            >
                <OpenInNew />
                <StyledContextMenuText>
                    {t("components.table.openTab")}
                </StyledContextMenuText>
            </MenuItem>
        </Menu>
    );

    return (
        <div>
            {_RenderContextMenu()}
            <StyledTableContainer style={tableContainerStyle}>
                {searchBar && (
                    <TableToolbar
                        onChange={(value) => setGlobalFilter(value)}
                        placeHolder={searchPlaceholder}
                    />
                )}
                <StyledMaUTable
                    style={tableStyle}
                    stickyHeader
                    {...getTableProps()}
                >
                    {renderTableHeader && renderTableHeader({ headerGroups })}
                    {renderTableBody &&
                        renderTableBody({
                            rows,
                            prepareRow,
                            handleContextClick,
                            history,
                            location,
                            loading,
                            disableRowClick,
                            onRowClick,
                        })}
                    {renderTableFooter &&
                        renderTableFooter({
                            loading,
                            controlledPageCount,
                            pageIndex,
                            handleChangePage,
                            pageSize,
                            handleChangeRowsPerPage,
                        })}
                </StyledMaUTable>
                {renderLoader && renderLoader({ loading })}
            </StyledTableContainer>
        </div>
    );
};

InsideTable.defaultProps = {
    initialFilters: [{}],
    searchBar: true,
    footer: true,
    renderLoader: (props) => <LoaderRowsRoundedSkeleton {...props} />,
};

export default InsideTable;
