import React from "react";
import styled from "@mui/styles/styled";
import { differenceInCalendarDays, parseISO } from "date-fns";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Typography, Chip } from "@mui/material";
import { Place } from "@mui/icons-material";

import { getStoreNameWithId } from "services/toolbox";
import ORDER_TYPES from "constants/order-types";
import ORDER_STATUS from "constants/order-status";

const WARNINGS_LEVELS = {
    ADVISORY: "ADVISORY",
    ALL_CLEAR: "ALL_CLEAR",
    WARNING: "WARNING",
};

const TYPES_TRACKED_FOR_ADVISORY = [ORDER_TYPES.VP];

const TYPES_TRACKED_FOR_WARNING = [
    ORDER_TYPES.VP,
    ORDER_TYPES.VF,
    ORDER_TYPES.VDS,
];

const DAYS_AFTER_SHIPPED_TRIGGER_ADVISORY = 2;

const DAYS_AFTER_SHIPPED_TRIGGER_WARNING = 4;

const DAYS_AFTER_IN_STORE_TRIGGER_ADVISORY = 14;

const DAYS_AFTER_IN_STORE_TRIGGER_WARNING = 30;

const styles = {
    chipAdvisory: {
        color: "#FFFF",
        fontWeight: "bold",
        backgroundColor: "#E26313",
    },
    chipWarning: {
        color: "#FFFF",
        fontWeight: "bold",
        backgroundColor: "#B2022F",
    },
    warningIcon: {
        color: "#FFFF",
        fontWeight: "bold",
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

const StyledContainer = styled("div")(() => ({
    textAlign: "center",
    display: "grid",
}));

const defineDeliveryWarningLevel = (orderType, date, orderStatus) => {
    const daysLate = differenceInCalendarDays(new Date(), parseISO(date));

    if (orderStatus === ORDER_STATUS.S_ORDER_SHIPPED) {
        if (
            TYPES_TRACKED_FOR_WARNING.includes(orderType) &&
            daysLate >= DAYS_AFTER_SHIPPED_TRIGGER_WARNING
        ) {
            return WARNINGS_LEVELS.WARNING;
        }
        if (
            TYPES_TRACKED_FOR_ADVISORY.includes(orderType) &&
            daysLate >= DAYS_AFTER_SHIPPED_TRIGGER_ADVISORY
        ) {
            return WARNINGS_LEVELS.ADVISORY;
        }
    }

    if (orderStatus === ORDER_STATUS.S_AVAILABLE_IN_STORE) {
        if (daysLate >= DAYS_AFTER_IN_STORE_TRIGGER_WARNING) {
            return WARNINGS_LEVELS.WARNING;
        }
        if (daysLate >= DAYS_AFTER_IN_STORE_TRIGGER_ADVISORY) {
            return WARNINGS_LEVELS.ADVISORY;
        }
    }

    return WARNINGS_LEVELS.ALL_CLEAR;
};

const StatusCellComponent = ({
    label,
    date,
    orderType,
    orderStatus,
    dateText,
    comment,
    stores,
}) => {
    const deliveryWarningLevel = defineDeliveryWarningLevel(
        orderType,
        date,
        orderStatus,
    );
    const warningLevelClass = () => {
        switch (deliveryWarningLevel) {
            case WARNINGS_LEVELS.WARNING:
                return styles.chipWarning;
            case WARNINGS_LEVELS.ADVISORY:
                return styles.chipAdvisory;
            default:
                return null;
        }
    };
    return (
        <StyledContainer>
            <Chip
                color={"default"}
                icon={
                    deliveryWarningLevel !== WARNINGS_LEVELS.ALL_CLEAR ? (
                        <ReportProblemOutlinedIcon
                            className={styles.warningIcon}
                        />
                    ) : null
                }
                sx={warningLevelClass()}
                label={label}
                size="small"
            />
            {orderStatus === "S_DISPATCH_ERROR" && comment?.scanned_by && (
                <Typography variant="body2" sx={styles.flexCenter}>
                    <Place fontSize="inherit" />{" "}
                    {comment.scanned_by.includes("@")
                        ? comment.scanned_by
                        : getStoreNameWithId(stores, comment.scanned_by)}
                </Typography>
            )}
            <Typography variant="caption">{dateText}</Typography>
        </StyledContainer>
    );
};

export default StatusCellComponent;
