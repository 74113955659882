import React from "react";
import {
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Fade,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "theme";

const styles = {
    packTableMain: {
        backgroundColor: theme.palette.mainGrey.main,
    },
};

const TablePrescriptionProduct = ({ prescription }) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12}>
            <Fade in={true} timeout={1200}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={styles.packTableMain}>
                            <TableRow>
                                <TableCell align="center">
                                    {t("common.rightEye").toUpperCase()}
                                </TableCell>
                                <TableCell align="center">
                                    {t("common.leftEye").toUpperCase()}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.sphere").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.right_sphere
                                                ? prescription.right_sphere
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.sphere").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.left_sphere
                                                ? prescription.left_sphere
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.cylinder").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.right_cylinder
                                                ? prescription.right_cylinder
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.cylinder").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.left_cylinder
                                                ? prescription.left_cylinder
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.axis").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.right_axis
                                                ? prescription.right_axis
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.axis").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.left_axis
                                                ? prescription.left_axis
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t(
                                                "common.pupillaryDistance",
                                            ).toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.right_pd
                                                ? prescription.right_pd
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t(
                                                "common.pupillaryDistance",
                                            ).toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.left_pd
                                                ? prescription.left_pd
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.height").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.right_height
                                                ? prescription.right_height
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.height").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.left_height
                                                ? prescription.left_height
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.addition").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.right_addition
                                                ? prescription.right_addition
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {t("common.addition").toUpperCase()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prescription.left_addition
                                                ? prescription.left_addition
                                                : "∅"}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fade>
        </Grid>
    );
};

export default TablePrescriptionProduct;
