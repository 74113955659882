const CLIENT_MOODS = {
    HAPPY: "HAPPY",
    MIXED: "MIXED",
    UNHAPPY: "UNHAPPY",
};

const PROPOSED_SOLUTION = {
    FOO: "FOO",
    BAR: "BAR",
};

const CONTACT_METHOD = {
    PHONE: "PHONE",
    EMAIL: "EMAIL",
    AT_STORE: "AT_STORE",
};

const REPORT_STATUS = {
    WAITING: "WAITING",
    TO_CONTACT: "TO_CONTACT",
    TO_NOT_CONTACT: "TO_NOT_CONTACT",
    RESOLVED: "RESOLVED",
};

const PROPOSED_SOLUTION_LIST = [PROPOSED_SOLUTION.FOO, PROPOSED_SOLUTION.BAR];

const CLIENT_MOOD_LIST = [
    CLIENT_MOODS.HAPPY,
    CLIENT_MOODS.MIXED,
    CLIENT_MOODS.UNHAPPY,
];

const CONTACT_METHOD_LIST = [
    CONTACT_METHOD.PHONE,
    CONTACT_METHOD.EMAIL,
    CONTACT_METHOD.AT_STORE,
];

const REPORT_STATUSES_LIST = [
    REPORT_STATUS.WAITING,
    REPORT_STATUS.TO_CONTACT,
    REPORT_STATUS.TO_NOT_CONTACT,
    REPORT_STATUS.RESOLVED,
];

export {
    CLIENT_MOODS,
    CONTACT_METHOD,
    REPORT_STATUS,
    PROPOSED_SOLUTION,
    PROPOSED_SOLUTION_LIST,
    CLIENT_MOOD_LIST,
    CONTACT_METHOD_LIST,
    REPORT_STATUSES_LIST,
};
