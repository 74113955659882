import React from "react";
import { TableFooter } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { useTranslation } from "react-i18next";

const buttonTexts = {
    prev: "common.previousPage",
    next: "common.nextPage",
};

const FooterPaginationSimple = ({
    controlledPageCount,
    pageIndex,
    handleChangePage,
    pageSize,
    handleChangeRowsPerPage,
    loading,
}) => {
    const { t } = useTranslation();
    if (loading) {
        return null;
    }
    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    style={{ borderBottomColor: "transparent" }}
                    count={controlledPageCount}
                    page={pageIndex}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[10, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("components.table.rowsPerPage")}
                    labelDisplayedRows={({ from, to, count }) =>
                        t("components.table.displayedRows", {
                            from,
                            to,
                            count,
                        })
                    }
                    getItemAriaLabel={(type) => t(buttonTexts[type])}
                />
            </TableRow>
        </TableFooter>
    );
};

export default FooterPaginationSimple;
