import { gql } from "@apollo/client";

const orderQuery = gql`
    query Orders(
        $store: String
        $types: [String!]
        $beginUpdatedAt: timestamptz
        $endUpdatedAt: timestamptz
        $statusOrderAtShop: String
        $globalStatusOrderShipped: String
        $globalStatusOrderEnded: String
    ) {
        orders(
            where: {
                _or: [
                    {
                        _and: [
                            {
                                global_status_id: {
                                    _eq: $globalStatusOrderShipped
                                }
                            }
                            { store_destination_id: { _eq: $store } }
                        ]
                    }
                    {
                        _and: [
                            {
                                updated_at: {
                                    _gte: $beginUpdatedAt
                                    _lte: $endUpdatedAt
                                }
                            }
                            { store_destination_id: { _eq: $store } }
                            { type: { _in: $types } }
                            {
                                _or: [
                                    {
                                        global_status_id: {
                                            _eq: $globalStatusOrderEnded
                                        }
                                    }
                                    {
                                        order_status_changes: {
                                            created_at: {
                                                _gt: $beginUpdatedAt
                                                _lt: $endUpdatedAt
                                            }
                                            order_statuses_id: {
                                                _eq: $statusOrderAtShop
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ) {
            id
            created_at
            updated_at
            carrier_type
            tracking_number
            type
            flat_amount_including_tax
            currency
            global_status_id
            order_status_changes(
                where: {
                    created_at: { _gte: $beginUpdatedAt, _lte: $endUpdatedAt }
                }
            ) {
                created_at
                order_statuses_id
            }
        }
    }
`;

export { orderQuery };
