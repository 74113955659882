import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import intervalPlural from "i18next-intervalplural-postprocessor";

const __DEV__ = process.env.NODE_ENV === "development";

const options = {
    fallbackLng: "fr-FR",
    debug: __DEV__,
    ns: ["orderStatus"],
};

i18next
    .use(Backend)
    .use(intervalPlural)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(options);

export default i18next;
