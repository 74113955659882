import React from "react";
import styled from "@mui/styles/styled";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import MenuItemIcons from "assets/menuItemIcons";

const StyledIcon = styled("img")(() => ({
    width: 25,
    height: 25,
    marginLeft: 15,
    marginRight: 16,
}));

const VerticalMenuItem = (props) => {
    const { icon, text, to } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <Link to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );

    const RenderIcon = () => {
        if (!icon) return null;

        if (MenuItemIcons[icon]) {
            return <StyledIcon alt={icon} src={MenuItemIcons[icon]} />;
        }

        return (
            <ListItemIcon style={{ justifyContent: "center" }}>
                <Icon color="primary">{icon}</Icon>
            </ListItemIcon>
        );
    };

    return (
        <ListItem
            button
            component={renderLink}
            style={{ justifyContent: "center" }}
        >
            {RenderIcon()}
            {text && (
                <ListItemText style={{ paddingLeft: "17px" }} primary={text} />
            )}
        </ListItem>
    );
};

export default VerticalMenuItem;
