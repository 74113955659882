const countries = require("./countries_isoCode.json");
const { format } = require("date-fns");
const { fr, enGB } = require("date-fns/locale");
const { parseISO } = require("date-fns");

function formatDateWithDashes(date) {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
}

/**
 * Transform an iso date string into a locale numeric date
 * @param {string} date string in Date format
 * @param {boolean} showTime if true show the time in the returned string
 * @returns {string} return a date in locale numeric format (ex for France => 06/01/2020 15h00)
 */
const convertDate = (date, showTime) => {
    return date !== null || date !== ""
        ? new Date(date).toLocaleDateString(
              navigator.languages ? navigator.languages[0] : navigator.language,
              {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: showTime && "numeric",
                  minute: showTime && "numeric",
              },
          )
        : "-";
};

/**
 *
 * @param {String} country in iso code Alpha3
 * @returns {String} full country name
 */
const transformCountryCode = (isoCountry) =>
    isoCountry !== null && isoCountry !== "" ? countries[isoCountry] : "";

/**
 * determine device type
 * @returns {String} "desktop" "mobile" or "tablet"
 */
const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua,
        )
    ) {
        return "mobile";
    }
    return "desktop";
};

const getLocalDate = (locale) => {
    switch (locale) {
        case "fr":
        case "fr-FR":
            return fr;
        case "en":
        case "en-GB":
            return enGB;
        default:
            return fr;
    }
};

const formatDateLocales = (date, locale, formatTarget = "iiii d MMMM yyyy") => {
    if (!date) {
        return null;
    }
    const parsedDate = parseISO(date);

    try {
        return format(parsedDate, formatTarget, {
            locale: getLocalDate(),
        });
    } catch (e) {
        return format(date, formatTarget, {
            locale: getLocalDate(),
        });
    }
};

/**
 *
 * @param {{id:string,name:string}[]} stores
 * @param {string} storeId
 * @returns
 */
const getStoreNameWithId = (stores, storeId) =>
    stores.find((store) => store.id === storeId)?.name || "";

/**
 * return the parameter string with the first letter capitalized
 * @param {string} string
 * @returns {string}
 */
const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

module.exports = {
    transformCountryCode,
    convertDate,
    getDeviceType,
    formatDateWithDashes,
    formatDateLocales,
    getLocalDate,
    getStoreNameWithId,
    capitalizeFirstLetter,
};
