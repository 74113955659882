import React, { useEffect, useState } from "react";
import styled from "@mui/styles/styled";
import { CircularProgress, Box } from "@mui/material";
import theme from "theme";

import InsideStepper from "components/common/InsideStepper";

import ORDER_STATUS from "constants/order-status";

const StyledContainer = styled("div")(() => ({
    width: "100%",
    marginTop: theme.spacing(3),
}));

const isOrderWithGlassOrdered = (orderProductLine) => {
    const IN_STOCK_TYPES = ["lab_stock"];
    const glassesTypes = orderProductLine.reduce(
        (acc, current) => [
            ...acc,
            ...(current?.glassByLeftGlass?.type
                ? [current.glassByLeftGlass.type]
                : []),
            ...(current?.glassByRightGlass?.type
                ? [current?.glassByRightGlass?.type]
                : []),
        ],
        [],
    );
    const oneIsNotStockType = glassesTypes.find(
        (type) => !IN_STOCK_TYPES.includes(type),
    );

    return !!oneIsNotStockType;
};

const globalStatusToChangeStatus = {
    S_WAITING: ORDER_STATUS.LAB_WAITING,
    S_GLASSES_ORDERED: ORDER_STATUS.LAB_GLASS_ORDERED,
    S_ASSEMBLY_IN_PROGRESS: ORDER_STATUS.LAB_WAITING_QUEUE,
    S_ORDER_PACKED: ORDER_STATUS.LAB_ORDER_PACKAGED,
    S_DISPATCH_ERROR: ORDER_STATUS.DISPATCH_ERROR,
    S_ORDER_SHIPPED: ORDER_STATUS.ORDER_SHIPPED,
    S_AVAILABLE_IN_STORE: ORDER_STATUS.ORDER_AT_SHOP,
    S_ORDER_ENDED: ORDER_STATUS.ORDER_DELIVERED,
};

const getLastStatus = (globalStatus, statusChanges) => {
    // convert global status to change status equivalency
    const statusChangeFromGlobal = globalStatusToChangeStatus[globalStatus];
    const lastStatusFromGlobal = statusChanges.find(
        (status) => status.order_statuses_id === statusChangeFromGlobal,
    );
    if (lastStatusFromGlobal) {
        return lastStatusFromGlobal;
    }
    return statusChanges.length ? statusChanges[0] : null;
};

/**
 * ## TrackingStatusBar
 * Display chrono frise according to steps array passed in props
 */
export default function TrackingStatusBar({
    status_changes,
    order_statuses_definitions,
    created_at,
    orderData,
    stores,
}) {
    const {
        origin,
        order_product_lines,
        shipping_method,
        global_status_detail,
    } = orderData;
    const [activeStepsCount, setActiveStepsCount] = useState(0);
    const [stepsWithData, setStepsWithData] = useState(null);
    const { id: globalStatusId } = global_status_detail || {};
    const lastStatus = getLastStatus(globalStatusId, status_changes);

    useEffect(() => {
        const computeStepsWithDate = (steps, status_changes) =>
            steps.map((currentStep, index) => {
                /*
                 *  For first step return the date of order created
                 * */
                if (index === 0) {
                    return { ...currentStep, date: created_at || null };
                }
                const statusOfCurrentStep = status_changes.find(
                    (status) => status.order_statuses_id === currentStep.id,
                );
                return {
                    ...currentStep,
                    comment: statusOfCurrentStep?.comment || null,
                    date: statusOfCurrentStep?.created_at || null,
                };
            });
        const stepsToDisplay = [
            ORDER_STATUS.LAB_WAITING,
            ...(isOrderWithGlassOrdered(order_product_lines)
                ? [ORDER_STATUS.LAB_GLASS_ORDERED]
                : []),
            ORDER_STATUS.LAB_WAITING_QUEUE,
            ORDER_STATUS.LAB_ORDER_PACKAGED,
            ...(status_changes.find(
                (status) =>
                    status.order_statuses_id === ORDER_STATUS.DISPATCH_ERROR,
            )
                ? [ORDER_STATUS.DISPATCH_ERROR]
                : []),
            ORDER_STATUS.ORDER_SHIPPED,
            ...(shipping_method === "store" || !origin?.startsWith("WEB")
                ? [ORDER_STATUS.ORDER_AT_SHOP]
                : []),
            ...(shipping_method === "store" || !origin?.startsWith("WEB")
                ? [ORDER_STATUS.ORDER_DELIVERED]
                : []),
        ];
        const steps = order_statuses_definitions.filter((status) =>
            stepsToDisplay.includes(status.id),
        );
        const stepsWithDate = computeStepsWithDate(steps, status_changes);
        /**
         * Get the last valid status without problem
         */
        const lastStatusIndex = lastStatus
            ? lastStatus.order_statuses_definition.index
            : 0;
        /**
         * Retrieve the last status to display in this component according to the lastStatusIndex
         */
        const validatedStatuses = steps
            .filter((statusDef) => statusDef.index <= lastStatusIndex)
            .slice();
        setStepsWithData(stepsWithDate);
        setActiveStepsCount(
            validatedStatuses.length !== 0 ? validatedStatuses.length - 1 : 0,
        );
    }, [
        lastStatus,
        order_statuses_definitions,
        order_product_lines,
        origin,
        shipping_method,
        status_changes,
        created_at,
    ]);
    return (
        lastStatus && (
            <>
                <StyledContainer>
                    {stepsWithData && stores.length > 0 ? (
                        <InsideStepper
                            orderData={orderData}
                            stores={stores}
                            activeSteps={stepsWithData}
                            activeStepsCount={activeStepsCount}
                        />
                    ) : (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    )}
                </StyledContainer>
            </>
        )
    );
}
