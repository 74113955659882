import React, { useState } from "react";
import styled from "@mui/styles/styled";
import Box from "@mui/material/Box";
import ContentWrapper from "components/Layout/ContentWithHeader";
import AddStickerDialog from "components/AddStickerDialog";
import Datagrid from "components/Datagrid";
import EditableCell from "components/Datagrid/EditableCell";
import {
    InputAdornment,
    TextField,
    Grid,
    Button,
    CircularProgress,
    Container,
} from "@mui/material";
import Text from "components/Text";
import { openPdfDataToNewWindow, getPdfLabels } from "services/utils/pdf";
import { useTranslation } from "react-i18next";
import theme from "theme";

const styles = {
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    button: {
        display: "block",
        marginTop: theme.spacing(2),
    },
};

const TRANS_PATH = "pages.stickers";

const StyledContainer = styled(Container)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    paddingBottom: theme.spacing(2),
}));

const Stickers = () => {
    const { t } = useTranslation();
    const [settings, setSettings] = React.useState({
        page_margin_top: 15,
        page_margin_left: 7,
        page_margin_bottom: 15,
        page_margin_right: 7,
        already_used_labels: 0,
    });
    const [pdfGenerationLoading, setPdfGenerationLoading] = useState(false);
    const columns = React.useMemo(
        () => [
            { Header: t("common.productCode"), accessor: "sku" },
            {
                Header: t("common.productName"),
                accessor: "productName",
            },
            {
                Header: t("common.quantity"),
                accessor: "quantity",
                Cell: EditableCell,
            },
        ],
        [t],
    );
    const [data, setData] = useState([]);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    const cleanAndSetData = (dataInput) => {
        var newData = dataInput.reduce((accumulator, current) => {
            const { sku } = current;
            const found = accumulator.find((elem) => elem.sku === sku);
            if (found) {
                found.quantity = (
                    parseInt(found.quantity) + parseInt(current.quantity)
                ).toString(10);
            } else {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
        setData(newData);
    };

    const handleSettingsChange = (prop) => (event) => {
        setSettings({ ...settings, [prop]: event.target.value });
    };

    const handlePDFGeneration = async () => {
        setPdfGenerationLoading(true);
        const refs = {};
        data.forEach((ref) => {
            refs[ref.sku] = ref.quantity;
        });
        const pdf = await getPdfLabels({
            ...settings,
            refs,
        });
        if (pdf) {
            openPdfDataToNewWindow(pdf);
        }
        setPdfGenerationLoading(false);
    };

    const updateMyData = (rowIndex, columnId, value) => {
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            }),
        );
    };

    return (
        <ContentWrapper title={t(`${TRANS_PATH}.title`)}>
            <StyledContainer>
                <Datagrid
                    addDataDialog={AddStickerDialog}
                    title={t("common.products")}
                    columns={columns}
                    data={data}
                    setData={cleanAndSetData}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                />

                <Box>
                    <Text variant="h6" style={styles.title}>
                        {t(`${TRANS_PATH}.printSettingsContainerTitle`)}
                    </Text>
                    <Text variant="subtitle1" style={styles.title}>
                        {t(`${TRANS_PATH}.marginsBoxTitle`)}
                    </Text>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    label={t("common.left")}
                                    required
                                    type="number"
                                    variant="standard"
                                    value={settings.page_margin_left}
                                    onChange={handleSettingsChange(
                                        "page_margin_left",
                                    )}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                cm
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={t("common.right")}
                                    required
                                    type="number"
                                    variant="standard"
                                    value={settings.page_margin_right}
                                    onChange={handleSettingsChange(
                                        "page_margin_right",
                                    )}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                cm
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    label={t("common.top")}
                                    required
                                    type="number"
                                    variant="standard"
                                    value={settings.page_margin_top}
                                    onChange={handleSettingsChange(
                                        "page_margin_top",
                                    )}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                cm
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={t("common.bottom")}
                                    required
                                    type="number"
                                    variant="standard"
                                    value={settings.page_margin_bottom}
                                    onChange={handleSettingsChange(
                                        "page_margin_bottom",
                                    )}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                cm
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Text style={styles.title} variant="subtitle1">
                        {t(`${TRANS_PATH}.skipLabelsBoxTitle`)}
                    </Text>
                    <Text style={styles.title} variant="body2">
                        {t(`${TRANS_PATH}.skipLabelsBoxDescription`)}
                    </Text>
                    <TextField
                        label={t(`${TRANS_PATH}.skipLabelsFieldTitle`)}
                        required
                        type="number"
                        variant="standard"
                        value={settings.already_used_labels}
                        onChange={handleSettingsChange("already_used_labels")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {t(
                                        `${TRANS_PATH}.skipLabelsFieldPlaceHolder`,
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        sx={styles.button}
                        disabled={pdfGenerationLoading || data.length === 0}
                        onClick={handlePDFGeneration}
                    >
                        {pdfGenerationLoading && <CircularProgress size={14} />}
                        {!pdfGenerationLoading &&
                            t(`${TRANS_PATH}.generatePdfCta`)}
                    </Button>
                </Box>
            </StyledContainer>
        </ContentWrapper>
    );
};

export default Stickers;
